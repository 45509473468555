import React from 'react'
import './App.css'
import { PrivateRoute } from './components/loginShell/PrivateRoute'
import SignupPage from './components/signup/SignupPage'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import HealthDashboard from './components/dashboard/HealthDashboard'
import Logout from './components/loginShell/Logout'
import LandingPage from './components/landing/LandingPage'
import LoginPage from './components/loginShell/LoginPage'
import AuthProvider from './context/AuthContext'
import { Toaster } from 'react-hot-toast'

const App = () => {
  return (
    <div className='flex flex-col min-h-screen'>
      <Toaster />
      <Router>
        <AuthProvider>
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route path='/dashboard/*' element={<HealthDashboard />} />
            </Route>
            <Route path='/login' element={<LoginPage />} />
            <Route path='/signup' element={<SignupPage />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/:landing' element={<LandingPage />} />
            <Route path='/' element={<Navigate replace to='/overview' />} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  )
}

export default App
