import React from 'react'
import { cmToFtIn, ftInToCm } from '../../../utils/unitHelper'

const HeightInput = ({ units, userParams, setUserParams }) => {
  if (units === 'metric') {
    return (
      <div>
        <label className='label'>
          <span className='label-text'>Height (cm)</span>
        </label>
        <input
          value={userParams.height}
          onChange={(e) => setUserParams((prev) => ({
            ...prev,
            height: parseInt(e.target.value)
          }))}
          type='text'
          placeholder='Type here'
          className='input input-bordered w-full'
        />
      </div>
    )
  } else {
    return (
      <ImperialHeightSelect
        userParams={userParams}
        setUserParams={setUserParams}
      />
    )
  }
}

const ImperialHeightSelect = ({ userParams, setUserParams }) => {
  const feetOptions = [2, 3, 4, 5, 6, 7, 8]
  const inchesOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

  const { feet, inches } = cmToFtIn(userParams.height)

  return (
    <div className='flex items-center space-x-2.5 w-full'>
      <div className='w-full'>
        <label className='label'>
          <span className='label-text'>Height (ft)</span>
        </label>
        <select
          value={feet}
          className='select select-bordered w-full'
          onChange={(e) => setUserParams((prev) => ({
            ...prev,
            height: ftInToCm(parseInt(e.target.value), inches)
          }))}
        >
          {feetOptions.map((opt) => (<option key={opt} value={opt}>{opt}</option>))}
        </select>
      </div>
      <div className='w-full'>
        <label className='label'>
          <span className='label-text'>Height (in)</span>
        </label>
        <select
          value={inches}
          className='select select-bordered w-full'
          onChange={(e) => setUserParams((prev) => ({
            ...prev,
            height: ftInToCm(feet, parseInt(e.target.value))
          }))}
        >
          {inchesOptions.map((opt) => (<option key={opt} value={opt}>{opt}</option>))}
        </select>
      </div>
    </div>
  )
}

export default HeightInput
