import React from 'react'
import PropTypes from 'prop-types'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const BarChart = ({ joinData }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderRadius: 100
      }
    },
    plugins: {
      legend: false
    },
    layout: {
      padding: 10
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        border: {
          display: false
        },
        ticks: {
          minRotation: 45, // Minimum rotation angle
          autoSkip: true, // Automatically skip labels to prevent crowding
          maxTicksLimit: 20 // Limit the number of displayed ticks if needed
        }
      },
      y: {
        min: 0,
        suggestedMax: 10,
        border: {
          display: false
        }
      }
    }
  }

  const data = {
    labels: joinData.map(val => val.label),
    datasets: [
      {
        label: 'Users Joined',
        data: joinData.map(val => val.userCount),
        backgroundColor: '#1fadff'
      }
    ]
  }

  return (
    <div className='h-[400px]'>
      <Bar options={options} data={data} />
    </div>
  )
}

BarChart.propTypes = {
  joinData: PropTypes.any
}

export default BarChart
