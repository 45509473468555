import React from 'react'
import PropTypes from 'prop-types'
import { formatBare, formatVol } from '../global/formatter'
import { motion } from 'framer-motion'

const CircularProgressGraph = ({ totalAmount, goal, streak, units }) => {
  const percent = Math.round((totalAmount / goal) * 100) || 0

  const renderCircle = (percent, height, width) => {
    const smallestDimension = Math.min(height, width)
    const radius = (smallestDimension / 2) * 0.8
    const cx = width / 2
    const cy = height / 2

    const remainColor = 'rgba(15,180,216,0.35)'
    const completeColor = '#02B72F'
    const progressColor = '#1FADFF'

    return (
      <div>
        <svg height={height} width={width} style={{ transform: 'rotate(270deg)' }}>
          <g>
            <circle
              cx={cx}
              cy={cy}
              r={radius}
              stroke={remainColor}
              fill='transparent'
              opacity='0.5'
              strokeWidth='20'
            />
            <motion.circle
              cx={cx}
              cy={cy}
              r={radius}
              fill='transparent'
              strokeWidth='20'
              strokeLinecap='round'
              stroke={percent < 100 ? progressColor : completeColor}
              initial={{ opacity: 0, pathLength: 0 }}
              animate={{ opacity: 1, pathLength: (percent / 100), transition: { duration: 2, ease: 'easeInOut' } }}
            />
          </g>
        </svg>
      </div>
    )
  }

  return (
    <div className='w-[300px]'>
      <div className='flex items-center justify-center'>
        {renderCircle(percent, 300, 300)}
        <div className='absolute text-center'>
          <h2 className='text-5xl font-bold'>{formatBare(totalAmount, units)}</h2>
          <p>Goal {formatVol(goal, units)}</p>
        </div>
      </div>
      <div className='flex items-center justify-between'>
        <p>{percent}%</p>
        <p>{streak ?? 0} Day Streak</p>
      </div>
    </div>
  )
}

CircularProgressGraph.propTypes = {
  totalAmount: PropTypes.number.isRequired,
  goal: PropTypes.number.isRequired,
  streak: PropTypes.number,
  units: PropTypes.any
}

export default CircularProgressGraph
