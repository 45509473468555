import React from 'react'
import DatePicker from 'react-datepicker'
import { format, getHours, getMinutes } from 'date-fns'
import DatePickerCustomInput from '../../DatePickerCustomInput'
import { utcToZonedTime } from 'date-fns-tz'

// https://reactdatepicker.com/#example-calendar-container
import 'react-datepicker/dist/react-datepicker.css'

const WakeUpSelect = ({ userParams, setUserParams }) => {
  return (
    <div className='flex items-center space-x-2.5'>
      <WakeSleepTimeSelect
        type='WAKE'
        userParams={userParams}
        setUserParams={setUserParams}
      />
      <WakeSleepTimeSelect
        type='SLEEP'
        userParams={userParams}
        setUserParams={setUserParams}
      />
    </div>
  )
}

const WakeSleepTimeSelect = ({ type, userParams, setUserParams }) => {
  const onChange = (date) => {
    const hrs = getHours(date)
    const mins = getMinutes(date)
    const newDate = new Date(Date.UTC(1970, 0, 1, hrs, mins))
    if (type === 'WAKE') {
      setUserParams((prev) => ({ ...prev, wakeUp: newDate }))
    } else {
      setUserParams((prev) => ({ ...prev, goToSleep: newDate }))
    }
  }

  const title = type === 'WAKE' ? 'Wake up time' : 'Sleep time'
  const caption = type === 'WAKE' ? 'Wake' : 'Sleep'

  const selected = type === 'WAKE'
    ? utcToZonedTime(userParams.wakeUp, 'UTC')
    : utcToZonedTime(userParams.goToSleep, 'UTC')

  return (
    <div className='w-full'>
      <label className='label'>
        <span className='label-text'>{title}</span>
      </label>
      <DatePicker
        customInput={<DatePickerCustomInput />}
        wrapperClassName='w-full'
        selected={selected}
        value={format(selected, 'h:mm a')}
        onChange={onChange}
        showTimeSelect
        showTimeSelectOnly
        showPopperArrow={false}
        timeIntervals={30}
        timeCaption={caption}
        popperModifiers={[
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport',
              tether: false,
              altAxis: true
            }
          }
        ]}
      />
    </div>
  )
}

export default WakeUpSelect
