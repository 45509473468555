import React, { useMemo } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import PropTypes from 'prop-types'
import { addHours, format, isSameHour, startOfDay } from 'date-fns'
import { mLToOz } from '../../utils/unitHelper'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const DayProgressChart = ({ sips, units, viewingDate }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderRadius: 100
      }
    },
    plugins: {
      legend: false
    },
    layout: {
      padding: 10
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        border: {
          display: false
        },
        ticks: {
          minRotation: 45, // Minimum rotation angle
          autoSkip: true, // Automatically skip labels to prevent crowding
          maxTicksLimit: 20 // Limit the number of displayed ticks if needed
        }
      },
      y: {
        min: 0,
        suggestedMax: 10,
        border: {
          display: false
        }
      }
    }
  }
  const midnight = startOfDay(viewingDate)
  const sipData = useMemo(() => Array.from({ length: 24 }, (_, idx) => {
    const thisDate = addHours(midnight, idx)
    let totalAmount = 0
    sips.forEach((sip) => {
      const sipTime = new Date(sip.time.iso)
      if (isSameHour(sipTime, thisDate)) { totalAmount += sip.amount }
    })
    totalAmount = units === 'metric' ? totalAmount : mLToOz(totalAmount)
    totalAmount = totalAmount.toFixed(2)
    return {
      label: format(thisDate, 'h:mm aa'),
      dateObject: thisDate,
      totalAmount
    }
  }), [sips])

  const label = units === 'metric' ? 'Intake amount (ml)' : 'Intake amount (oz)'

  const data = {
    labels: sipData.map(d => d.label),
    datasets: [
      {
        label,
        data: sipData.map(d => d.totalAmount),
        backgroundColor: '#1fadff'
      }
    ]
  }

  return (
    <Bar options={options} data={data} />
  )
}

DayProgressChart.propTypes = {
  sips: PropTypes.array.isRequired
}

export default DayProgressChart
