import axios from 'axios'
// import { updateGroupCache } from '../components/global/groupInfo'
const config = { apiUrl: process.env.REACT_APP_API_URL }
const authHeader = require('./authHeader')

async function updateGroupMembers ({ groupId, toRemove, toAdd }) {
  if (groupId && typeof groupId === 'string' && (toRemove.length > 0 || toAdd.length > 0)) {
    const options = {
      headers: authHeader.authHeader()
    }

    const payload = { toRemove, toAdd }

    await axios.put(`${config.apiUrl}group/${groupId}`, payload, options)
    return true
  } else {
    throw new Error('Invalid Group Id')
  }
}

async function createGroup ({ groupName, joinCode, groupId }) {
  if (groupName && typeof groupName === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    const payload = { groupName, joinCode, groupId }

    await axios.post(`${config.apiUrl}group/:groupId`, payload, options)
  }
}

const getGroupLogoUrl = async () => {
  const options = {
    headers: authHeader.authHeader()
  }
  const groupId = window.localStorage.getItem('groupId')
  const res = await axios.get(`${config.apiUrl}group/${groupId}/logo`, options)
  if (res.status === 200) {
    return res.data
  }
}

export const groupService = {
  updateGroupMembers,
  createGroup,
  getGroupLogoUrl
}
