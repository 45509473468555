import React, { useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { IconButton, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RefreshIcon from '@mui/icons-material/Refresh';

const styles = theme => ({
  mainPanel: {
    backgroundColor: theme.palette.main.foreground
  },
  header: {
    padding: '.5em 1.5em .5em',
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    textAlign: 'left',
    marginRight: 'auto'
  },
  key: {
    fontFamily: 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
    fontWeight: 'bold'
  },
  val: {
    fontFamily: 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
    padding: '0 1em'
  },
  undef: {
    color: '#888',
    fontStyle: 'italic'
  }
})

const DebugView = ({ classes, data, onRefresh }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className={classes.root}>
      <Paper square={false} className={classes.mainPanel}>
        <div className={classes.header} onClick={() => setExpanded(!expanded)}>
          <Typography variant='h3' className={classes.title}>
            Debug Data
          </Typography>
          <Typography variant='body2'>{Object.keys(data).length} items</Typography>
          <IconButton
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              onRefresh()
            }}
            size="large">
            <RefreshIcon />
          </IconButton>
          <IconButton size="large">
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>

        {expanded &&
          <Table>
            <TableBody>
              {Object.keys(data).map(key => (
                <TableRow key={key}>
                  <TableCell align='right' className={classes.key}>{key}</TableCell>
                  <TableCell className={classes.val}>
                    <pre>
                      {JSON.stringify(data[key], null, 2) || <span className={classes.undef}>undefined</span>}
                    </pre>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>}
      </Paper>
    </div>
  );
}

DebugView.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  onRefresh: PropTypes.func
}

export default (withStyles(styles, { withTheme: true })(DebugView))
