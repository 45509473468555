import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { EmbedVideo } from './embeds'
import Lightbox from './Lightbox'
import playIcon from '../../resources/play.svg'
import playLightIcon from '../../resources/play-light.svg'
import './Hero.css'

export default function Hero ({ lightTheme, altLayout, superTitle, title, subTitle, imgDesktop, imgMobile, imgAlt = '', ...props }) {
  const [showLightbox, setShowLightbox] = useState(false)

  const btnClasses = 'hst-btn' + (lightTheme ? '' : ' hst-btn-light')

  return (
    <>
      <div className='hst-hero' {...props}>
        <img className='hst-heroImg hst-desktop' src={'/assets/' + imgDesktop} alt={imgAlt} />
        <img className='hst-heroImg hst-mobile' src={'/assets/' + (imgMobile || imgDesktop.replace('.', '-m.'))} alt={imgAlt} />
        <div className={'hst-heroContent' + (lightTheme ? ' hst-heroContent-light' : '') + (altLayout ? ' hst-heroContent-altLayout' : '')}>
          {superTitle && <h1 className='hst-heroSuper'>{superTitle}</h1>}
          {title && <h2 className='hst-heroTitle'>{title}</h2>}
          {subTitle && <h3 className='hst-heroSub'>{subTitle}</h3>}
          <div className='hst-heroButtons'>
            <Link to='#getStarted' onClick={() => window.scrollTo(0, 9999999)} className={btnClasses}>GET STARTED</Link>
            <a
              href='https://vimeo.com/670326833'
              className={btnClasses}
              onClick={event => {
                event.preventDefault()
                setShowLightbox(true)
              }}
            >
              <img src={lightTheme ? playLightIcon : playIcon} alt='' /> WATCH VIDEO
            </a>
          </div>
        </div>
      </div>

      <Lightbox open={showLightbox} onClose={() => setShowLightbox(false)}>
        <EmbedVideo />
      </Lightbox>
    </>
  )
}
