import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import PageHeader from '../PageHeader'
import moment from 'moment'
import { Grid, Button, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Avatar, Typography } from '@mui/material'
import { challengesService } from '../../services/challengesService'
import { AddCircle, ChevronRight } from '@mui/icons-material'
import iconBottlesSaved from '../../resources/bottlesSaved.svg'
import iconTotalVolume from '../../resources/totalVolume.svg'
import iconGoalsMet from '../../resources/goalsMet.svg'
import iconUsers from '../../resources/users.svg'
import Graph from '../graphing/Graph'
import ErrorBoundary from '../global/ErrorBoundary'
import { authService } from '../../services/authService'
import { formatBareLarge, formatLarge } from '../global/formatter'
import { useParams } from 'react-router-dom'
import ReactGA from 'react-ga4'

const styles = theme => ({
  mainPanel: {
  },
  paper: {
    padding: theme.spacing(2),
    color: 'black'
  },
  plainLink: {
    textDecoration: 'none',
    color: 'white'
  },

  cell: {
    maxWidth: '30em',
    padding: '1em',
    margin: '0 auto',
    borderRadius: '.25em',
    textAlign: 'center',
    display: 'flex',
    position: 'relative'
  },
  headerCell: {
    flexDirection: 'column',
    paddingTop: '4em',
    marginTop: '4em',
    '& span': {
      marginBottom: '.5em'
    }
  },
  floatingImgBox: {
    position: 'absolute',
    top: '-3em',
    left: '0',
    right: '0',
    height: '6em',
    display: 'flex',
    justifyContent: 'center'
  },
  floatingImg: {
    width: '6em',
    height: '6em',
    borderRadius: '12em',
    objectFit: 'cover',
    background: '#eeeeee'
  },
  challengeTitle: {
    fontSize: '1.2em'
  },
  challengeDesc: {
    fontSize: '.9em',
    margin: '.5em 0 1em'
  },
  challengeType: {
    textTransform: 'uppercase',
    margin: '0 1em .5em',
    borderTop: '1px solid #ddd',
    paddingTop: '1em'
  },
  pending: {
    color: theme.palette.primary.main
  },
  statusPill: {
    margin: '.5em auto',
    padding: '.25em .75em .4em',
    borderRadius: '16em',
    border: `3px solid ${theme.palette.primary.main}`,
    fontWeight: 'bold'
  },
  statusPillActive: {
    border: 'none',
    background: theme.palette.primary.main,
    color: 'white',
    animation: '2s infinite alternate $pulse'
  },
  '@keyframes pulse': {
    from: { backgroundColor: theme.palette.primary.dark },
    to: { backgroundColor: theme.palette.primary.main }
  },
  statusDetail: {
    fontStyle: 'italic'
  },
  buttonRow: {
    display: 'flex',
    '& > *': {
      display: 'block',
      width: '50%',
      flexGrow: '1',
      '& button': {
        width: '100%'
      }
    }
  },
  unpublishBody: {
    fontSize: '1em'
  },
  trophy: {
    width: '25%',
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& img': {
      width: '6em',
      height: '6em'
    }
  },
  teamCell: {
    alignItems: 'center',
    padding: '0 1em .5em'
  },
  teamNum: {
    fontSize: '1.5em',
    marginRight: '.5em'
  },

  backContainer: {
    height: '3em',
    display: 'flex',
    alignItems: 'flex-end'
  },
  summaryCol: {
    position: 'sticky'
  },
  detailCol: {
    '&>:first-child': {
      marginTop: '7em'
    }
  },
  card: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  cardLabels: {
    display: 'flex',
    alignItems: 'center',
    '& h3': {
      marginRight: 'auto'
    }
  },

  progressTrack: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '18px'
  },
  progressTrackFill: {
    background: theme.palette.primary.main,
    height: '6px',
    borderRadius: '6px'
  },
  progressTrackDot: {
    height: '18px',
    width: '18px',
    borderRadius: '18px',
    border: '2px solid white',
    margin: '0 -9px',
    zIndex: '10',
    animation: '2s infinite alternate $pulse'
  },
  progressTrackEmpty: {
    background: '#eee',
    height: '6px',
    flexGrow: '1',
    borderRadius: '6px'
  },
  progressNotes: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    fontSize: '1.2em'
  },
  progressNotesBottom: {
    color: '#888',
    alignItems: 'flex-start'
  },
  progressMain: {
    color: '#666',
    fontSize: '1.5em',
    '&.active': {
      color: theme.palette.primary.dark
    }
  },

  statsHeader: {
    textAlign: 'center',
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: '1px solid #ddd'
  },
  statsItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '.8em'
  },
  statsVal: {
    fontSize: '1.5em'
  },

  teamList: {
    margin: theme.spacing(-2),
    marginTop: theme.spacing(2),
    padding: '0',
    '& li': {
      listStyleType: 'none',
      display: 'flex',
      alignItems: 'center',
      padding: '.5em 1em',
      '&:nth-child(odd)': {
        background: '#f1f7f9'
      }
    }
  },
  teamPlace: {
    fontSize: '2em',
    width: '1em'
  },
  teamDetail: {
    flexGrow: '1'
  },
  teamPoints: {
    display: 'flex',
    alignItems: 'center'
  },
  teamPointsBar: {
    height: '4px',
    background: theme.palette.primary.main,
    borderRadius: '2px 0 0 2px'
  },
  teamPointsDot: {
    height: '8px',
    width: '8px',
    marginLeft: '1px',
    marginRight: theme.spacing(1),
    background: theme.palette.primary.main,
    borderRadius: '100px'
  },
  teamData: {
    width: '3em'
  },
  teamDataLong: {
    minWidth: '4em'
  },
  teamLabel: {
    fontSize: '.8em',
    width: '3.75em'
  },
  teamLabelLong: {
    fontSize: '.8em',
    width: '5em'
  },

  trophyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    textAlign: 'center'
  },
  trophyImg: {
    height: '6em'
  },
  trophyName: {
    fontWeight: 'bold'
  },
  trophyDesc: {
    color: '#888',
    fontStyle: 'italic'
  },
  trophyRecipients: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },

  selectList: {
    padding: '0',
    '& li': {
      listStyleType: 'none',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '& svg': {
        marginRight: theme.spacing(1)
      },
      '&:hover': {
        color: theme.palette.primary.main
      }
    }
  },

  ownerRow: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '300px',
    marginBottom: theme.spacing(2)
  },
  ownerName: {
    marginLeft: theme.spacing(2),
    marginRight: 'auto'
  },
  ownerCount: {
    marginRight: 'auto'
  },

  graphHolder: {
    marginTop: '1em'
  },
  graphTitle: {
    marginTop: '1em'
  }
})

function scaleNumber (num) {
  if (num >= 100000) {
    return Math.floor(num / 1000) + 'k'
  } else if (num >= 1000) {
    return Math.floor(num / 100) / 10 + 'k'
  } else {
    return Math.round(num)
  }
}

const ChallengeCard = ({ classes, title, labelContent, children, ...props }) => (
  <Paper className={classes.card} {...props}>
    {labelContent
      ? (
        <div className={classes.cardLabels}>
          {title && <Typography variant='h3'>{title}</Typography>}
          {labelContent}
        </div>
        )
      : title && <Typography variant='h3'>{title}</Typography>}
    <ErrorBoundary msg='There was an error displaying this data.'>
      {children}
    </ErrorBoundary>
  </Paper>
)

ChallengeCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  labelContent: PropTypes.object,
  children: PropTypes.any
}

const ProgressBar = ({ classes, progress }) => (
  <div className={classes.progressTrack}>
    <div className={classes.progressTrackFill} style={{ width: `${progress * 100}%` }} />
    <div className={classes.progressTrackDot} />
    <div className={classes.progressTrackEmpty} />
  </div>
)

ProgressBar.propTypes = {
  classes: PropTypes.object.isRequired,
  progress: PropTypes.num
}

const ChallengeDetails = ({ classes }) => {
  const [challenge, setChallenge] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showTrophyDialog, setShowTrophyDialog] = useState(null)
  const [trophyDetails, setTrophyDetails] = useState(null)
  const [copiedEmails, setCopiedEmails] = useState(false)
  const [unpublishing, setUnpublishing] = useState(false)

  const { groupId, challengeId } = useParams()

  const [prefs, setPrefs] = useState({})
  useEffect(() => {
    console.log(window.location.pathname)
    ReactGA.send({
      hitType: 'pageview',
      page: window?.location?.pathname ?? '',
      title: 'Challenge Details Page'
    })
    return authService.listenToUserPrefs(setPrefs)
  }, [])
  const units = prefs.measurementSystem

  const [permissions, setPermissions] = useState(authService.blankPermissions)
  useEffect(() => {
    return authService.listenToPermissions(groupId, setPermissions)
  }, [groupId])

  const fetchChallenge = useCallback(async () => {
    setLoading(true)
    const results = await challengesService.getChallengeDetails(groupId, challengeId)
    if (results === 'bad' || results.message) {
      setChallenge({})
      setErrorMessage(results.message || 'Error loading challenge details.')
      setLoading(false)
      return
    }

    // let's pre-process the data to get some expensive calc out of the way

    // extract the challenge data, and momentize the dates
    const data = {
      ...results.challenge,
      approved: results.challenge.visibility === 'APPROVED',
      visibilityDate: results.challenge.visibilityDate ? moment(results.challenge.visibilityDate) : null,
      startDate: moment(results.challenge.startDate),
      endDate: results.challenge.endDate ? moment(results.challenge.endDate) : null
    }

    // get some fake stats in there if they're not passed for any reason
    if (!data.challengeStats) {
      data.challengeStats = {
        fake: true,
        daysComplete: moment().diff(data.startDate, 'days')
      }
    }

    // for threshold challenges, estimate an end date based on rate of progress so far
    if (data.goal === 'THRESHOLD') {
      // now + ((goal - total) / (total / (endDate - startDate)))
      const total = data.challengeStats.totalBottlesSaved
      data.estimatedEndDate = moment().add(
        (data.thresholdValue - total) / (total / moment().diff(data.startDate, 'days') + 1),
        'days'
      )
    }

    // for team challenges, rank our teams appropriately
    if (data.teams && data.teams.length > 0) {
      data.teams.sort((a, b) => (
        ((b.challengeStats || {}).hydrationScore || -1) -
        ((a.challengeStats || {}).hydrationScore || -1)
      ))
    }

    // for threshold challenges, turn the stat history into an array the graph can use
    if (data.challengeStatHistory && data.challengeStatHistory.length > 0 && data.goal === 'THRESHOLD') {
      data.progressData = data.challengeStatHistory.map((entry, i) => {
        let label = ' '
        if (i % 3 === 0) {
          label = moment(entry.day).format('MMM D')
        }
        return {
          label,
          goal: entry.totalBottlesSaved,
          water: 0
        }
      })
    }

    // big one! turn the challenge join dates obj into another graph-shaped array
    data.joinData = []
    const joinDates = data.challengeJoinDates
    if (joinDates && Object.keys(joinDates).length > 0) {
      const indexDate = moment(Object.keys(joinDates)[0])

      let graphEndDate = moment()
      if (data.endDate && data.endDate.isBefore(graphEndDate)) {
        graphEndDate = data.endDate
      }
      graphEndDate.add(12, 'hours')

      const labels = {
        [data.startDate.format('YYYY-MM-DD')]: 'START'
      }
      if (data.endDate) labels[data.endDate.format('YYYY-MM-DD')] = 'END'

      let firstDate = true
      while (indexDate.isBefore(graphEndDate)) {
        const key = indexDate.format('YYYY-MM-DD')

        let label = ' '
        if (firstDate) {
          firstDate = false
          label = indexDate.format('MMM D')
        }

        if (joinDates[key] || labels[key] || (Math.random() < 1.2)) {
          data.joinData.push({
            water: (joinDates[key] || 0),
            goal: 5,
            label: labels[key] || label
          })
        }
        indexDate.add(1, 'day')
      }

      // if there's more than 90 days of data, sum by week instead
      if (data.joinData.length > 90) {
        data.weekMode = true
        const weekData = []
        let i = 0
        let sum = 0
        let label = ' '
        data.joinData.forEach(day => {
          i++
          sum += day.water
          if (day.label !== ' ') {
            label = day.label
          }
          if (i > 6) {
            weekData.push({
              water: sum,
              goal: 5,
              label
            })
            i = 0
            sum = 0
            label = ' '
          }
        })
        data.joinData = weekData
      }
    }

    setChallenge(data)
    setLoading(false)
  }, [groupId, challengeId])

  useEffect(() => {
    fetchChallenge()
  }, [fetchChallenge])

  if (errorMessage) {
    return (
      <div>
        <PageHeader title='Challenges' />
        <div className={classes.mainPanel}>
          <Grid container>
            <Grid item md={3}>
              {/* <A href='../challenges' className={classes.plainLink}>
                <Button startIcon={<ArrowBack />} color='inherit'>Back</Button>
              </A> */}
            </Grid>
            <Grid item md={6}>
              <Paper>
                <div className={classes.cell + ' ' + classes.headerCell}>
                  <div className={classes.floatingImgBox}>
                    <div className={classes.floatingImg} />
                  </div>
                  <span className={classes.challengeTitle}>
                    Error loading challenge
                  </span>
                  <span>{errorMessage}</span>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div>
        <PageHeader title='Challenges' />
        <div className={classes.mainPanel}>
          <Grid container spacing={2}>
            <Grid item md={4} className={classes.summaryCol}>
              <div className={classes.backContainer}>
                {/* <A href='../challenges' className={classes.plainLink}>
                  <Button startIcon={<ArrowBack />} color='inherit'>All Challenges</Button>
                </A> */}
              </div>
              <div>
                <Paper>
                  <div className={classes.cell + ' ' + classes.headerCell}>
                    <div className={classes.floatingImgBox}>
                      <div className={classes.floatingImg} />
                    </div>
                    <span className={classes.challengeTitle}>
                      Loading...
                    </span>
                  </div>
                </Paper>
              </div>
            </Grid>

            <Grid item md={8} className={classes.detailCol}>
              <ChallengeCard classes={classes}>
                <div>
                  <div className={classes.progressNotes}>
                    <span className={classes.progressMain}>Loading...</span>
                  </div>
                  <div className={classes.progressTrack}>
                    <div className={classes.progressTrackEmpty} />
                  </div>
                </div>
              </ChallengeCard>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }

  let status = { unknown: true }
  if (challenge.startDate) {
    if (challenge.startDate.isAfter()) {
      status = { msg: `Starting in ${challenge.startDate.diff(moment(), 'days')} days` }
    } else {
      if (challenge.endDate) {
        if (challenge.endDate.isAfter()) {
          status = {
            active: true,
            msg: `${challenge.endDate.diff(moment(), 'days')} days remaining`
          }
          if (challenge.challengeClosed) {
            status.detail = 'This challenge is close to ending and cannot be joined.'
          }
        } else {
          status = {
            msg: 'Complete'
          }
        }
      } else {
        if (challenge.completed) {
          status = {
            msg: 'Complete'
          }
        } else {
          status = {
            active: true,
            msg: 'In progress',
            detail: 'This challenge will end when the collective goal is reached.'
          }
          if (challenge.challengeClosed) {
            status.detail = 'This challenge is close to ending and cannot be joined.'
          }
        }
      }
    }
  }

  let dataStatus = 'future'
  if (challenge.challengeStats && !challenge.challengeStats.fake) dataStatus = 'active'
  if (challenge.completed) dataStatus = 'past'

  return (
    <div>
      <PageHeader title='Challenges' />
      <div className={classes.mainPanel}>
        <Grid container spacing={2}>
          <Grid item md={4} className={classes.summaryCol}>
            <div className={classes.backContainer}>
              {/* <A href='../challenges' className={classes.plainLink}>
                <Button startIcon={<ArrowBack />} color='inherit'>All Challenges</Button>
              </A> */}
            </div>
            <div>
              <Paper>
                <div className={classes.cell + ' ' + classes.headerCell}>
                  <div className={classes.floatingImgBox}>
                    {challenge.icon
                      ? <img className={classes.floatingImg} src={challenge.icon} alt='Challenge icon' />
                      : <div className={classes.floatingImg} />}
                  </div>
                  <Typography variant='h4' className={classes.challengeTitle}>
                    {challenge.name}
                  </Typography>
                  <p className={classes.challengeDesc}>{challenge.description}</p>
                  <Typography variant='h4' className={classes.challengeType}>
                    {challenge.teams && challenge.teams.length > 0
                      ? 'TEAM CHALLENGE'
                      : 'COOPERATIVE CHALLENGE'}
                  </Typography>
                  <span className={challenge.approved ? null : classes.pending}>
                    {challenge.approved
                      ? challenge.visibilityDate
                        ? `Visible from ${challenge.visibilityDate.format('MMM D')}`
                        : 'Visible now'
                      : permissions.check('challenge', 'publisher')
                        ? 'Ready to publish'
                        : 'Pending approval'}
                  </span>
                  {/* challenge.visibilityDate &&
                    <span>Visible from {challenge.visibilityDate.format('MMM D')}</span> */}
                  {challenge.startDate &&
                    <span>
                      {challenge.endDate
                        ? `${challenge.startDate.format('MMM D')} — ${challenge.endDate.format('MMM D, YYYY')}`
                        : `${challenge.startDate.isAfter() ? 'Starts' : 'Started'} on ${challenge.startDate.format('MMMM D, YYYY')}`}
                    </span>}
                  <span>
                    {challenge.joinCode
                      ? `Joinable with code ${challenge.joinCode || '?????'}`
                      : challenge.privacy === 'GROUP'
                        ? 'Joinable by any group member'
                        : 'Joinable by anyone'}
                  </span>
                  <span>{challenge.participantCount} participants</span>
                  {!status.unknown &&
                    <>
                      <div className={classes.statusPill + ' ' + (status.active ? classes.statusPillActive : '')}>{status.msg}</div>
                      <div className={classes.statusDetail}>{status.detail}</div>
                    </>}
                  <div className={classes.buttonRow}>
                    {/* {challenge.startDate && challenge.startDate.diff(moment(), 'hours') < 24
                      ? (
                        <Tooltip title={`This challenge ${challenge.startDate.isAfter() ? 'is too close to starting' : 'has already started'} and can't be edited.`}>
                          <div>
                            <Button color='primary' disabled>Edit</Button>
                          </div>
                        </Tooltip>)
                      : (
                        <A href={`./${challengeId}/edit`} className={classes.plainLink}>
                          <Button color='primary'>Edit</Button>
                        </A>)
                        } */}

                    {challenge.startDate.diff(moment()) >= 0 &&
                      ((challenge.approved && permissions.check('challenge', 'editor')) || permissions.check('challenge', 'publisher')) &&
                        <Button
                          color='primary'
                          onClick={() => {
                            if (permissions.check('challenge', 'publisher') || !challenge.approved) {
                              challengesService.publishChallenge(groupId, challengeId, !challenge.approved).then(() => {
                                setChallenge(chal => ({
                                  ...chal,
                                  approved: !chal.approved
                                }))
                              })
                            } else {
                              setUnpublishing(true)
                            }
                          }}
                        >
                          {challenge.approved ? 'Unpublish' : 'Publish'}
                        </Button>}

                    <Dialog open={unpublishing} onClose={() => setUnpublishing(false)}>
                      <DialogContent>
                        <DialogContentText className={classes.unpublishBody}>
                          Are you sure you want to unpublish this challenge? A publisher will need to re-approve it later.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setUnpublishing(false)} color='primary'>
                          Cancel
                        </Button>
                        <Button
                          color='primary'
                          onClick={() => {
                            challengesService.publishChallenge(groupId, challengeId, !challenge.approved).then(() => {
                              setChallenge(chal => ({
                                ...chal,
                                approved: !chal.approved
                              }))
                            })
                            setUnpublishing(false)
                          }}
                        >
                          Unpublish
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
              </Paper>
            </div>
          </Grid>

          <Grid item md={8} className={classes.detailCol}>

            <ChallengeCard classes={classes}>
              {
                dataStatus === 'future'
                  ? (
                    <>
                      <div className={classes.progressNotes}>
                        <span className={classes.progressMain}>Starts {challenge.startDate.fromNow()}</span>
                        <span>{challenge.startDate.format('MMMM D, YYYY')}</span>
                      </div>
                      <div className={classes.progressTrack}>
                        <div className={classes.progressTrackEmpty} />
                      </div>
                    </>)
                  : dataStatus === 'past'
                    ? (
                      <>
                        <div className={classes.progressNotes}>
                          <span className={classes.progressMain}>Finished {challenge.endDate && challenge.endDate.fromNow()}</span>
                          <span>{challenge.endDate && challenge.endDate.format('MMMM D, YYYY')}</span>
                        </div>
                        <div className={classes.progressTrack}>
                          <div className={classes.progressTrackFill} style={{ width: '100%' }} />
                        </div>
                      </>)
                    : challenge.endDate
                      ? (
                        <>
                          <div className={classes.progressNotes}>
                            <span className={classes.progressMain + ' active'}>{challenge.endDate.fromNow(true).replace(/^a/, '1')} to go</span>
                            <span>Ends {challenge.endDate.format('dddd, MMMM D')}</span>
                          </div>
                          <ProgressBar classes={classes} progress={challenge.challengeStats.daysComplete / (challenge.endDate.diff(challenge.startDate, 'days'))} />
                          <div className={classes.progressNotes + ' ' + classes.progressNotesBottom}>
                            <span>Started {challenge.startDate.format('dddd, MMMM D')}</span>
                          </div>
                        </>)
                      : (
                        <>
                          <div className={classes.progressNotes}>
                            <span className={classes.progressMain + ' active'}>{challenge.challengeStats.totalBottlesSaved || 0} bottles saved</span>
                            <span>Goal: {challenge.thresholdValue} bottles</span>
                          </div>
                          <ProgressBar classes={classes} progress={challenge.challengeStats.totalBottlesSaved / challenge.thresholdValue} />
                          <div className={classes.progressNotes + ' ' + classes.progressNotesBottom}>
                            <span>Started {challenge.startDate.format('dddd, MMMM D')}</span>
                            <span>Est. finish: {challenge.estimatedEndDate.format('MMMM D, YYYY')}</span>
                          </div>
                        </>)
              }
              {dataStatus !== 'future' && challenge.progressData &&
                <>
                  <Typography variant='h3' className={classes.graphTitle}>Progress</Typography>
                  <div className={classes.graphHolder}>
                    <ErrorBoundary msg='There was an error displaying this graph.'>
                      <Graph volume data={challenge.progressData} lineOnly />
                    </ErrorBoundary>
                  </div>
                </>}
            </ChallengeCard>

            {dataStatus !== 'future' &&
              <ChallengeCard classes={classes} title='Stats'>
                <Grid container spacing={2}>
                  <Grid item lg={6} container>
                    <Grid item xs={12} className={classes.statsHeader}>
                      <Typography variant='h4'>All Participants</Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.statsItem}>
                      <img src={iconTotalVolume} alt='' />
                      <span className={classes.statsVal}>{scaleNumber(formatBareLarge(challenge.challengeStats.totalAmount, units))}</span>
                      <span>Total {units === 'imperial' ? 'Gallons' : 'Liters'}</span>
                    </Grid>
                    <Grid item xs={4} className={classes.statsItem}>
                      <img src={iconBottlesSaved} alt='' />
                      <span className={classes.statsVal}>{scaleNumber(challenge.challengeStats.totalBottlesSaved)}</span>
                      <span>Bottles Saved</span>
                    </Grid>
                    <Grid item xs={4} className={classes.statsItem}>
                      <img src={iconGoalsMet} alt='' />
                      <span className={classes.statsVal}>{Math.round(challenge.challengeStats.daysComplete / challenge.challengeStats.totalDays * 100)}%</span>
                      <span>Goals Met</span>
                    </Grid>
                  </Grid>
                  <Grid item lg={6} container>
                    <Grid item xs={12} className={classes.statsHeader}>
                      <Typography variant='h4'>Average Per Person</Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.statsItem}>
                      <img src={iconTotalVolume} alt='' />
                      <span className={classes.statsVal}>{scaleNumber(formatBareLarge(challenge.challengeStats.totalAmount / (challenge.participantCount || 1), units))}</span>
                      <span>Total {units === 'imperial' ? 'Gallons' : 'Liters'}</span>
                    </Grid>
                    <Grid item xs={4} className={classes.statsItem}>
                      <img src={iconBottlesSaved} alt='' />
                      <span className={classes.statsVal}>{scaleNumber(challenge.challengeStats.totalBottlesSaved / (challenge.participantCount || 1))}</span>
                      <span>Bottles Saved</span>
                    </Grid>
                    <Grid item xs={4} className={classes.statsItem}>
                      <img src={iconGoalsMet} alt='' />
                      <span className={classes.statsVal}>{Math.round(challenge.challengeStats.daysComplete / challenge.challengeStats.totalDays * 100)}%</span>
                      <span>Goals Met</span>
                    </Grid>
                  </Grid>
                </Grid>
              </ChallengeCard>}

            {challenge.teams && challenge.teams.length > 0 &&
              <ChallengeCard
                classes={classes}
                title='Teams'
                labelContent={
                  <>
                    <img src={iconTotalVolume} alt='' />
                    <span className={classes.teamLabelLong}>Total Volume</span>
                    <img src={iconBottlesSaved} alt='' />
                    <span className={classes.teamLabel}>Bottles Saved</span>
                    <img src={iconGoalsMet} alt='' />
                    <span className={classes.teamLabel}>Goals Met</span>
                  </>
              }
              >
                <ol className={classes.teamList}>
                  {challenge.teams.map((team, i) => (
                    <li key={team.id}>
                      <span className={classes.teamPlace}>{i + 1}</span>
                      <div className={classes.teamDetail}>
                        <span>{team.name}</span>
                        {team.challengeStats &&
                          <div className={classes.teamPoints}>
                            <div className={classes.teamPointsBar} style={{ width: `${team.challengeStats.hydrationScore / challenge.challengeStats.hydrationScore * 80}%` }} />
                            <div className={classes.teamPointsDot} />
                            <span>{scaleNumber(team.challengeStats.hydrationScore)}</span>
                          </div>}
                      </div>
                      {team.participantCount &&
                        <>
                          <img src={iconUsers} alt='' />
                          <span className={classes.teamData}>{team.participantCount}</span>
                        </>}
                      {team.challengeStats &&
                        <>
                          <img src={iconTotalVolume} alt='' />
                          <span className={classes.teamDataLong}>{formatLarge(team.challengeStats.totalAmount, units)}</span>
                          <img src={iconBottlesSaved} alt='' />
                          <span className={classes.teamData}>{scaleNumber(team.challengeStats.totalBottlesSaved)}</span>
                          <img src={iconGoalsMet} alt='' />
                          <span className={classes.teamData}>{Math.round(team.challengeStats.daysComplete / team.challengeStats.totalDays * 100)}%</span>
                        </>}
                    </li>
                  ))}
                </ol>
              </ChallengeCard>}

            {challenge.trophies && challenge.trophies.length > 0 &&
              <ChallengeCard classes={classes} title='Trophies'>
                <Grid container>
                  {challenge.trophies && challenge.trophies.map(trophy => (
                    <Grid item md key={trophy.trophyId} className={classes.trophyContainer}>
                      <img src={trophy.image} alt='' className={classes.trophyImg} />
                      <span className={classes.trophyName}>{trophy.name}</span>
                      {/* <span className={classes.trophyDesc}>Awarded when...</span> */}
                      <span
                        className={classes.trophyRecipients}
                        role='button'
                        onClick={async () => {
                          setShowTrophyDialog(true)
                          setTrophyDetails(null)
                          setCopiedEmails(false)
                          const details = await challengesService.getTrophyRecipients(groupId, challengeId, trophy.trophyId)
                          setTrophyDetails(details.trophy)
                        }}
                      >View Recipients
                      </span>
                    </Grid>
                  ))}
                </Grid>
              </ChallengeCard>}

            <ChallengeCard classes={classes} title='Date Participants Joined'>
              {challenge.joinData &&
                <div className={classes.graphHolder}>
                  <ErrorBoundary msg='There was an error displaying this graph.'>
                    <Graph data={challenge.joinData} showGoal={false} />
                  </ErrorBoundary>
                </div>}
            </ChallengeCard>

            {challenge.analytics &&
              <ChallengeCard classes={classes} title='Analytics'>
                <Grid container>
                  <Grid item lg={6}>
                    <ul className={classes.selectList}>
                      <li>
                        <AddCircle />Challenge Page Views
                        <span style={{ marginLeft: 'auto' }}>1375</span><ChevronRight />
                      </li>
                      <li>
                        <AddCircle />Link Clicks
                        <span style={{ marginLeft: 'auto' }}>78</span><ChevronRight />
                      </li>
                      <li>
                        <AddCircle />Times Shared
                        <span style={{ marginLeft: 'auto' }}>124</span><ChevronRight />
                      </li>
                    </ul>
                  </Grid>
                  <Grid item lg={6}>
                    {/* <FakeGraph /> */}
                  </Grid>
                </Grid>
              </ChallengeCard>}

          </Grid>
        </Grid>
      </div>

      <Dialog
        open={showTrophyDialog || false}
        onClose={() => setShowTrophyDialog(null)}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>
          Trophy Recipients
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id='scroll-dialog-description' tabIndex={-1}>
            <p>
              {!trophyDetails
                ? 'Loading...'
                : `${trophyDetails.count} ${trophyDetails.count === 1 ? 'person' : 'people'} received this trophy${(trophyDetails && trophyDetails.owners && trophyDetails.owners.length > 0) ? ':' : '.'}`}
            </p>
            {trophyDetails && trophyDetails.owners && trophyDetails.owners.map(owner => (
              <div key={owner.id} className={classes.ownerRow}>
                <Avatar alt={owner.name} src={owner.profile} />
                <span className={classes.ownerName}>{owner.name}</span>
                <span>{owner.email}</span>
              </div>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {trophyDetails && trophyDetails.owners && trophyDetails.owners.length > 0 &&
            <Button
              color='primary'
              onClick={() => {
                const out = trophyDetails.owners.map(owner => owner.email).join('\n')
                navigator.clipboard.writeText(out)
                setCopiedEmails(true)
              }}
            >
              {copiedEmails ? 'Copied!' : 'Copy Emails'}
            </Button>}
          <Button
            color='primary'
            onClick={() => setShowTrophyDialog(null)}
          >Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

ChallengeDetails.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ChallengeDetails)
