import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import EditStepContent from './EditStepContent'
import EditStepSchedule from './EditStepSchedule'
import EditStepAudience from './EditStepAudience'
import EditStepReview from './EditStepReview'
import { notificationsService } from '../../services/notificationsService'
import { searchService } from '../../services/searchService'
import { addDays, endOfDay } from 'date-fns'
import HidrateCard from '../HidrateCard'

const steps = [{
  component: EditStepContent,
  title: 'Create or Choose a Message',
  id: 'content',
  shortTitle: 'Content',
  buttons: { next: true }
}, {
  component: EditStepSchedule,
  title: 'Set a Schedule',
  id: 'schedule',
  shortTitle: 'Schedule',
  buttons: { back: true, next: true }
}, {
  component: EditStepAudience,
  id: 'audience',
  shortTitle: 'Audience',
  buttons: { back: true, next: true }
}, {
  component: EditStepReview,
  id: 'submit',
  shortTitle: 'Submit'
}]

const NotificationEdit = ({
  groupId,
  notifications,
  setView,
  fetchNotifications,
  msgApprovals,
  setMsgApprovals
}) => {
  const today = new Date()
  const startDate = addDays(today, 2)
  const endDate = endOfDay(addDays(today, 30))
  const [notification, setNotification] = useState({
    repeat: false,
    repeatInterval: '7',
    startDate,
    endDate,
    audience: [],
    reviewRequired: true // enable features and notices referring to the review process
  })
  const [step, setStep] = useState(0)
  const [users, setUsers] = useState([])
  const [subgroups, setSubgroups] = useState([])

  useEffect(() => {
    const abortController = new window.AbortController()

    const fetchUsers = async () => {
      const results = await notificationsService.getUsers(groupId, 0, 10000)
      if (results === 'bad') return setUsers([])
      setUsers(results.users.users.map(user => ({
        ...user.user,
        scheduledNotificationCount: user.notifications.length
      })))
    }
    fetchUsers()

    const fetchSubgroups = async () => {
      const results = await searchService.getSubGroupsMembers(groupId)
      if (results === 'bad') return setSubgroups([])
      setSubgroups(results.subgroupMembers)
    }
    fetchSubgroups()

    return () => abortController.abort()
  }, [groupId])

  const modifyNotification = (obj) => {
    setNotification(notification => ({ ...notification, ...obj }))
  }

  const submitNotification = async () => {
    const abortController = new window.AbortController()

    let notificationId = notification.savedText
    if (!notificationId || notificationId === 'new') {
      const createdContent = await notificationsService.createNewGroupNotification(groupId, notification.text)
      notificationId = createdContent.notification.objectId
    }
    if (notification.repeat) {
      await notificationsService.scheduleRecurringGroupNotification(
        groupId,
        notificationId,
        notification.audience,
        notification.startDate,
        notification.endDate,
        parseInt(notification.repeatInterval)
      )
    } else {
      await notificationsService.scheduleOneTimeGroupNotification(
        groupId,
        notificationId,
        notification.audience,
        notification.startDate
      )
    }
    fetchNotifications(abortController.signal)
  }

  const EditStep = steps[step].component
  const findStep = id => setStep(steps.findIndex(s => s.id === id))

  const cancel = () => setView('list')

  return (
    <div className='grid grid-cols-1 md:grid-cols-4 gap-5'>
      <div className='col-span-3 w-full'>
        <HidrateCard>
          <h2 className='text-xl font-semibold'>{steps[step]?.title ?? ''}</h2>
          <EditStep {...{ notification, modifyNotification, groupId, setStep, findStep, notifications, users, subgroups, msgApprovals, setMsgApprovals, submitNotification }} onCancel={cancel} />
          {steps[step].buttons &&
            <div className='flex items-center justify-between mt-10'>
              <button className='btn btn-neutral' onClick={cancel}>Cancel</button>
              <div className='flex space-x-2.5'>
                {steps[step].buttons.back && <button className='btn btn-neutral' onClick={() => setStep(step - 1)}>Back</button>}
                {steps[step].buttons.next && <button className='btn btn-primary' onClick={() => setStep(step + 1)}>Next</button>}
              </div>
            </div>}
        </HidrateCard>
      </div>
      <div className='hidden md:block'>
        <HidrateCard>
          <ul className='steps steps-vertical'>
            {steps.map((s, i) => (
              <li
                key={i}
                className={`step capitalize ${i <= step ? 'step-primary' : ''}`}
              >
                {s.id}
              </li>
            ))}
          </ul>
        </HidrateCard>
      </div>
    </div>
  )
}

NotificationEdit.propTypes = {
  groupId: PropTypes.string,
  notifications: PropTypes.any,
  setView: PropTypes.func,
  fetchNotifications: PropTypes.func,
  msgApprovals: PropTypes.any,
  setMsgApprovals: PropTypes.func
}

export default NotificationEdit
