import React from 'react'
import './Section.css'

// Props
// imgLeft: Bool - image will appear on left side of screen instead of right
// text: String - html needed for text section
// image: String - path to image
// mblImage: String - path to mobile image
// halfWidth: Bool - card design will only show on text section instead of entire section

export function Section ({ imgLeft, halfWidth, image, mblImage, text }) {
  return (
    <div className='outer-width'>
      <div className={'section' + (imgLeft ? ' img-left' : ' img-right') + (halfWidth ? '' : ' section-bg')}>
        <div className='section-image'>
          <img className={'block' + (mblImage ? ' mbl-hide' : '')} src={image} />
          {mblImage ? <img className='hide mbl-block' src={mblImage} /> : ''}
        </div>
        <div className={'section-text' + (halfWidth ? ' section-bg' : '')}>
          <div className='section-text-inner'>
            {text}
          </div>
        </div>
      </div>
    </div>
  )
}

// Props
// image: String - path to image
// title: String - title text
// noPaddingBottom: Bool - image will have no space between bottom of image and bottom of card
export function ImageSection ({ image, title, noPaddingBottom }) {
  return (
    <div className='outer-width'>
      <div className={'section image-section section-bg' + (noPaddingBottom ? ' mb-none' : '')}>
        <div className='section-full-image'>
          <div className='title'>{title}</div>
          <div className='image-container'>
            <img src={image} />
          </div>
        </div>
      </div>
    </div>
  )
}
