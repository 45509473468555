import React from 'react'

const PregnancyToggle = ({ isPregnant, setIsPregnant }) => {
  return (
    <div className='form-control'>
      <label className='label cursor-pointer'>
        <span className='label-text'>Pregnant</span>
        <input
          type='checkbox'
          className='toggle toggle-primary'
          checked={isPregnant}
          onChange={() => setIsPregnant((prev) => !prev)}
        />
      </label>
    </div>
  )
}

export default PregnancyToggle
