import React from 'react'
import PropTypes from 'prop-types'
import ReviewStep from '../templates/ReviewStep'

import logo from '../../resources/logomark.svg'
import { differenceInDays, differenceInHours, format, formatDistance, isBefore, addDays, differenceInMonths } from 'date-fns'

const notices = [{
  if: notif => !notif.text,
  fatal: true,
  warn: true,
  text: "No text selected for this notification. Choose a message you've sent before, or write a new message.",
  step: 'content'
}, {
  if: notif => notif.repeat && isBefore(notif.endDate, notif.startDate),
  fatal: true,
  warn: true,
  text: 'Seems like the start and end dates are flipped somehow. Try and set them again.',
  step: 'schedule'
}, {
  if: notif => notif.audience.length === 0,
  fatal: true,
  warn: true,
  text: "There aren't any users selected to receive this notification. Choose some users or subgroups.",
  step: 'audience'
}, {
  if: notif => notif.reviewRequired && notif.text && (!notif.savedText || notif.pending) && differenceInHours(notif.startDate, new Date()) > 46,
  text: 'Your message will be submitted for approval, which can take up to 48 hours.'
}, {
  if: notif => notif.reviewRequired && notif.text && (!notif.savedText || notif.pending) && differenceInHours(notif.startDate, new Date()) <= 46,
  warn: true,
  text: notif => `Your message will be submitted for approval, and delivered if it's approved before the ${notif.repeat ? 'start' : 'delivery'} date of ${format(notif.startDate, 'hh:mm a MMMM dddd')}.\
  ${notif.repeat
    // ? `If it's approved after the start date, users will start receiving the notification on the next repeat date (${moment(notif.startDate).add(notif.repeatInterval, 'days').format('MMMM Do')}).`
    ? `If it's approved after the start date, users will start receiving the notification on the next repeat date (${format(addDays(notif.startDate, notif.repeatInterval), 'MMMM dddd')}).`
    : 'Otherwise, this notification will not be delivered.'}\
  You can skip the approval process by picking a previously approved message.`
}, {
  if: notif => differenceInDays(notif.startDate, notif.endDate) > 365,
  text: notif => `This notification is set to repeat over ${differenceInMonths(notif.startDate, notif.endDate)} months. You probably meant to do that, but if you didn't, you may want to double check your settings.`,
  step: 'schedule'
}, {
  if: notif => notif.repeat && differenceInDays(notif.startDate, notif.endDate) < notif.repeatInterval,
  text: 'With these start and end dates, this repeating notification will only happen once.',
  step: 'schedule'
}]

const EditStep = ({ notification, setStep, findStep, submitNotification, onCancel }) => {
  return (
    <ReviewStep
      objTerm='notification'
      data={notification}
      notices={notices}
      onSubmit={submitNotification}
      {...{ onCancel, setStep, findStep }}
    >
      <div className='bg-base-300 p-5 mx-auto w-full my-5 rounded-xl max-w-xl'>
        <div className='uppercase font-semibold leading-3 flex items-center mb-2'>
          <img className='h-[1.5rem] mr-2' src={logo} alt='' />
          Hidrate
          <span className='ml-auto font-normal'>
            {formatDistance(notification.startDate, new Date(), { addSuffix: true })}
          </span>
        </div>
        {notification.text || <span>No notification text selected</span>}
      </div>
      <div className='grid grid-cols-2 p-5'>
        <div className='text-center'>
          <p>Sending on</p>
          <p className='text-lg font-semibold'>
            {format(notification.startDate, 'MMMM d')}
            <br />
            {format(notification.startDate, 'hh:mm a')}
          </p>
          {notification.repeat &&
            <>
              <p>{notification.repeatInterval === '7' ? 'Weekly' : 'Biweekly'} through</p>
              <p className='text-lg font-semibold'>
                {format(notification.endDate, 'MMMM d')}
                <br />
                {(differenceInDays(notification.endDate, notification.startDate) / notification.repeatInterval) + 1} total times
              </p>
            </>}
        </div>
        <div className='text-center'>
          <p>Sending to</p>
          <p className='text-lg font-semibold'>
            {notification.audience.length} users
          </p>
        </div>
      </div>
    </ReviewStep>
  )
}

EditStep.propTypes = {
  notification: PropTypes.object,
  setStep: PropTypes.func,
  findStep: PropTypes.func,
  submitNotification: PropTypes.func,
  onCancel: PropTypes.func
}

export default EditStep
