import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material'
import iconParticipation from '../../resources/challenges-participation.png'
import iconWinner from '../../resources/challenges-winner.png'
import iconStreak from '../../resources/challenges-streak.png'

const styles = theme => ({
  trophy: {
    margin: '16px',
    padding: '24px 8px 16px',
    borderRadius: '16px',
    border: `4px solid ${theme.palette.primary.main}`,
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'background .05s linear',
    '& h6': {
      fontSize: '1.2em',
      margin: 0
    },
    '&:hover': {
      background: '#00000011'
    }
  },
  selected: {
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main
    }
  },
  trophyPlaceholder: {
    width: '160px',
    height: '160px',
    borderRadius: '100px',
    border: '6px dashed #00000044',
    margin: '1em auto'
  },
  trophyIcon: {
    width: '160px',
    height: '160px',
    margin: '1em auto'
  }
})

const trophies = [
  { id: 'participation', title: 'Participation', icon: iconParticipation },
  { id: 'winner', title: 'Winning Team', icon: iconWinner },
  { id: 'streak', title: 'Flawless Streak', icon: iconStreak }
  // { id: 'bottles', title: 'Bottles Saved' },
  // { id: 'custom', title: 'Request Custom Trophy' }
]

const EditStep = ({ classes, data: challenge, modifyData: modifyChallenge }) => {
  const selected = challenge.trophies.map(t => t.trophyType)

  return (
    <>
      <Grid container>
        {trophies.map(trophy => (
          <Grid key={trophy.id} item xs={6} md={4}>
            <div
              role='button'
              className={classes.trophy + (selected.includes(trophy.id) ? ' ' + classes.selected : '')}
              onClick={() => {
                if (selected.includes(trophy.id)) {
                // setSelect(select.filter(id => id !== trophy.id))
                  modifyChallenge({ trophies: challenge.trophies.filter(t => t.trophyType !== trophy.id) })
                } else {
                // setSelect([...select, trophy.id])
                  modifyChallenge({ trophies: [...challenge.trophies, { trophyType: trophy.id }] })
                }
              }}
            >
              <img className={classes.trophyIcon} src={trophy.icon} alt='' />
              <h6>{trophy.title}</h6>
            </div>
          </Grid>
        ))}
      </Grid>
      <span>
        {selected.length === 0
          ? 'The challenge will not have trophies available.'
          : selected.length === 1
            ? `The selected trophy will be ${challenge.editing ? '' : 'created and'} available for participants to achieve.`
            : `These ${selected.length} selected trophies will be ${challenge.editing ? '' : 'created and'} available for participants to achieve.`}
      </span>
    </>
  )
}

EditStep.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  modifyData: PropTypes.func
}

export default withStyles(styles)(EditStep)
