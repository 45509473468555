import React, { useMemo, useState } from 'react'
import { authService } from '../../services/authService'
import { notificationsService } from '../../services/notificationsService'
import { EllipsisVerticalIcon, PlusCircleIcon as PlusCircleIconOutline } from '@heroicons/react/24/outline'
import { PlusCircleIcon as PlusCircleIconSolid } from '@heroicons/react/24/solid'

const EditStep = ({ groupId, notification, modifyNotification, notifications, msgApprovals, setMsgApprovals }) => {
  const messages = useMemo(() => notifications.filter(n => n.status !== 'rejected'), [notifications])
  const permissions = authService.getPermissions(groupId)
  const isPublisher = permissions.check('notif', 'publisher')

  const handleNewNotification = () => {
    if (notification.savedText !== 'new') {
      modifyNotification({
        savedText: 'new',
        text: '',
        approved: isPublisher
      })
    }
  }

  return (
    <div className='max-h-[35rem] overflow-y-auto mt-5'>
      <table className='table'>
        <tbody>
          <tr
            onClick={handleNewNotification}
            className={`${notification.savedText === 'new' ? 'bg-base-200' : ''}`}
          >
            <td className='flex items-center space-x-2.5'>
              {notification.savedText === 'new'
                ? <PlusCircleIconSolid height={25} width={25} className='text-primary' />
                : <PlusCircleIconOutline height={25} width={25} className='text-primary' />}
              <input
                type='text'
                placeholder='Add a message'
                className='input input-bordered w-full text-normal'
                disabled={!permissions.check('notif', 'editor')}
                value={notification.savedText === 'new' ? notification.text : ''}
                onChange={event => {
                  modifyNotification({
                    savedText: 'new',
                    text: event.target.value
                  })
                }}
              />
            </td>

            <td className='text-right'>
              <span>
                {isPublisher
                  ? <>New messages are<br /> auto-approved.</>
                  : <>Messages require<br /> approval by admin.</>}
              </span>
            </td>

          </tr>
          {messages.map(message => (
            <MessageRow
              key={message.objectId}
              active={message.objectId === notification.savedText}
              approved={message.objectId in msgApprovals ? msgApprovals[message.objectId] : message.enabled}
              {...{ message, modifyNotification, isPublisher, msgApprovals, setMsgApprovals, groupId }}
            />
          )).reverse()}
        </tbody>
      </table>
    </div>
  )
}

const MessageRow = ({ message, active, approved, setMsgApprovals, isPublisher, modifyNotification, groupId }) => {
  const [loading, setLoading] = useState(false)
  const handleApprove = () => {
    setLoading(true)
    notificationsService.publishNotification(groupId, message.objectId, true).then(() => {
      setLoading(false)
      setMsgApprovals(obj => ({ ...obj, [message.objectId]: true }))
      modifyNotification({
        approved: true
      })
    }).catch(err => {
      setLoading(false)
      window.alert('Error approving message. ' + err)
    })
  }

  const handleUnapprove = () => {
    setLoading(true)
    notificationsService.publishNotification(groupId, message.objectId, false).then(() => {
      setLoading(false)
      setMsgApprovals(obj => ({ ...obj, [message.objectId]: false }))
      modifyNotification({
        approved: false
      })
    }).catch(err => {
      setLoading(false)
      window.alert('Error unapproving message. ' + err)
    })
  }

  return (
    <tr className={`hover hover:cursor-pointer ${active && 'bg-base-200'}`}>
      <th
        onClick={() => {
          modifyNotification({
            savedText: message.objectId,
            text: message.text,
            approved
          })
        }}
      >
        <div className='flex items-center justify-start space-x-2.5'>
          <input
            type='radio'
            className='radio radio-primary'
            checked={active}
            onChange={() => {
              modifyNotification({
                savedText: message.objectId,
                text: message.text,
                approved
              })
            }}
          />
          <span className='text-md font-normal'>{message.text}</span>
        </div>
      </th>
      <td className='flex items-center justify-end space-x-2.5'>
        {loading
          ? <span className='loading loading-spinner loading-sm' />
          : approved
            ? <span>APPROVED</span>
            : isPublisher
              ? (
                <button
                  className='btn btn-primary btn-outline btn-sm'
                  onClick={handleApprove}
                >
                  Approve
                </button>
                )
              : <span>PENDING</span>}
        <div className='dropdown dropdown-end'>
          <label tabIndex={0} className='btn btn-sm btn-ghost btn-square'>
            <EllipsisVerticalIcon height={25} width={25} />
          </label>
          <ul tabIndex={0} className='dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52'>
            <li>
              <button onClick={() => {
                modifyNotification({
                  savedText: 'new',
                  text: message.text,
                  approved: isPublisher
                })
              }}
              >
                Edit a copy
              </button>
            </li>
            {approved && (
              <li>
                <button onClick={handleUnapprove}>
                  Unapprove
                </button>
              </li>)}
          </ul>
        </div>
      </td>
    </tr>
  )
}

export default EditStep
