import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FileBase64 from 'react-file-base64'
import { withStyles } from '@mui/styles'

const styles = () => ({
})

const FileUpload = ({ onDone, classes, ...props }) => {
  const [errorMessage, setErrorMessage] = useState(null)

  const handleDone = (file) => {
    console.log(JSON.stringify(file))
    const partsOfSizeString = file.size.split(' ')
    const size = parseInt(partsOfSizeString[0])
    const imageSizeLimit = 2048
    if (size > imageSizeLimit) {
      setErrorMessage('Image size should be less than 2 MB')
      return
    }

    const validImageTypes = ['image/jpeg', 'image/png']
    if (!validImageTypes.includes(file.type)) {
      setErrorMessage('Only jpeg and png images are allowed')
      return
    }
    onDone(file)
  }

  return (
    <>
      <FileBase64 onDone={handleDone} {...props} />
      {errorMessage && <span className={classes.errorMsg}>{errorMessage}</span>}
    </>
  )
}

FileUpload.propTypes = {
  classes: PropTypes.object.isRequired,
  onDone: PropTypes.func
}

export default withStyles(styles)(FileUpload)

/*
"name":"FALL 2022_Bottle Concepting_a03__0001_SpotGloss_Black-VeryPeri.jpg"
"type":"image/jpeg"
"size":"248 kB"

image/jpeg
image/png

image/gif
image/tiff
image/bmp
*/
