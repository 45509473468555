import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import EditStepDetails from './EditStepDetails'
import EditStepSchedule from './EditStepSchedule'
import EditStepTeams from './EditStepTeams'
import EditStepTrophies from './EditStepTrophies'
import EditStepReview from './EditStepReview'
import StepFlow from '../templates/StepFlow'
import moment from 'moment'
import { challengesService } from '../../services/challengesService'
import { useGroup } from '../global/groupInfo'
import { useParams, useNavigate } from 'react-router-dom'

const styles = () => ({
})

const steps = [{
  component: EditStepDetails,
  id: 'details',
  title: 'Edit Details',
  shortTitle: 'Details',
  buttons: true
}, {
  component: EditStepSchedule,
  id: 'schedule',
  title: 'Set Schedule',
  shortTitle: 'Schedule',
  buttons: true
}, {
  when: challenge => challenge.goal === 'DATE',
  component: EditStepTeams,
  id: 'teams',
  title: 'Manage Teams',
  shortTitle: 'Teams',
  buttons: true
}, {
  component: EditStepTrophies,
  id: 'trophies',
  title: 'Choose Trophies',
  shortTitle: 'Trophies',
  buttons: true
}, {
  component: EditStepReview,
  id: 'review',
  shortTitle: 'Save'
}]

const defaultDate = moment().utc(true).startOf('day').add(12, 'hours')

const ChallengeEdit = () => {
  const groupInfo = useGroup()
  const { groupId, challengeId } = useParams()
  const navigate = useNavigate()
  const [challenge, setChallenge] = useState({
    editing: true,
    name: '',
    description: '',
    icon: null,
    privacy: 'GROUP', // PUBLIC, GROUP, PRIVATE
    joinCode: '',
    goal: 'DATE', // THRESHOLD, DATE
    metric: 'POINT', // POINT, BOTTLES
    thresholdValue: 1000,
    visibilityDate: moment(defaultDate).add(3, 'days'),
    startDate: moment(defaultDate).add(7, 'days'),
    endDate: moment(defaultDate).add(7, 'days').add(1, 'month'),
    teams: [{ name: '', id: '__0' }, { name: '', id: '__1' }],
    trophies: ['participation', 'winner', 'streak'].map(id => ({ trophyType: id }))
    // reviewRequired: true // enable features and notices referring to the review process
  })
  const [origName, setOrigName] = useState(null)

  useEffect(() => {
    challengesService.getChallengeDetails(groupId, challengeId).then(res => {
      setOrigName(res.challenge.name)
      setChallenge({
        ...res.challenge,
        editing: true,
        privacy: res.challenge.privacy === 'PUBLIC' ? 'GROUP' : res.challenge.privacy,
        targetGroup: groupInfo.hasParent ? groupId : 'ROOT',
        icon: undefined,
        currentIcon: res.challenge.icon,
        visibilityDate: moment(res.challenge.visibilityDate),
        startDate: moment(res.challenge.startDate),
        endDate: moment(res.challenge.endDate),
        teams: (res.challenge.teams || []).map(team => ({
          name: team.name,
          id: team.id,
          isCurrent: true
        })),
        trophies: (res.challenge.trophies || []).map(trophy => ({
          trophyType: trophy.type
        }))
      })
    })
  }, [groupId, challengeId])

  const modifyChallenge = (obj) => {
    setChallenge(challenge => ({ ...challenge, ...obj }))
  }

  const submitChallenge = async () => {
    await challengesService.editChallenge(groupId, challengeId, {
      ...challenge,
      trophies: challenge.trophies.map(trophy => ({ type: trophy.trophyType })),
      teams: challenge.goal === 'DATE' ? challenge.teams : undefined,
      visibilityDate: challenge.privacy !== 'PRIVATE' ? challenge.visibilityDate : undefined,
      endDate: challenge.goal === 'DATE' ? challenge.endDate : undefined,
      thresholdValue: challenge.goal === 'THRESHOLD' ? challenge.thresholdValue : undefined
    })
  }

  const cancel = () => {
    navigate(-1)
  }

  return (
    <StepFlow
      title='Edit Challenge'
      subtitle={origName}
      allSteps={steps}
      data={challenge}
      modifyData={modifyChallenge}
      onSubmit={submitChallenge}
      onCancel={cancel}
      {...{ groupId }}
    />
  )
}

ChallengeEdit.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ChallengeEdit)
