import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { searchService } from '../../services/searchService'
import image from '../../resources/hidrate-logo.svg'
import HidrateCard from '../../components/HidrateCard'
import { PencilSquareIcon } from '@heroicons/react/24/outline'

const UserInfo = ({ userId, isEditor }) => {
  const [state, setState] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    searchService.getHardware(userId).then(res => {
      setState(res)
      setIsLoading(false)
    })
  }, [userId])

  if (isLoading) { return <HidrateCard /> }

  if (!state.user) {
    return (
      <HidrateCard>
        <div className='avatar'>
          <div className='w-[150px] rounded-full'>
            <img src={image} alt='Avatar' />
          </div>
        </div>
      </HidrateCard>
    )
  }

  let bottleModel = 'HidrateSpark'
  if (state.bootloaderVersion >= 50) bottleModel = 'HidrateSpark PRO'
  else if (state.bootloaderVersion >= 30) bottleModel = 'HidrateSpark 3'
  else if (state.bootloaderVersion >= 1) bottleModel = 'HidrateSpark 2.0'

  return (
    <HidrateCard>

      <div className='flex flex-col h-full items-center justify-center relative p-5'>
        {isEditor && (
          <div className='absolute right-0 top-0 -mt-2.5 -mr-2.5'>
            <button
              className='btn btn-circle btn-ghost'
              onClick={() => document.getElementById('personal_params_modal').showModal()}
            >
              <PencilSquareIcon height={25} width={25} />
            </button>
          </div>
        )}
        <div className='avatar'>
          <div className='w-[100px] rounded-full mb-5'>
            <img
              alt='Avatar'
              src={(state.user && state.user.profileImage && state.user.profileImage.url)
                ? state.user.profileImage.url
                : image}
            />
          </div>
        </div>
        <div className='text-center'>
          <h3 className='text-xl font-semibold'>{state.user.name}</h3>
          <p className='font-thin text-gray-500'>{state.user.email}</p>
          <p className='font-thin text-gray-500'>{state.user.dept}</p>
          <h4 className='text-lg font-semibold mt-2.5'>{bottleModel}</h4>
          <p className='font-thin text-gray-500'>Firmware: {state.bootloaderVersion}.{state.minorVersion}</p>
          <h4 className='text-lg font-semibold mt-2.5'>{state.device ? state.device.replace(/_/g, ' ') : 'Unknown device'}</h4>
          <p className='font-thin text-gray-500'>Version: {state.installVersion}</p>
        </div>
      </div>

    </HidrateCard>
  )
}

UserInfo.propTypes = {
  userId: PropTypes.string.isRequired
}

export default UserInfo
