import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { FormControl, TextField, Grid, Typography, RadioGroup, NativeSelect } from '@mui/material'
import RadioOption from '../global/RadioOption'
import FileUpload from '../global/FileUpload'
import { styled } from '@mui/material/styles'

const TopBarIcon = styled('img')(() => ({
  background: '#ccc',
  borderRadius: '2em',
  marginLeft: 2,
  height: 4,
  width: 10,
  objectFit: 'contain'
}))

const AdImage = styled('img')(() => ({
  maxHeight: '20em',
  maxWidth: '100%',
  display: 'block',
  marginBottom: 1,
  boxShadow: '0 0 1px #aaa',
  background: '#808080'
}))

const DataField = ({ maxLength, codeMode, value, onChange }) => {
  const [val, setVal] = useState(value)
  const debounceRef = useRef(null)

  return (
    <FormControl fullWidth>
      <TextField
        inputProps={{ maxLength }}
        variant='outlined'
        value={val}
        onChange={event => {
          let newVal = event.target.value
          if (codeMode) {
            newVal = newVal.toUpperCase().replace(/[ \-_]/, '')
          }
          setVal(newVal)
          clearTimeout(debounceRef.current)
          debounceRef.current = setTimeout(() => onChange(newVal), 300)
        }}
        onBlur={() => {
          clearTimeout(debounceRef.current)
          onChange(val)
        }}
      />
    </FormControl>
  )
}

DataField.propTypes = {
  maxLength: PropTypes.any,
  codeMode: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func
}

const EditStepContent = ({ data: ad, modifyData: modifyAd }) => {
  return (
    <Grid container spacing={2}>
      <RadioGroup>
        <Grid container direction='column'>
          <RadioOption
            title='Home Page'
            detail='A button appears in the top bar on the home page of the app. Tap it to reveal the full ad.'
            value={ad.location === 'home'}
            onClick={() => modifyAd({ location: 'home' })}
          />
          <RadioOption
            title='Bottles Tab'
            detail='The full ad image appears below the list of your bottles.'
            value={ad.location === 'bottles'}
            onClick={() => modifyAd({ location: 'bottles' })}
          />
        </Grid>
      </RadioGroup>

      <Grid
        item xs={12} sx={{ marginTop: '2em' }}
      >
        <Typography variant='h3'>Upload Image</Typography>
      </Grid>

      {ad.location === 'home' &&
        <>
          <Grid item md={4} lg={3} sx={{ '& p': { marginTop: '1em' } }}>
            <p>Top bar icon</p>
          </Grid>
          <Grid item md={8} lg={9} container alignItems='center'>
            <FormControl>
              <NativeSelect
                value={ad.icon}
                onChange={event => modifyAd({ icon: event.target.value })}
              >
                <option value='https://hidrate-images.s3.amazonaws.com/ads/lightning.png'>Lightning</option>
                <option value='https://hidrate-images.s3.amazonaws.com/ads/new.png'>New</option>
                <option value='https://hidrate-images.s3.amazonaws.com/ads/SALE.png'>Sale</option>
                {/* <option value={false}>Custom</option> */}
              </NativeSelect>
            </FormControl>
            {ad.icon && <TopBarIcon src={ad.icon} alt='Top bar icon for ad' />}
          </Grid>
        </>}

      <Grid item md={4} lg={3} sx={{ '& p': { marginTop: '1em' } }}>
        <p>Ad image</p>
      </Grid>
      <Grid item md={8} lg={9} container direction='column' alignItems='flex-start' justifyContent='center'>
        {ad.image
          ? <AdImage src={ad.image.imageString} alt='' />
          : ad.currentImage
            ? <AdImage src={ad.currentImage} alt='' />
            : <div />}
        <FileUpload
          onDone={data => {
            modifyAd({
              image: {
                name: data.name,
                encoding: data.type,
                imageString: data.base64
              }
            })
          }}
        />
        <Typography variant='caption'>
          Recommended dimensions: {ad.location === 'home' ? '715 x 1000 px' : '1440 x 810 px'}.
        </Typography>
      </Grid>

      <Grid item md={4} lg={3} sx={{ '& p': { marginTop: '1em' } }}>
        <p>Image description</p>
      </Grid>
      <Grid item md={8} lg={9}>
        <DataField
          placeholder='Describe the content and text of the image for accessibility.'
          maxLength={500}
          multiline
          value={ad.imageDescription}
          onChange={value => {
            modifyAd({ imageDescription: value })
          }}
        />
      </Grid>

      <Grid item md={4} lg={3} sx={{ '& p': { marginTop: '1em' } }}>
        <p>Display weight</p>
      </Grid>
      <Grid item md={8} lg={9}>
        <DataField
          placeholder='0'
          type='number'
          value={ad.displayWeight}
          onChange={value => {
            modifyAd({ displayWeight: value })
          }}
        />
        <Typography variant='caption'>
          Ads with a higher weight are ordered first in the app.
        </Typography>
      </Grid>

      <Grid
        item xs={12} sx={{ marginTop: '2em' }}
      >
        <Typography variant='h3'>Link to URL</Typography>
      </Grid>

      <Grid item md={12}>
        <DataField
          placeholder='https://hidratespark.com'
          value={ad.link}
          onChange={value => {
            modifyAd({ link: value })
          }}
        />
        <Typography variant='caption'>
          Tracking and campaign parameters are not automatically added; make sure this URL includes any UTM parameters you want.
        </Typography>
      </Grid>
    </Grid>
  )
}

EditStepContent.propTypes = {
  data: PropTypes.object,
  modifyData: PropTypes.func
}

export default EditStepContent
