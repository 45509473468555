const mlToL = 1000
const mlToOz = 29.57353
const mlToGal = 3785.4118
const unitMl = ' ml'
const unitL = ' L'
const unitKL = ' kL'
const unitOz = ' oz.'
const unitGal = ' gal.'
const unitKGal = 'k gal.'

function round(val, places = 0) {
  const pow = Math.pow(10, places)
  return Math.round(val * pow) / pow
}

// decimal oz / normal ml
export function formatVol (val = 0, units) {
  if (units === 'imperial') {
    return round(val / mlToOz, 1) + unitOz
  } else {
    return round(val) + unitMl
  }
}

// rounded oz / normal ml
export function formatRound (val = 0, units) {
  if (units === 'imperial') {
    return round(val / mlToOz) + unitOz
  } else {
    return round(val) + unitMl
  }
}

// 2 decimal place oz / normal ml
export function formatPrecise (val = 0, units) {
  if (units === 'imperial') {
    return round(val / mlToOz, 2) + unitOz
  } else {
    return round(val) + unitMl
  }
}

// gal / l, scaled above 10k gal / 100 kL
export function formatLarge (val = 0, units) {
  if (units === 'imperial') {
    if (val > 37854118) {
      return round(val / mlToGal / 100, 1) + unitKGal
    }
    return round(val / mlToGal) + unitGal
  } else {
    if (val > 100000000) {
      return round(val / mlToL / 1000) + unitKL
    }
    return round(val / mlToL) + unitL
  }
}

// large above 10 gal / 50 l
export function formatAuto (val = 0, units) {
  if (units === 'imperial') {
    if (val > 37854) { // 10 gallons
      return formatLarge(val, units)
    } else {
      return formatVol(val, units)
    }
  } else {
    if (val > 50000) { // 50 liters
      return formatLarge(val, units)
    } else {
      return formatVol(val, units)
    }
  }
}

// rounded without units (returns number)
export function formatBare (val = 0, units) {
  if (units === 'imperial') {
    return round(val / mlToOz)
  } else {
    return round(val)
  }
}

// rounded large without units (returns number)
export function formatBareLarge (val = 0, units) {
  if (units === 'imperial') {
    return round(val / mlToGal)
  } else {
    return round(val / mlToL)
  }
}

// format two amounts as goal, rounded
export function formatGoal (val = 0, goal = 0, units) {
  if (units === 'imperial') {
    return `${round(val / mlToOz)} / ${round(goal / mlToOz)} oz.`
  } else {
    return `${round(val)} / ${round(goal)} ml`
  }
}

export function convertForGraphing (val = 0, units) {
  if (units === 'imperial') {
    return val / mlToOz
  } else {
    return val
  }
}

export function deconvertForGraphing (val = 0, units) {
  if (units === 'imperial') {
    return val * mlToOz
  } else {
    return val
  }
}
