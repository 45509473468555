import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import React, { useContext, useState, useEffect } from 'react'
import { Paper, CircularProgress, Box, Link } from '@mui/material'
import Typography from '@mui/material/Typography'
import Navbar from '../landing/Navbar'
import '../landing/LandingPage.css'
import imageName from '../../resources/hidrate-health.svg'
import { AuthContext } from '../../context/AuthContext'
import Alert from '@mui/material/Alert'

const SignupPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')

  const [loggingIn, setLoggingIn] = useState(false)

  const { signup, error } = useContext(AuthContext)

  useEffect(() => {

  }, [error])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoggingIn(true)
    try {
      await signup({ password, email, name })
    } catch (e) {
      console.log(e)
    } finally {
      setLoggingIn(false)
    }
  }

  return (
    <div
      className='hst-landing'
      style={{
        backgroundColor: '#f2f2f5',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Navbar />
      {error &&
        <Alert severity='error'>{error}</Alert>}

      <Box sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      >
        <form onSubmit={handleSubmit}>
          <Paper sx={{
            padding: 5,
            width: 500,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          >
            <img width='75%' alt='HidrateSpark+' src={imageName} />

            <Box sx={{ height: 25 }} />

            <TextField
              fullWidth
              name='email'
              label='Email'
              type='email'
              value={email}
              onChange={(e) => { setEmail(e.target.value) }}
              autoFocus
              required
            />

            <Box sx={{ height: 15 }} />

            <TextField
              fullWidth
              name='name'
              label='Name'
              type='text'
              value={name}
              onChange={(e) => { setName(e.target.value) }}
              required
            />

            <Box sx={{ height: 15 }} />

            <TextField
              fullWidth
              name='password'
              label='Create Password'
              type='password'
              value={password}
              onChange={(e) => { setPassword(e.target.value) }}
              required
            />

            <Box sx={{ height: 35 }} />

            <Button
              fullWidth
              type='submit'
              variant='contained'
              sx={{ color: 'white' }}
            >
              {loggingIn ? <CircularProgress color='inherit' size='1.7em' /> : 'Sign Up'}
            </Button>

            <Box sx={{ height: 15 }} />

            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
              <Typography mr={1}>Already have an account?</Typography>
              <Link href='/login'>Log in</Link>
            </Box>
          </Paper>
        </form>
      </Box>
    </div>
  )
}

export default SignupPage
