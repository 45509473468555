import React, { useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import PageHeader from '../PageHeader'
import moment from 'moment'
import { CircularProgress, Paper } from '@mui/material'
import { challengesService } from '../../services/challengesService'
import { authService } from '../../services/authService'
import FilterHeader from '../global/FilterHeader'
import { useParams } from 'react-router-dom'
import ReactGA from 'react-ga4'

const styles = theme => ({
  mainPanel: {
  },
  plainLink: {
    textDecoration: 'none',
    color: 'inherit'
  },
  addButton: {
    color: 'white',
    margin: '0 0 2em auto'
  },
  paper: {
    padding: theme.spacing(8),
    color: 'black'
  },
  filterHeader: {
    padding: '2em 2em 0'
  },
  challenge: {
    height: '4em',
    padding: '0 1em',
    borderBottom: '1px solid #ddd',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  clickable: {
    transition: 'background .05s ease-in-out',
    '&:hover': {
      background: '#e4f6f7'
    }
  },
  icon: {
    width: '3em',
    height: '3em',
    borderRadius: '1.5em',
    border: '1px solid #ddd',
    marginRight: '1em',
    background: '#eee',
    objectFit: 'cover'
  },
  title: {
    fontSize: '1.1em',
    fontWeight: 'bold'
  },
  detail: {
    color: '#666',
    marginLeft: 'auto',
    marginRight: '1em'
  },
  edit: {
    marginLeft: 'auto'
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  message: {
    padding: theme.spacing(2),
    textAlign: 'center',
    fontSize: '1.1em',
    fontStyle: 'italic',
    color: '#888'
  }
})

const ChallengesList = ({ classes }) => {
  const [challenges, setChallenges] = useState([])
  const challengeCounts = useRef({})
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState('all')

  const { groupId } = useParams()

  useEffect(() => {
    console.log(window.location.pathname)
    ReactGA.send({
      hitType: 'pageview',
      page: window?.location?.pathname ?? '',
      title: 'Challenge List Page'
    })
  }, [])

  const fetchChallenges = useCallback(async (abortController) => {
    setLoading(true)
    const results = await challengesService.getGroupChallenges(groupId, abortController.signal)
    if (results === 'bad' || results.message) {
      setChallenges([])
      setErrorMessage(results.message || 'Error loading notifications.')
      return
    }
    challengeCounts.current = {}
    setChallenges(results.challenges.reverse().map(challenge => {
      const out = {
        ...challenge,
        startDate: challenge.startDate ? moment(challenge.startDate) : moment(),
        endDate: challenge.endDate ? moment(challenge.endDate) : null
      }
      if (out.startDate.isAfter()) {
        out.filterStatus = 'upcoming'
        out.dateSummary = `starts ${out.startDate.format('MMM D, YYYY')}`
      } else if (out.completed) {
        out.filterStatus = 'complete'
        out.dateSummary = (
          out.endDate
            ? `ended ${out.endDate.format('MMM D, YYYY')}`
            : 'complete'
        )
      } else {
        out.filterStatus = 'active'
        out.dateSummary = (
          out.endDate
            ? `ends ${out.endDate.format('MMM D, YYYY')}`
            : `started ${out.startDate.format('MMM D, YYYY')}`
        )
      }
      challengeCounts.current[out.filterStatus] = (challengeCounts.current[out.filterStatus] || 0) + 1
      return out
    }).sort((a, b) => b.startDate.diff(a.startDate)))
    setLoading(false)
  }, [groupId])

  useEffect(() => {
    const abortController = new window.AbortController()

    const syncFetch = () => {
      fetchChallenges(abortController)

      return () => {
        abortController.abort()
      }
    }

    return syncFetch()
  }, [fetchChallenges])

  const [permissions, setPermissions] = useState(authService.blankPermissions)
  useEffect(() => {
    return authService.listenToPermissions(groupId, setPermissions)
  }, [groupId])

  const challengesFiltered = filter === 'all' ? challenges : challenges.filter(chal => chal.filterStatus === filter)

  return (
    <div>
      <PageHeader title='Challenges' />
      <div className={classes.mainPanel}>
        <Paper>
          <div className={classes.filterHeader}>
            <FilterHeader
              filters={[{
                title: 'Filter by',
                current: filter,
                onChange: setFilter,
                options: [
                  { id: 'all' },
                  { id: 'upcoming', prune: true },
                  { id: 'active' },
                  { id: 'complete' }
                ],
                counts: { all: challenges.length, ...challengeCounts.current }
              }]}
              canAdd={permissions.check('challenge', 'editor')}
              addText='Create a Challenge'
              addLink='./create'
            />
          </div>
          {challengesFiltered.map(challenge => (
            <a key={challenge.challengeId} href={'challenges/' + challenge.challengeId} className={classes.plainLink}>
              <div className={classes.challenge + ' ' + classes.clickable}>
                {challenge.icon && challenge.icon.startsWith('http')
                  ? <img src={challenge.icon} alt='Challenge icon' className={classes.icon} />
                  : <div className={classes.icon} />}
                <span className={classes.title}>{challenge.name}</span>
                <span className={classes.detail}>
                  {challenge.teams.length > 0 && (challenge.teams.length + ' teams – ')}
                  {challenge.dateSummary}
                </span>
              </div>
            </a>
          ))}
          {loading &&
            <div className={classes.loading}>
              <CircularProgress />
            </div>}
          {!loading && challengesFiltered.length === 0 &&
            <div className={classes.message}>
              No challenges here yet.
            </div>}
          {!loading && errorMessage &&
            <div className={classes.message}>
              Error: {errorMessage}
            </div>}
        </Paper>
      </div>
    </div>
  )
}

ChallengesList.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ChallengesList)
