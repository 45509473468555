import React from 'react'

const GraphLines = (height, width, highest, unit, format = v => v) => {
  const ceiling = highest * 1.00 // used to be 1.15
  const graphHeight = height * 0.90
  const lineSpace = graphHeight / 10
  const tags = []
  for (let i = 1; i <= 11; i++) {
    tags.push(
      <g key={i}>
        <text x='40' y={graphHeight - (i * lineSpace)} textAnchor='end' dominantBaseline={i === 10 ? 'hanging' : 'middle'} style={{ fontFamily: 'muli', font: 'bold 12px sans-serif', fill: '#CFCFCF' }}>{format(Math.round((ceiling / 10) * i))}{unit}</text>
        <line stroke='rgba(0,0,0,0.3)' x1='60' x2={width} y1={graphHeight - (i * lineSpace) + 1} y2={graphHeight - (i * lineSpace) + 1} />
      </g>)
  }
  return tags
}

export default GraphLines
