import React from 'react'
import { EmbedForm } from './embeds'
import Hero from './Hero'
import Tile, { DualTile, TileGrid, TileRow } from './Tile'
import { Section, ImageSection } from './Section'
import VideoBanner from './VideoBanner'
import './content.css'
import { Link } from 'react-router-dom'

const topicList = [
  { icon: 'athletics', title: 'Athletics' },
  { icon: 'business', title: 'Business' },
  { icon: 'gov', title: 'Government', link: 'government' },
  { icon: 'healthcare', title: 'Healthcare' }
]

const impactList = [[
  { icon: 'bloodGlucose', title: 'Blood Glucose' },
  { icon: 'diabetesType2', title: 'Diabetes Type 2' },
  { icon: 'weightManage', title: 'Weight Management' },
  { icon: 'bloodPressure', title: 'Blood Pressure' },
  { icon: 'heartDisease', title: 'Heart Disease' },
  { icon: 'kidneyDisease', title: 'Kidney Disease' }
], [
  { icon: 'physicalPerformance', title: 'Physical Performance' },
  { icon: 'energy', title: 'Energy' },
  { icon: 'headaches', title: 'Headaches' },
  { icon: 'cognitionMood', title: 'Cognition & Mood' },
  { icon: 'skinComplexion', title: 'Skin Complexion' },
  { icon: 'productivity', title: 'Productivity' }
]]

const trialList = [
  { icon: 'mayoClinic', title: 'Mayo Clinic' },
  { icon: 'clevelandClinic', title: 'Cleveland Clinic' },
  { icon: 'childresHospitalofPhilly', title: 'Children’s Hospital of Philadelphia' },
  { icon: 'uniMichigan', title: 'University of Michigan' },
  { icon: 'uwMed', title: 'UW Medicine' },
  { icon: 'dukeUni', title: 'Duke University' },
  { icon: 'pennMedicine', title: 'Penn Medicine' },
  { icon: 'uniChicago', title: 'University of Chicago' },
  { icon: 'UTsouthwesternMedCenter', title: 'UT Southwestern Medical Center' },
  { icon: 'uniMinnesota', title: 'University of Minnesota' },
  { icon: 'pennStateMed', title: 'PennState College of Medicine' },
  { icon: 'indianaUni', title: 'Indiana University' },
  { icon: 'uniWashingtonSTL', title: 'Washington University in St. Louis' },
  { icon: 'uniMiami', title: 'University of Miami' },
  { icon: 'childrensHealth', title: 'Children’s Health' }
]

const Footnote = ({ num, children, ...props }) => (
  <a rel='noopener noreferrer' target='_blank' className='hstc-footnote' {...props}>{children || num} </a>
)

export const commonTiles = (
  <>
    <TileRow>
      <Tile title='75% of Americans are chronically dehydrated'>
        <img
          src='/assets/gfx-dehydrated.png'
          alt='10 human outlines, only 2 of which are filled with blue'
          style={{ width: '80%', margin: '1em auto 2em' }}
        />
      </Tile>
      <Tile title='Hydration directly impacts'>
        <div style={{ display: 'flex', marginTop: '1em' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            {impactList[0].map(item => (
              <div key={item.icon} className='hstc-impact'>
                <img src={`/assets/impact-${item.icon}.svg`} alt='' height='45' width='45' />
                {item.title}
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            {impactList[1].map(item => (
              <div key={item.icon} className='hstc-impact'>
                <img src={`/assets/impact-${item.icon}.svg`} alt='' height='45' width='45' />
                {item.title}
              </div>
            ))}
          </div>
        </div>
      </Tile>
    </TileRow>
    <TileRow>
      <Tile superTitle={<>Clinically proven <a href='https://pubmed.ncbi.nlm.nih.gov/28980082/' target='_blank' rel='noopener noreferrer'>accurate</a> &amp; <a href='https://www.auajournals.org/doi/10.1097/01.JU.0000555198.85152.f6' target='_blank' rel='noopener noreferrer'>effective</a></>} title='Hidrate Health clinical trial locations' style={{ textAlign: 'center' }}>
        <div className='hstc-trials'>
          {trialList.map(item => (
            <img key={item.icon} src={`/assets/trial-${item.icon}.svg`} alt={item.title} height='140' width='140' style={{ margin: '1em' }} />
          ))}
        </div>
        <a href='https://hidratespark.com/pages/clinical-trials' target='_blank' rel='noopener noreferrer' className='hst-btn' style={{ display: 'block', margin: '1em auto' }}>VIEW STUDIES</a>
      </Tile>
    </TileRow>
    <TileRow>
      <Tile>
        <VideoBanner full />
      </Tile>
    </TileRow>
  </>
)

const StandardLayout = ({ hero, intro, formIntro, children }) => (
  <>
    <Hero {...hero} />

    <TileGrid>
      <TileRow>
        <Tile>
          <p className='hst-fullP'>
            {intro}
          </p>
        </Tile>
      </TileRow>

      {children}

      {commonTiles}

      <TileRow>
        <Tile center title='Let’s get started'>
          <p>{formIntro}</p>
          <EmbedForm />
        </Tile>
      </TileRow>
    </TileGrid>
  </>
)

export default {
  general: (
    <>
      <Hero
        lightTheme
        superTitle='Hidrate Health for Teams'
        title='A Sip Ahead Of The Competition'
        imgDesktop='hero-overview-desktop.jpg'
        imgMobile='hero-overview-mobile.jpg'
        imgAlt=''
      />
      <TileGrid>
        <TileRow>
          <Tile center>
            <p className='hst-fullP'>
              You know your team’s potential. See what a difference proper hydration makes when you give them the tools to reach it. Hidrate Health paired with the award-winning HidrateSpark PRO smart water bottle is transforming how teams across athletics, business, healthcare, and government & defense execute at peak performance while building healthy hydration habits, improving wellness, and promoting sustainability. When you’re fully hydrated, you’re fully charged.
            </p>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              {topicList.map(topic => (
                <Link key={topic.icon} to={'/' + (topic.link || topic.icon)} className='hstc-topic'>
                  <div className='hstc-topicBtn'><img src={`/assets/topic-${topic.icon}.svg`} alt='' height='70' width='70' /></div>
                  {topic.title}
                </Link>
              ))}
            </div>
          </Tile>
        </TileRow>
        <DualTile
          img='lp-profile.png'
          alt="Tablet showing a user's hydration stats in Hidrate Health"
          title='Hidrate Health delivers unique and actionable insights'
        >
          {[
            'Analyze your team’s hydration data and generate insights that support higher performance, healthier living, and happier people.',
            <p key='A dehydration level of 1%'>
              A dehydration level of 1% of bodyweight has been correlated across multiple studies with negative effects on cognition and performance, including impairment of short term memory,
              <Footnote num='1' href='https://www.researchgate.net/publication/6239500_Neuropsychological_Performance_Postural_Stability_and_Symptoms_After_Dehydration' />
              <Footnote num='2' href='https://www.researchgate.net/publication/38013476_Voluntary_dehydration_and_cognitive_performance_in_trained_college_athletes_1_2_Percept' />
              and decreased concentration and alertness, with increasing dehydration levels further inducing fatigue, drowsiness and headaches.
              <Footnote num='3' href='https://www.researchgate.net/publication/6631856_Effects_of_Voluntary_Fluid_Intake_Deprivation_on_Mental_and_Psychomotor_Performance' />
              <Footnote num='4' href='https://www.researchgate.net/publication/8498454_The_relation_of_hydration_status_to_cognitive_performance_in_healthy_older_adults' />
              Keeping your team hydrated is a simple and effective way to ensure optimal productivity.
            </p>,
            'With the power of Hidrate Health you’ll gain valuable and actionable insights into how proper hydration habits affect your team. More than just a diagnostic, the habit forming technology leads to long term improvements in health and behavior, inspiring real change.'
          ]}
        </DualTile>
        <DualTile
          flip
          img='lp-behavior.png'
          alt=''
          title='Hidrate Health encourages behavior change'
        >
          {[
            'Our innovative smart water bottles and accompanying app are designed using several psychological models of behavior change and habit formation. They help turn proper hydration into an automatic action through glowing reminders, accountability, and gamification.',
            'In a clinical trial conducted at the Mayo Clinic, participants with HidrateSpark smart water bottles reported a 62% increase in mean urine output as a measure of water intake, and a 50% decrease in forgetfulness around drinking compared to control groups without HidrateSpark smart water bottles. HidrateSpark smart water bottles provide measurable and trusted results at a scale you can see. '
          ]}
        </DualTile>
        <DualTile
          img='lp-api.jpg'
          alt='person holding HidrateSpark bottle and phone with Hidrate app'
          title='The Hidrate Health API can be easily integrated'
        >
          Integrate Hidrate Health data into the platform that you already use to easily measure, understand, and engage your team’s hydration habits, performance, and sustainability impact. HidrateSpark adheres to rigorous privacy and compliance standards, so you can rest easy knowing your team’s data is secure.
        </DualTile>

        {commonTiles}

        <TileRow>
          <Tile center title='Let’s get started'>
            <p>Learn how Hidrate Health can give your team a competitive edge and create life lasting habits of better hydration.</p>
            <EmbedForm />
          </Tile>
        </TileRow>
      </TileGrid>
    </>
  ),

  athletics: (
    <StandardLayout
      hero={{
        superTitle: 'Hidrate Health For Athletics',
        title: 'A Sip Ahead Of The Competition',
        imgDesktop: 'hero-athletics-desktop.jpg',
        imgMobile: 'hero-athletics-mobile.jpg'
      }}
      intro='It’s not easy being the best. Help your athletes reach their highest potential and stay there. Hidrate Health paired with the award-winning HidrateSpark PRO smart water bottle is transforming how coaches encourage athletes to execute at peak performance while building healthy hydration habits, improving wellness, and promoting sustainability. When your athletes are fully hydrated, they’re fully charged.'
      formIntro='Learn how Hidrate Health can give your team a competitive edge and improve safety.'
    >
      <DualTile
        img='lp-profile.png'
        alt="Tablet showing a user's hydration stats in Hidrate Health"
        title='Improve performance and safety'
      >
        {[
          <p key='In the heat of the'>
            In the heat of the game, your players push past limits in pursuit of victory, and risk overexertion in the process. Even with water on the sidelines, athletes can lose over 2% of body mass in fluids over the course of a game, with significant impacts on physical ability, including a strong correlation with heightened fatigue.
            <Footnote num='1' href='https://www.researchgate.net/publication/47674599_Examination_of_fatigue_development_in_elite_soccer_in_a_hot_environment_A_multi-experimental_approach' />
          </p>,
          'The Hidrate Health platform gives them the tools to build better hydration habits, with a number of behavior change techniques and a series of reminders and custom goals that are clinically proven to increase mean hydration.'
        ]}
      </DualTile>
      <DualTile
        flip
        img='lp-athletics1.jpg'
        alt='athletes in a locker room looking at phones'
        title='Next level coaching tools'
      >
        Get access to data never before accessible with the ground breaking sensing technology in HidrateSpark PRO smart water bottles paired with an intuitive team dashboard. With the Hidrate Health dashboard you’ve got insight into everything happening with your team’s hydration, before, during and after the game. Using the team dashboard, coaches, trainers, and nutritional staff can make informed decisions to improve physical performance, and avoid injury among athletes.
      </DualTile>
      <DualTile
        img='lp-athletics2.jpg'
        alt='woman holding Hidrate bottle'
        title='Hydrating athletes before, during & after the game'
      >
        HidrateSpark’s software and hardware have been optimized for athletes to use before, during and after the big game. We know that hydration leading up to the game is critical for athletes to perform at their best. HidrateSpark PRO bottles are insulated, BPA free, and leak proof. We’ve also prioritized protection against dirt and germs with a unique spout cover that puts us leagues ahead of competitors in our attention to safety and water quality. With multiple options for size, color, materials, and accessories, bottles are easily customizable. We also offer branding options including team logos and athlete names.
      </DualTile>
    </StandardLayout>
  ),

  business: (
    <StandardLayout
      hero={{
        superTitle: 'Hidrate Health For Business',
        title: 'Hydrate Your Workforce',
        imgDesktop: 'hero-business-desktop.jpg',
        imgMobile: 'hero-business-mobile.jpg'
      }}
      intro='Community, efficiency, and productivity are key aspects of any successful business. Ensuring that workers are properly hydrated is essential for a wide variety of reasons including lowering corporate liability, reducing the potential for workplace accidents, decreasing company insurance costs, increasing worker productivity, and generally keeping workers happier and healthier while increasing profits.'
      formIntro='Learn how Hidrate Health can give your team a competitive edge and create life lasting habits of better hydration.'
    >
      <DualTile
        img='lp-profile.png'
        alt="Tablet showing a user's hydration stats in Hidrate Health"
        title='Improve performance & productivity'
      >
        <p>
          Keeping your team hydrated is a simple and effective way to ensure optimal productivity. A dehydration level of 1% of bodyweight has been correlated across multiple studies with negative effects on cognition and performance, including impairment of short term memory,
          <Footnote num='1' href='https://www.researchgate.net/publication/6239500_Neuropsychological_Performance_Postural_Stability_and_Symptoms_After_Dehydration' />
          <Footnote num='2' href='https://www.researchgate.net/publication/38013476_Voluntary_dehydration_and_cognitive_performance_in_trained_college_athletes_1_2_Percept' />
          and decreased concentration and alertness, with increasing dehydration levels further inducing fatigue, drowsiness and headaches.
          <Footnote num='3' href='https://www.researchgate.net/publication/6631856_Effects_of_Voluntary_Fluid_Intake_Deprivation_on_Mental_and_Psychomotor_Performance' />
          <Footnote num='4' href='https://www.researchgate.net/publication/8498454_The_relation_of_hydration_status_to_cognitive_performance_in_healthy_older_adults' />
          Hidrate Health allows you to analyze your team’s hydration data and generate insights that support higher performance, healthier living, and happier people. You can also create “group challenges” to help foster a workplace community through friendly competition.
        </p>
      </DualTile>
      <DualTile
        flip
        img='lp-business1.jpg'
        alt='construction workers holding a laptop'
        title='Prioritize worker safety'
      >
        {[
          'Whether they’re out under the sun or hard at work behind a desk, chances are your people are not drinking enough water while they are on the job. The importance of the relationship between water intake and both employee safety and performance has been widely recognized by the scientific community and the Occupational Safety and Health Administration. In fact, OSHA has developed a sheet of protocols and risk management techniques associated with the prevention and treatment of heat and dehydration related illness.',
          'Reduce the risk of dehydration or injury by understanding their hydration habits and how it affects performance with data never before accessible The ground breaking sensing technology in HidrateSpark PRO smart water bottles pairs with an intuitive dashboard, giving you insight into everything. See each team member’s fluid intake, before, during and after peak hours so you can make informed decisions to improve physical performance, and avoid injury amongst your employees.'
        ]}
      </DualTile>
      <DualTile
        img='lp-business2.jpg'
        alt='person holding HidrateSpark bottle'
        title='Promote sustainability & healthy living'
      >
        Save the planet while encouraging employees to live healthier. With the power of Hidrate Health you’ll gain valuable and actionable insights into how proper hydration habits affect your team and how big your sustainability impact is, reducing single use plastics by replacing traditional single use water bottles. More than just a diagnostic, the habit forming technology leads to long term improvements in health and behavior, inspiring real change and saving the planet from unnecessary plastic waste. Reusable bottles are here to stay, and with multiple options for size, color, materials, and accessories, HidrateSpark bottles are easily customizable with company logos and individual’s names.
      </DualTile>
    </StandardLayout>
  ),

  healthcare: (
    <StandardLayout
      hero={{
        superTitle: 'Hidrate Health For Healthcare',
        title: 'Improve Patient Outcomes With Hydration',
        imgDesktop: 'hero-healthcare-desktop.jpg',
        imgMobile: 'hero-healthcare-mobile.jpg'
      }}
      intro='Proper daily hydration is one of the simplest, most effective, and least obtrusive practices a person can do in order to maintain their health, yet it is shockingly easy to fall into bad habits that worsen serious conditions. Hidrate Health uses scientifically proven psychological principles to promote healthy fluid intake that will not only improve education surrounding hydration, but actively builds proper hydration habits over time.'
      formIntro='Learn how Hidrate Health can improve patient outcomes with hydration and empower individuals to take ownership of their health.'
    >
      <DualTile
        img='lp-profile.png'
        alt="Tablet showing a user's hydration stats in Hidrate Health"
        title='Improve patient outcomes with lasting hydration habits'
      >
        {[
          'Our innovative smart water bottles and accompanying app are designed using several psychological models of behavior change and habit formation. They help turn proper hydration into an automatic action through glowing reminders, accountability, and gamification.',
          'In a clinical trial conducted at the Mayo Clinic for kidney stone patients, participants with HidrateSpark smart water bottles reported a 62% increase in mean urine output as a measure of water intake, and a 50% decrease in forgetfulness around drinking compared to control groups without HidrateSpark smart water bottles. HidrateSpark smart water bottles provide measurable and trusted results at a scale you can see.'
        ]}
      </DualTile>
      <DualTile
        flip
        img='lp-healthcare1.jpg'
        alt='doctor reviewing papers with patient'
        title='Reduce costs for payers and patients'
      >
        Payers recognize that unmanaged and undermanaged chronic conditions can cause health care costs to quickly spiral upward. This is why the top insurance companies have specialized programs to help manage, and even prevent, chronic conditions with the help of remote tracking technologies. Hydration has a direct impact on the most costly conditions like Diabetes, Coronary Artery Disease, Depression & Anxiety, Obesity, Chronic Kidney Disease and more. By including the Hidrate Health platform alongside their chronic disease management programs as a preventative tool, payers can ensure maintenance of proper hydration and prevent chronic problems from worsening.
      </DualTile>
      <DualTile
        img='lp-healthcare2.jpg'
        alt='woman playing board game with older man'
        title='Empower the aging population'
      >
        {[
          <p key='Older adults are at an'>
            Older adults are at an increased risk for dehydration. As you age, your body’s fluid reserve becomes smaller, your ability to conserve water is reduced, and your thirst sense becomes less acute. These problems are compounded by chronic illnesses such as diabetes and dementia, and by the use of certain medications. Older adults also may have mobility problems that limit their ability to obtain water for themselves.
            <Footnote num='1' href='https://www.mayoclinic.org/diseases-conditions/dehydration/symptoms-causes/syc-20354086' />
          </p>,
          'Hidrate Health can help family members and care providers remotely monitor aging individuals’ hydration in real time, ease minds or provide alerts if not enough fluid intake is occurring. The bottles are simple to use and easy to set up for members of the aging population.'
        ]}
      </DualTile>
    </StandardLayout>
  ),

  gov: (
    <StandardLayout
      hero={{
        superTitle: 'Hidrate Health For Government',
        title: 'Keep Our Heroes Hydrated & Safe',
        imgDesktop: 'hero-gov-desktop.jpg',
        imgMobile: 'hero-gov-mobile.jpg'
      }}
      intro='Fuel up, and prepare your people for the toughest missions around the globe. No matter the conditions, no matter the operation, and no matter the stakes, your personnel are ready to tackle any challenge. Proper hydration means your people execute at maximum physical and mental potential, ensuring mission integrity and empowering those serving in any capacity.'
      formIntro='Learn how Hidrate Health can reduce the risk of dehydration or injury for your personnel.'
    >
      <DualTile
        img='lp-profile.png'
        alt="Tablet showing a user's hydration stats in Hidrate Health"
        title='Ensure optimal performance'
      >
        Fatigue and inattention can be deadly; you know that better than most. When your personnel are dehydrated they’re at risk of lost focus, sluggish reflexes and foggy thinking. The risks are high, and the stakes are higher. Help your team build healthy hydration habits that last, and monitor for dangerous trends so you can intervene before it’s too late. Whether it’s fighting fires or protecting the vulnerable, your people want the tools to ensure optimal performance.
      </DualTile>
      <DualTile
        flip
        img='lp-gov1.jpg'
        alt='soldiers saluting'
        title='Prioritize personnel safety'
      >
        Whether they’re out under the sun or hard at work behind a desk, chances are your personnel aren’t drinking enough water while they are on the job. Reduce the risk of dehydration or injury by understanding their hydration habits and how it affects performance with data never before accessible. The groundbreaking sensing technology in HidrateSpark PRO smart water bottles pairs with an intuitive dashboard, giving you insight into everything. With the Hidrate Health dashboard you’ve got insight into everything happening with your team’s hydration, before, during and after missions. Using the team dashboard, leaders can make informed decisions to improve physical performance, and avoid injury among personnel.
      </DualTile>
      <DualTile
        img='lp-gov2.jpg'
        alt='first responder holding child'
        title='Hydrating heroes before, during & after missions'
      >
        HidrateSpark’s software and hardware have been optimized for government & defense personnel to use before, during and after missions. We know that hydration leading up to major physical exertion is critical in ensuring individuals perform at their best. HidrateSpark PRO bottles are insulated, BPA free, and leak proof. We’ve also prioritized protection against dirt and germs with a unique spout cover that puts us leagues ahead of competitors in our attention to safety and water quality. With multiple options for size, color, materials, and accessories, bottles are easily customizable. We also offer branding options including logos and individual’s names.
      </DualTile>
    </StandardLayout>
  ),

  howitworks: (
    <>
      <Hero
        lightTheme
        altLayout
        superTitle='Hidrate Health for Teams'
        title='A Sip Ahead Of The Competition'
        imgDesktop='heroalt-howitworks.jpg'
        imgMobile='hero-general-m.jpg'
        imgAlt=''
      />
      <TileGrid>

        <DualTile
          img='howitworks.png'
          title='Hidrate Health delivers unique and actionable insights'
        >
          <p>
            <h6>1. Distribute HidrateSpark smart water bottles to your participants</h6>
            HidrateSpark smart water bottles automatically collect fluid intake data and encourage users to form healthy hydration habits.
          </p>
          <p>
            <h6>2. Form a Hidrate Health Group</h6>
            Invite participants to opt in.
          </p>
          <p>
            <h6>3. Monitor and analyze hydration data from your group</h6>
            Avoid injury and dehydration by monitoring individuals’ fluid intake down to the ml, and find correlations between hydration and health outcomes.
          </p>
          <p>
            <h6>4. Customize engagement with participants</h6>
            Create fun challenges to motivate them, and if participants show signs of poor hydration habits custom guidance messages can be automatically sent to the individuals HidrateSpark app.
          </p>
        </DualTile>

        <DualTile
          flip
          img='lp-profile.png'
          title='Easy to use features to keep your group hydrated'
        >
          <p>
            <h6>Home Dashboard</h6>
            A quick overview of your team’s progress, challenge status, high level analytics, and sustainability impact.
          </p>
          <p>
            <h6>Live Hydration Data</h6>
            Review live hydration data for individuals down to the ml and time consumed, along with historical progress toward reaching goals.
          </p>
          <p>
            <h6>Send & Schedule Custom Notifications</h6>
            Create your own custom notifications and schedule them to go out to the entire group, sub groups, or specific individuals.
          </p>
          <p>
            <h6>Create Engaging Challenges</h6>
            Encourage fun hydration with challenges for your team to keep them engaged and motivated.
          </p>
        </DualTile>

        <TileRow>
          <Tile center title='Let’s get started'>
            <p>Learn how Hidrate Health can give your team a competitive edge and create life lasting habits of better hydration.</p>
            <EmbedForm />
          </Tile>
        </TileRow>
      </TileGrid>
    </>
  ),
  challenges: (
    <>
      <Hero
        superTitle='Challenges'
        title='Compete, Achieve, and Engage in Healthy Competition'
        imgDesktop='hero-challenges.jpg'
        imgMobile='hero-challenges-m.jpg'
        imgAlt=''
      />
      <Section
        imgLeft
        image='assets/challenges-info.png'
        text={
          <>
            <h3>Create fun challenges to jump start your team&apos;s health</h3>
            <h6>TEAM BATTLE</h6>
            <p>Teams compete in a time-based challenge to see who comes out on top.</p>
            <h6>CO-OP CHALLENGE</h6>
            <p>Bring your team together and see how many single use plastic bottles you can save.</p>
          </>
}
      />
      <Section
        halfWidth
        image='assets/challenges-trophies.png'
        text={
          <>
            <h3>Reward each victory with challenge trophies</h3>
            <p>Whether you&apos;re apart of the winning team, had a personal flawless water goal streak, or participated for the love of the game, our trophies keep your teams engaged in the fun.</p>
          </>
        }
      />
      <Section
        imgLeft
        halfWidth
        image='assets/challenges-notification.png'
        mblImage='assets/challenges-notification-mbl.png'
        text={
          <>
            <h3>Keep the competitive spirit alive with challenge notifications</h3>
            <p>Engage with your teams through custom notifications. Send a helpful reminder to the entire challenge participants or send encouraging messages to individual teams.</p>
          </>
        }
      />
      <ImageSection
        title='View it all from your Hidrate Health Dashboard'
        image='assets/challenges-big-splash.png'
        noPaddingBottom
      />

      <TileGrid>
        <TileRow>
          <Tile center title='Let’s get started'>
            <p>Learn how Hidrate Health can give your team a competitive edge and create life lasting habits of better hydration.</p>
            <EmbedForm />
          </Tile>
        </TileRow>
      </TileGrid>
    </>
  )
}
