import React from 'react'
import PropTypes from 'prop-types'
import SipHistory from './SipHistory'

import HidrateCard from '../HidrateCard'
import DayProgressChart from './DayProgressChart'

const DayStats = ({ day, units, newSipsState }) => {
  return (
    <HidrateCard>
      <div className='flex items-center justify-between h-80'>
        <div className='flex-1 min-w-0 h-full'>
          <DayProgressChart
            viewingDate={day}
            sips={newSipsState.sips}
            units={units}
          />
        </div>
        <div className='hidden md:block h-full min-w-56 flex-none overflow-y-auto p-2.5 border border-gray-300 rounded-xl'>
          <SipHistory sips={newSipsState.sips} units={units} />
        </div>
      </div>
    </HidrateCard>
  )
}

DayStats.propTypes = {
  goal: PropTypes.number,
  userId: PropTypes.string.isRequired,
  day: PropTypes.instanceOf(Date).isRequired,
  units: PropTypes.any,
  newSipsState: PropTypes.object.isRequired,
  createdAtDate: PropTypes.instanceOf(Date).isRequired
}

export default DayStats
