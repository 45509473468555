import React from 'react'
import PropTypes from 'prop-types'
import { HydrationProgress } from './HydrationProgress'
import { HydrationFactor } from './HydrationFactor'
import DatePicker from 'react-datepicker'
import { format, isSameDay } from 'date-fns'
import HidrateCard from '../HidrateCard'

import 'react-datepicker/dist/react-datepicker.css'

const HydrationScore = ({ day, setDay, hydrationScore, contributors, dateLoading }) => {
  const today = new Date()

  const dailyGoal = Math.floor((contributors.daily ?? 0) * 100)
  const hourlyGoal = Math.floor((contributors.hourly ?? 0) * 100)
  const beverageHydration = Math.floor((contributors.beverage ?? 0) * 100)

  if (dateLoading) {
    return (
      <HidrateCard>
        <div className='flex items-center justify-start'>
          <DatePicker
            disabled={dateLoading}
            className='input input-bordered hover:cursor-pointer'
            selected={day}
            value={isSameDay(day, today) ? 'Today' : format(day, 'MMM dd, yyyy')}
            onChange={(date) => setDay(date)}
            maxDate={today}
            showPopperArrow={false}
          />
          <span className='loading loading-spinner loading-sm ml-5' />
        </div>
        <div className='my-5 h-[250px] bg-gray-300 animate-pulse rounded-xl' />
      </HidrateCard>
    )
  }

  return (
    <HidrateCard>
      <div className='grid grid-cols-1 lg:grid-cols-2'>

        <div>
          <div className='flex items-center justify-start'>
            <DatePicker
              disabled={dateLoading}
              className='input input-bordered hover:cursor-pointer'
              selected={day}
              value={isSameDay(day, today) ? 'Today' : format(day, 'MMM dd, yyyy')}
              onChange={(date) => setDay(date)}
              maxDate={today}
              showPopperArrow={false}
            />
          </div>
          <HydrationProgress
            hydrationScore={hydrationScore}
            viewingDate={day}
          />
        </div>

        <div className='hidden lg:flex flex-col items-center justify-center max-w-lg'>
          <HydrationFactor progress={dailyGoal} index={0} factor='Daily Goal' />
          <HydrationFactor progress={hourlyGoal} index={1} factor='Hourly Goals' />
          <HydrationFactor progress={beverageHydration} index={2} factor='Beverage Hydration' />
        </div>

      </div>
    </HidrateCard>
  )
}

HydrationScore.propTypes = {
  contributors: PropTypes.object.isRequired,
  day: PropTypes.instanceOf(Date).isRequired,
  setDay: PropTypes.func.isRequired,
  hydrationScore: PropTypes.any.isRequired
}

export default HydrationScore
