import React from 'react'

import DatePickerCustomInput from '../DatePickerCustomInput'
import { addDays, endOfDay, format } from 'date-fns'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const EditStep = ({ notification, modifyNotification }) => {
  const today = new Date()
  const defaultStartDate = addDays(today, 2)
  const defaultEndDate = endOfDay(addDays(today, 30))

  const startDate = notification?.startDate ?? defaultStartDate
  const endDate = notification?.endDate ?? defaultEndDate

  return (
    <>
      <div className='grid grid-cols-2 my-5'>
        <label className='label cursor-pointer space-x-5 justify-start'>
          <input
            type='radio'
            name='One time'
            className='radio radio-primary'
            checked={!notification.repeat}
            onChange={() => modifyNotification({ repeat: false })}
          />
          <div>
            <span className='text-xl'>One time</span>
            <p className='text-sm'>Choose a date and time to send once.</p>
          </div>
        </label>
        <label className='label cursor-pointer space-x-5 justify-start'>
          <input
            type='radio'
            name='Repeating'
            className='radio radio-primary'
            checked={notification.repeat}
            onChange={() => modifyNotification({ repeat: true, endDate })}
          />
          <div>
            <span className='text-xl'>Repeating</span>
            <p className='text-sm'>Set a recurring weekly schedule.</p>
          </div>
        </label>
      </div>
      {!notification.repeat
        ? (
          <div className='flex items-center justify-start max-w-xl space-x-5'>
            <div>
              <p className='mb-1'>Date</p>
              <HidrateDatePicker
                date={startDate}
                setDate={date => modifyNotification({ startDate: date })}
              />
            </div>
            <div>
              <p className='mb-1'>Time</p>
              <HidrateTimePicker
                time={startDate}
                setTime={time => modifyNotification({ startDate: time })}
              />
            </div>
          </div>)
        : (
          <div className='flex items-end justify-start space-x-5'>
            <div>
              <p className='mb-1'>Start on</p>
              <HidrateDatePicker
                date={startDate}
                setDate={(date) => modifyNotification({ startDate: date })}
              />
            </div>
            <div>
              <p className='mb-1'>End on</p>
              <HidrateDatePicker
                date={endDate}
                setDate={(date) => {
                  const endOfDayDate = endOfDay(date)
                  modifyNotification({ endDate: endOfDayDate })
                }}
              />

            </div>
            <div>
              <p className='mb-1'>Repeat</p>
              <div className='flex items-end justify-center space-x-2.5'>
                <div>
                  <select
                    value={notification.repeatInterval || 7}
                    onChange={event => modifyNotification({ repeatInterval: event.target.value })}
                    className='select select-bordered w-full'
                  >
                    <option value='7'>Every {format(startDate, 'EEEE')}</option>
                    <option value='14'>Every other {format(startDate, 'EEEE')}</option>
                  </select>
                </div>
                <div>
                  <HidrateTimePicker
                    time={notification.startDate}
                    setTime={time => modifyNotification({ startDate: time })}
                  />
                </div>
              </div>
            </div>
          </div>)}
      {(notification.reviewRequired && !notification.savedText && notification.startDate) && (
        <p>New messages may take up to 48 hours to be approved.</p>
      )}
      {/* {notification.reviewRequired &&
      !notification.savedText &&
      notification.startDate &&
      moment(notification.startDate).isBefore(moment().add(2, 'days').subtract(1, 'hour')) &&
        <p className={classes.warning}>New messages may take up to 48 hours to be approved.</p>} */}
    </>
  )
}

const HidrateTimePicker = ({ time, setTime }) => {
  return (
    <DatePicker
      customInput={<DatePickerCustomInput />}
      wrapperClassName='w-full'
      selected={time}
      value={format(time, 'h:mm a')}
      onChange={value => setTime(value)}
      showTimeSelect
      minDate={new Date()}
      showTimeSelectOnly
      showPopperArrow={false}
      timeIntervals={30}
      popperModifiers={[
        {
          name: 'preventOverflow',
          options: {
            rootBoundary: 'viewport',
            tether: false,
            altAxis: true
          }
        }
      ]}
    />
  )
}

const HidrateDatePicker = ({ date, setDate }) => {
  return (
    <DatePicker
      customInput={<DatePickerCustomInput />}
      wrapperClassName='w-full'
      selected={date}
      value={format(date, 'MMMM d, yyyy')}
      onChange={(date) => setDate(date)}
      minDate={new Date()}
      showPopperArrow={false}
      showMonthDropdown
      showYearDropdown
      popperModifiers={[{
        name: 'preventOverflow',
        options: {
          rootBoundary: 'viewport',
          tether: false,
          altAxis: true
        }
      }]}
    />
  )
}

export default EditStep
