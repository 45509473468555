import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import AppWrapper from './AppWrapper'
import * as serviceWorker from './serviceWorker'
import ReactGA from 'react-ga4'

const devEnvironment = process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production'

if (devEnvironment) {
  ReactGA.initialize('G-V58G0MMFR6', { })
} else {
  ReactGA.initialize('G-7ELJGES1T2', { })
}

ReactDOM.render(<AppWrapper />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
