import React, { useState, useRef, useMemo } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import EditIcon from '@mui/icons-material/Edit'
import FileBase64 from 'react-file-base64'
import { adService } from '../../services/advertisementService'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { styled } from '@mui/material/styles'

import {
  FormLabel,
  Typography,
  TextField,
  FormControl,
  NativeSelect
} from '@mui/material'
import PropTypes from 'prop-types'
import moment from 'moment'

const MainColumn = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '100%'
}))

const Error = styled('span')(() => ({
  color: 'red',
  fontWeight: 'bold'
}))

const Dash = styled('div')(() => ({
  fontSize: '0.8em',
  textTransform: 'uppercase',
  textAlign: 'center',
  fontWeight: 'bold',
  color: '#888',
  margin: '0 2em'
}))

const ContentRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center'
}))

const AdImage = styled('img')(() => ({
  maxWidth: '100%',
  maxHeight: '50vh',
  objectFit: 'contain'
}))

// const Images = props => {
const Images = ({ images, removeImage }) => {
  return images.map((image, i) =>
    <div key={i} className='fadein'>
      <div>
        <IconButton
          aria-label='close'
          onClick={() => removeImage(image.public_id)}
          size='large'
        >
          <CloseIcon />
        </IconButton>
      </div>
      <AdImage src={URL.createObjectURL(image.file)} alt='' />
      <div>{image.name}</div>
    </div>
  )
}

const Buttons = props => {
  return (
    <div>
      <FileBase64 multiple={false} onDone={props.onChange} />
    </div>
  )
}

Buttons.propTypes = {
  onChange: PropTypes.func.isRequired
}

const DialogTitle = ({ children, onClose }) => {
  return (
    <MuiDialogTitle
      disableTypography
      sx={{
        width: '90%',
        maxWidth: '800px',
        margin: '10vh auto',
        height: 'auto'
      }}
    >
      <Typography variant='h6'>{children}</Typography>
      {onClose
        ? (
          <IconButton
            aria-label='close'
            sx={{
              position: 'absolute',
              right: 1,
              top: 1,
              color: 'grey[500]'
            }}
            onClick={onClose}
            size='large'
          >
            <CloseIcon />
          </IconButton>
          )
        : null}
    </MuiDialogTitle>
  )
}

DialogTitle.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.string
}

const UploadDialog = ({ adLocation, refresh, edit, groupId }) => {
  const [open, setOpen] = useState(false)
  const editState = useMemo(() => {
    if (!edit) {
      return {}
    }
    const out = {
      loaded: true,
      accssibilityText: edit.accessibilityText,
      editStartDate: moment(edit.startDate).toDate(),
      editEndDate: moment(edit.stopDate).toDate()
    }
    if (edit.destinationUrl) {
      const matched = edit.destinationUrl.match(/http.?:\/\/([\w.]+?)\//)
      if (matched && matched[1]) out.host = matched[1]
    }
    return out
  }, [edit])
  const initialState = {
    images: [],
    homeImages: [],
    host: 'hidratespark.com',
    utm_campaign: 'generic',
    accessibilityText: '',
    displayWeight: '0',
    hostedBannerImageUrl: 'https://hidrate-images.s3.amazonaws.com/ads/lightning.png',
    ...editState
  }

  const [state, setState] = useState(initialState)
  const [editSetup, setEditSetup] = useState(edit ? 'pending' : 'none')
  if (initialState.loaded && editSetup === 'pending') {
    setEditSetup('done')
    setState(initialState)
  }

  const [startDate, setStartDate] = React.useState(editState.editStartDate || new Date())
  const [endDate, setEndDate] = React.useState(editState.editEndDate || new Date())
  const locationString = adLocation === 'bottles' ? 'bottles_page' : 'homepage'
  const urlString = `https://${state.host}/?utm_source=${locationString}&utm_medium=hidrate_app&utm_campaign=${state.utm_campaign}${state.utm_content ? ('&utm_content=' + state.utm_content) : ''}`

  const readyRef = useRef(null)
  const allowedImageTypes = ['image/png', 'image/jpg', 'image/jpeg']

  const [error, setError] = useState(null)

  const setReady = () => {
    return state.accessibilityText && startDate < endDate && (state.file && allowedImageTypes.includes(state.file.type))
  }

  readyRef.current = setReady()

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleStartDateChange = date => {
    setStartDate(date)
  }

  const handleEndDateChange = date => {
    setEndDate(date)
  }

  const handleChange = event => {
    console.log(event.target.value, event.target.id)
    const localState = { ...state }
    localState[event.target.id] = event.target.value
    setState(localState)
    console.log(localState[event.target.id])
    readyRef.current = setReady()
  }

  const handleSubmit = () => {
    if (edit) {
      console.log('editing ad')

      const adData = {
        objectId: edit.objectId,
        groupId,
        destinationUrl: urlString,
        startDate,
        stopDate: endDate,
        accessibilityText: state.accessibilityText,
        adLocation,
        displayWeight: parseFloat(state.displayWeight) || 0,
        imagePayload: {
          name: state.file.name,
          encoding: state.file.type,
          imageString: state.file.base64
        }
      }
      if (state.hostedBannerImageUrl) {
        adData.hostedBannerImageUrl = state.hostedBannerImageUrl
      } else {
        adData.homeImagePayload = {
          name: state.homeFile.name,
          encoding: state.homeFile.type,
          imageString: state.homeFile.base64
        }
      }

      return adService.editAdvertisement(adData)
        .then((response) => {
          console.log('success!!!')
          console.log(response)
          refresh()
          setOpen(false)
        })
        .catch((error) => {
          console.log('failed')
          console.log(error)
        })
    } else {
      console.log('creating ad')

      const adData = {
        groupId,
        destinationUrl: urlString,
        startDate,
        stopDate: endDate,
        accessibilityText: state.accessibilityText,
        adLocation,
        displayWeight: parseFloat(state.displayWeight) || 0,
        imagePayload: {
          name: state.file.name,
          encoding: state.file.type,
          imageString: state.file.base64
        }
      }
      if (state.hostedBannerImageUrl) {
        adData.hostedBannerImageUrl = state.hostedBannerImageUrl
      } else {
        adData.bannerImage = {
          name: state.homeFile.name,
          encoding: state.homeFile.type,
          imageString: state.homeFile.base64
        }
      }

      return adService.createNewAdvertisement(adData)
        .then((response) => {
          console.log('success!!!')
          console.log(response)
          refresh()
          setOpen(false)
        })
        .catch((err) => {
          console.log('failed')
          console.log(err)
          if (err.response) {
            setError(err.response.data.message || 'Failed to save ad: internal server error.')
          } else {
            setError('Failed to save ad: no response received.')
          }
        })
    }
  }

  const removeImage = id => {
    setState({
      ...state,
      images: state.images.filter(image => image.public_id !== id)
    })
  }
  const { images, homeImages } = state

  const mainImagePicker = () => (
    images.length > 0
      ? <Images images={images} removeImage={removeImage} />
      : <Buttons onChange={e => setState({ ...state, file: e, images: [e] })} />
  )

  const homeImagePicker = () => (
    homeImages.length > 0
      ? (
        <Images images={homeImages} removeImage={() => setState({ ...state, images: [] })} />)
      : (
        <>
          <NativeSelect id='hostedBannerImageUrl' value={state.hostedBannerImageUrl} onChange={handleChange}>
            <option value='https://hidrate-images.s3.amazonaws.com/ads/lightning.png'>Lightning</option>
            <option value='https://hidrate-images.s3.amazonaws.com/ads/new.png'>New</option>
            <option value='https://hidrate-images.s3.amazonaws.com/ads/SALE.png'>Sale</option>
            <option value={false}>Custom</option>
          </NativeSelect>
          {!state.hostedBannerImageUrl && <Buttons onChange={e => setState({ ...state, homeFile: e, homeImages: [e] })} />}
        </>
        )
  )

  return (
    <div>
      <IconButton
        aria-label='close'
        onClick={handleClickOpen}
        sx={{ color: 'white' }}
        size='large'
      >
        {edit ? <EditIcon /> : <AddCircleIcon />}
      </IconButton>
      <Dialog
        fullScreen onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} sx={{
          width: '90%',
          maxWidth: '800px',
          margin: '10vh auto',
          height: 'auto'
        }}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          {edit ? 'Edit Ad' : 'Create New Ad'}
        </DialogTitle>
        <MuiDialogContent
          dividers sx={{
            padding: 1
          }}
        >
          <MainColumn>
            <Typography variant='h4'>Main Image</Typography>
            {mainImagePicker()}
            {adLocation === 'home' &&
              <>
                <Typography variant='h4'>Home Image</Typography>
                {homeImagePicker()}
              </>}
            <ContentRow>
              <TextField
                sx={{
                  width: '100%',
                  margin: '1em'
                }}
                id='host'
                label='Host'
                value={state.host}
                onChange={handleChange}
                variant='filled'
              />
              <TextField
                sx={{
                  width: '100%',
                  margin: '1em'
                }}
                id='utm_campaign'
                label='Campaign'
                value={state.utm_campaign}
                onChange={handleChange}
                variant='filled'
              />
              <TextField
                sx={{
                  width: '100%',
                  margin: '1em'
                }}
                id='utm_content'
                label='Content'
                value={state.utm_content}
                onChange={handleChange}
                variant='filled'
              />
            </ContentRow>
            <Typography variant='caption'>{urlString}</Typography>
            <TextField
              sx={{
                width: '100%',
                margin: '1em'
              }}
              id='accessibilityText'
              label='Accessibility Text'
              value={state.accessibilityText}
              onChange={handleChange}
              variant='filled'
            />
            <TextField
              sx={{
                width: '100%',
                margin: '1em'
              }}
              id='displayWeight'
              label='Display Weight'
              value={state.displayWeight}
              onChange={handleChange}
              variant='filled'
            />

            <ContentRow>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl>
                  <FormLabel>Start Date</FormLabel>
                  <DatePicker
                    margin='normal'
                    id='start-date-picker'
                    format='MM/dd/yyyy'
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                  <TimePicker
                    margin='normal'
                    id='start-time-picker'
                    format='h:mm'
                    value={startDate}
                    renderInput={() => <TextField label='Time' helperText='Something' />}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                </FormControl>
                <Dash>
                  through
                </Dash>
                <FormControl>
                  <FormLabel>End Date</FormLabel>
                  <DatePicker
                    margin='normal'
                    id='end-date-picker'
                    format='MM/dd/yyyy'
                    value={endDate}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                  <TimePicker
                    margin='normal'
                    id='end-time-picker'
                    format='h:mm'
                    value={endDate}
                    renderInput={() => <TextField label='Time' helperText='Something' />}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                </FormControl>
              </LocalizationProvider>
            </ContentRow>

          </MainColumn>

        </MuiDialogContent>
        <MuiDialogActions sx={{
          margin: 0,
          padding: 1
        }}
        >
          {error && <Error>{error}</Error>}
          <Button disabled={!readyRef.current && false} autoFocus onClick={handleSubmit} color='primary'>
            Submit
          </Button>
        </MuiDialogActions>
      </Dialog>
    </div>
  )
}

UploadDialog.propTypes = {
  adLocation: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
  edit: PropTypes.any,
  groupId: PropTypes.string
}

export default UploadDialog
