import React from 'react'

const LightTypeSelect = ({ userParams, setUserParams }) => {
  const lightTypeOptions = [
    { value: 44, type: 'Low' },
    { value: 48, type: 'Medium' },
    { value: 52, type: 'High' }
  ]

  return (
    <div>
      <label className='label'>
        <span className='label-text'>Glow type</span>
      </label>
      <select
        value={userParams.lightType}
        onChange={(e) => setUserParams((prev) => ({ ...prev, lightType: e.target.value }))}
        className='select select-bordered w-full'
      >
        {lightTypeOptions.map((opt) => (
          <option key={opt.value} value={opt.value}>{opt.type}</option>))}
      </select>
    </div>
  )
}

export default LightTypeSelect
