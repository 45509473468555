import React, { useEffect, useState } from 'react'
import HidrateCard from '../HidrateCard'
import { searchService } from '../../services/searchService'
import image from '../../resources/hidrate-logo.svg'

const AllTrophiesCard = ({ groupId, userId }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const response = await searchService.getTrophies(groupId, userId)
      if (response.message) {
        console.log(response)
      } else {
        setData(response.awards.sort((a, b) => a.weight - b.weight))
      }
    }
    fetchData()
  }, [groupId, userId])

  if (!data || data?.length < 1) { return (<></>) }

  return (
    <HidrateCard>
      <div className='flex flex-wrap'>
        {data?.map((trophy) => (
          <div key={trophy.trophyId} className='h-[100px] w-[100px] relative flex items-center justify-center'>
            <svg viewBox='0 0 60 60' className='-rotate-90'>
              <circle
                strokeWidth='3'
                strokeLinecap='round'
                stroke='#1fadff'
                strokeDasharray='158 158' // circumference with r=25
                strokeDashoffset={157 - trophy.progress * 157}
                fill='transparent'
                r='25'
                cx='30'
                cy='30'
              />
            </svg>
            <div className='absolute'>
              <img className='h-[65px] w-[65px] rounded-full' src={trophy.image ?? image} alt={trophy.icon} />
            </div>
          </div>
        ))}
      </div>
    </HidrateCard>
  )
}

export default AllTrophiesCard
