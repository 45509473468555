import axios from 'axios'
const config = { apiUrl: process.env.REACT_APP_API_URL }
const authHeader = require('./authHeader')

export const updateUserParams = async ({ userId, userParams, dateKey }) => {
  const options = { headers: authHeader.authHeader() }
  const params = { ...userParams, memberId: userId }

  const res = await axios.post(`${config.apiUrl}member/${userId}/params/${dateKey}`, params, options)
  return res.data
}
