// import { usePath } from 'hookrouter'
import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

let groupCache = JSON.parse(window.localStorage.getItem('subgroups') || '{}')
export function updateGroupCache (newSubgroups) {
  // console.log('updating group cache', newSubgroups)
  groupCache = {
    ...groupCache,
    ...newSubgroups,
    update: (groupCache.update || 0) + 1
  }
  window.localStorage.setItem('subgroups', JSON.stringify(groupCache))
}

export function getCached (groupId) {
  return groupCache[groupId] || { id: groupId }
}

function hasParent (group) {
  return group.parent && (group.parent !== group.id)
}

export function getSubgroups (groupId) {
  const out = []
  Object.values(groupCache).forEach(group => {
    if (group.parent === groupId && group.id !== groupId) {
      out.push(group)
    }
  })
  // console.log('getting subgroups', groupCache, out)
  return out
}

export function getAllSubgroups (...groupIds) {
  const out = []
  const selected = [...groupIds]
  const queue = [...groupIds]
  let counter = 0
  while (queue.length > 0 && counter < 100) {
    counter++
    const check = queue.shift()
    const results = Object.values(groupCache).filter(group => group.parent === check && !selected.includes(group.id))
    out.push(...results)
    const ids = results.map(g => g.id)
    selected.push(...ids)
    queue.push(...ids)
  }
  return out
}

/*
{
  group: { id: 'abc5', name: 'Tiny Subgroup', parent: 'abc4' },
  parent: { id: 'abc5', name: 'Tiny Subgroup', parent: 'abc4' },
  root: { id: 'abc5', name: 'Tiny Subgroup', parent: 'abc4' }
}

all three objects will exist, may equal `group`
*/

export function useGroup () {
  const location = useLocation()
  const path = location.pathname

  const groupInfo = useMemo(() => {
    const currentId = path.match(/dashboard\/(\w+)\//)[1]
    const out = {
      group: getCached(currentId)
    }

    if (hasParent(out.group)) {
      out.parent = getCached(out.group.parent)
      out.hasParent = true

      if (hasParent(out.parent)) {
        let level = getCached(out.parent.parent)
        while (hasParent(level)) {
          level = getCached(level.parent)
        }
        out.root = level
      } else {
        out.root = out.parent
      }
    } else {
      out.parent = out.group
      out.root = out.group
    }
    return out
  }, [path, groupCache, groupCache.update])

  return {
    path,
    ...groupInfo,
    id: groupInfo.group.id
  }
}
