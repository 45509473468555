import React, { useState, useEffect } from 'react'
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField } from '@mui/material'
import { useParams } from 'react-router-dom'

const CreateGroup = () => {
  const [openModal, setOpenModal] = useState(false)
  const [newGroupName, setNewGroupName] = useState('')

  const [newJoinCode, setNewJoinCode] = useState('')
  const [joinCodeError, setJoinCodeError] = useState(false)
  const [joinCodeHelperText, setJoinCodeHelperText] = useState('Enter valid join code')

  const { groupId } = useParams()

  const isCodeValid = str => {
    if (!str) {
      setJoinCodeError(true)
    } else if (str.length < 5) {
      setJoinCodeError(true)
    } else {
      setJoinCodeError(false)
    }

    setNewJoinCode(str)

    // if (str.length < 5) return { error: 'Join code must be at least 5 characters.' }
    // if (str.length > 20) return { error: 'Join code must be no more than 20 characters.' }
    // if (str.match(/[ \-_]./)) return { error: 'No spaces, hyphens or underscores allowed.' }
    // return { valid: str.toUpperCase() }
  }

  const submitCreateGroup = () => {
    console.log(newJoinCode)
    console.log(newGroupName)
  }

  const closeModal = () => {
    setOpenModal(false)
    setNewGroupName('')
    setNewJoinCode('')
  }
  return (
    <>
      <Button onClick={() => setOpenModal(true)}>Create Group</Button>
      <Dialog open={openModal} onClose={closeModal}>
        <DialogTitle>Create Group</DialogTitle>
        <DialogContent>
          <TextField
            variant='outlined'
            fullWidth
            style={{ marginBottom: '20px' }}
            placeholder='Group Name'
            value={newGroupName}
            onChange={e => setNewGroupName(e.target.value)}
          />
          <TextField
            variant='outlined'
            fullWidth
            placeholder='Join Code'
            error={joinCodeError}
            value={newJoinCode}
            onChange={e => isCodeValid(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={submitCreateGroup} disabled={newGroupName.length < 1 || newJoinCode.length < 1}>Confirm</Button>
          <Button onClick={closeModal}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>

  )
}
export default CreateGroup
