import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { searchService } from '../../services/searchService'
import { formatGoal } from '../global/formatter'
import { useNavigate } from 'react-router-dom'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'

import image from '../../resources/Logomark_TEAL.svg'
import { getHydrationScore, getHydrationScoreColor } from '../../utils/hydrationScore'
import HidrateCard from '../HidrateCard'

const renderUser = ({ groupId, user, anonymous, navigate, units }) => {
  const handleClickRow = (id) => {
    navigate(`/dashboard/${groupId}/individual/${id}`)
  }

  const url = user.profile || image
  const average = Math.round(user.average * 100)
  const hydrationScore = getHydrationScore(user.progress.todayHydrationScore)

  return (
    <tr
      className='hover hover:cursor-pointer'
      key={user.id}
      onClick={() => handleClickRow(user.id)}
    >
      <th className='hidden md:table-cell'>
        <div className='avatar'>
          <div className='w-10 rounded-full'>
            <img alt={user.name} src={url} />
          </div>
        </div>
      </th>
      <td>{anonymous ? user.id : user.name}</td>
      <td>
        <p>{average || 0}%</p>
      </td>
      <td>{user.subgroups || 'None'}</td>
      <td>{formatGoal(user.progress.todayTotal, user.progress.todayGoal, units)}</td>
      <td>
        <div
          style={{ backgroundColor: getHydrationScoreColor(hydrationScore) }}
          className='h-[30px] w-[30px] text-xs flex items-center justify-center rounded-full'
        >
          {hydrationScore}
        </div>
      </td>
    </tr>
  )
}

const LeaderBoard = ({ groupId, units }) => {
  const [state, setState] = useState({ users: [], anonymize: false })
  const [sortBy, setSortBy] = useState('HYDRATION_SCORE_DESC')

  const navigate = useNavigate()

  useEffect(() => {
    const abortController = new window.AbortController()
    const fetchData = async (sortByQueryKey) => {
      try {
        const response = await searchService.getLeaderboard(groupId, false, sortByQueryKey, abortController.signal)
        const sortedList = sortList(sortBy, response.users)
        if (response.result === 'bad') { throw new Error('Failed to fetch leaderboard') }
        setState({
          ...response,
          users: sortedList
        })
      } catch (e) {
        console.log(e)
      }
    }

    fetchData('hydrationScore')
    return () => {
      abortController.abort()
    }
  }, [groupId])

  const sortList = (sortDirection, usersList) => {
    if (sortDirection === 'HYDRATION_SCORE_DESC') {
      return usersList.sort((a, b) => (b.progress.todayHydrationScore - a.progress.todayHydrationScore))
    } else {
      return usersList.sort((a, b) => (a.progress.todayHydrationScore - b.progress.todayHydrationScore))
    }
  }

  const handleSortByHydrationScore = () => {
    const newSortBy = sortBy === 'HYDRATION_SCORE_ASC' ? 'HYDRATION_SCORE_DESC' : 'HYDRATION_SCORE_ASC'
    const newList = sortList(newSortBy, state.users)
    setSortBy(newSortBy)
    setState((prev) => ({
      ...prev,
      users: newList
    }))
  }

  return (
    <HidrateCard>
      <div className='text-left'>
        <h3 className='text-xl font-semibold'>Leaderboard</h3>

        <div className='overflow-x-auto'>
          <table className='table table-auto w-[100%] whitespace-nowrap'>
            <thead>
              <tr>
                <th className='hidden md:table-cell' />
                <th>{state.anonymize ? 'ID' : 'Name'}</th>
                <th>7 Day Average</th>
                <th>Group</th>
                <th>Current Goal</th>
                <th>
                  <button className='flex items-center justify-center' onClick={handleSortByHydrationScore}>
                    <p className='mr-2'>Hydration Score</p>
                    {sortBy === 'HYDRATION_SCORE_ASC'
                      ? (<ChevronUpIcon height={20} width={20} />)
                      : (<ChevronDownIcon height={20} width={20} />)}
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {state.users.map((user) =>
                renderUser({
                  groupId,
                  user,
                  navigate,
                  anonymous: state.anonymize,
                  units
                })
              )}
            </tbody>
          </table>
        </div>

      </div>
    </HidrateCard>
  )
}

LeaderBoard.propTypes = {
  groupId: PropTypes.string.isRequired,
  units: PropTypes.any
}

export default LeaderBoard
