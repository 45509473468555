import React, { useState, useEffect } from 'react'
import PageHeader from '../PageHeader'
import NotificationsList from './NotificationsList'
import NotificationEdit from './NotificationEdit'
import { notificationsService } from '../../services/notificationsService'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import ReactGA from 'react-ga4'

const NotificationsPage = () => {
  const { groupId } = useParams()

  const [view, setView] = useState('list')
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(true)
  const [msgApprovals, setMsgApprovals] = useState({})

  const fetchNotifications = async (abortController) => {
    const results = await notificationsService.getGroupNotifications(groupId, abortController.signal)
    if (results === 'bad' || results.message) {
      setNotifications([])
      toast.error(results.message || 'Error loading notifications.')
      setView('blocked')
      return
    }
    setMsgApprovals({})
    setNotifications(
      Object.keys(results.notifications).map(key => {
        const notif = results.notifications[key]
        const deliveries = notif.deliveries.map(date => moment(date)).sort((a, b) => a.diff(b))
        const pastDeliveries = []
        const futureDeliveries = []
        let lastDelivery = null
        let nextDelivery = null
        deliveries.forEach(delivery => {
          if (delivery.isBefore()) {
            pastDeliveries.push(delivery)
            lastDelivery = delivery
          } else {
            futureDeliveries.push(delivery)
            if (!nextDelivery) nextDelivery = delivery
          }
        })

        return {
          ...notif,
          objectId: key,
          deliveries,
          pastDeliveries,
          futureDeliveries,
          lastDelivery,
          nextDelivery,
          audienceCount: new Set(notif.audience.map(user => user.id)).size
        }
      })
    )
    setLoading(false)
  }

  useEffect(() => {
    const abortController = new window.AbortController()
    const syncFetch = () => {
      fetchNotifications(abortController)
      return () => {
        abortController.abort()
      }
    }
    return syncFetch()
  }, [groupId])

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window?.location?.pathname ?? '',
      title: 'Notifications Page'
    })
  }, [])

  return (
    <div className='flex-grow'>
      <PageHeader title='Notifications' hideSubgroupChip />
      <div>
        {view === 'list' && view !== 'blocked'
          ? <NotificationsList {...{
            groupId,
            notifications,
            setView,
            loading,
            msgApprovals,
            setMsgApprovals,
            fetchNotifications
          }}
            />
          : <NotificationEdit {...{
            groupId,
            notifications,
            setView,
            fetchNotifications,
            msgApprovals,
            setMsgApprovals
          }}
            />}
      </div>
    </div>
  )
}

export default NotificationsPage
