import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import TableFooter from '@mui/material/TableFooter'
import TablePaginationActionsWrapped from './TablePaginationActions'
import UserListRow from './UserListRow'
import PageHeader from '../PageHeader'
import { searchService } from '../../services/searchService'
import { Avatar, CircularProgress, Grid, IconButton } from '@mui/material'
import { authService } from '../../services/authService'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import GroupIcon from '@mui/icons-material/Group'
import SubgroupSettings from './SubgroupSettings'
import MaingroupSettings from './MaingroupSettings'
import { updateGroupCache } from '../global/groupInfo'
import { useParams, Link, useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'

const styles = theme => ({
  textField: {
    flexGrow: 1
  },
  avatar: {
    margin: 10
  },
  mainPanel: {
    textAlign: 'center',
    overflowX: 'auto'
  },
  table: {
    width: '100%'
  },
  sortableHeader: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& svg': {
      color: '#888'
    }
  },
  pageBar: {
    justifyContent: 'center'
  },
  pageSpacer: {
    display: 'none'
  },
  subgroups: {
    marginBottom: theme.spacing(4)
  },
  subgroupTile: {
    padding: theme.spacing(2),
    position: 'relative'
  },
  plainLink: {
    textDecoration: 'none'
  },
  subgroupUserCount: {
    marginTop: theme.spacing(1),
    border: '1px solid ' + theme.palette.primary.main,
    borderRadius: '3em',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    fontSize: '.8em',
    padding: '0.2em 0.5em 0.2em 0.8em',
    '& svg': {
      height: '.75em'
    }
  },
  avatarList: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    minHeight: '96px',
    '& > *': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  avatarAdd: {
    width: '40px',
    height: '40px',
    border: '1px solid ' + theme.palette.primary.main,
    borderRadius: '20px',
    background: theme.palette.primary.light + '22',
    color: theme.palette.primary.dark,
    fontSize: '30px',
    lineHeight: '32px',
    textAlign: 'center'
  },
  subgroupMore: {
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  subgroupCreate: {
    background: '#fff3',
    border: '1px solid #aaa8',
    borderRadius: '4px',
    padding: theme.spacing(1.5),
    cursor: 'pointer',
    transition: 'background .1s ease-in-out',
    '&:hover': {
      background: 'white'
    }
  }
})

const initialState = {
  users: [],
  count: 0,
  searching: false,
  message: ''
}

const SubgroupTile = ({ subgroup, groupId, onChange, classes }) => {
  const [showSettings, setShowSettings] = useState(false)

  const handleShowSettings = event => {
    event.stopPropagation()
    event.preventDefault()
    setShowSettings(true)
  }

  return (
    <Grid item md={4}>
      <Link to={`/dashboard/${subgroup.id}/users`} className={classes.plainLink}>
        <Paper className={classes.subgroupTile}>
          <IconButton
            className={classes.subgroupMore}
            onClick={handleShowSettings}
            size='large'
          >
            <MoreVertIcon />
          </IconButton>
          <Typography variant='h4'>{subgroup.name}</Typography>
          <div className={classes.subgroupUserCount} onClick={handleShowSettings}>
            {subgroup.members.length} <GroupIcon />
          </div>
          <div className={classes.avatarList}>
            {subgroup.userData
              ? subgroup.userData.slice(0, 9).map(member => (
                <Avatar key={member.id} src={member.profile?.url}>{member.initials}</Avatar>
              ))
              : subgroup.members.slice(0, 9).map(member => (
                <Avatar key={member} />
              ))}
            {subgroup.members.length === 0 &&
              <div className={classes.avatarAdd}>+</div>}
          </div>
        </Paper>
      </Link>
      <SubgroupSettings
        {...{ subgroup, groupId }}
        open={showSettings}
        onClose={changed => {
          setShowSettings(false)
          if (changed === true) { // closing by clicking outside passes an object
            onChange()
          }
        }}
      />
    </Grid>
  )
}

SubgroupTile.propTypes = {
  classes: PropTypes.object.isRequired,
  subgroup: PropTypes.any,
  groupId: PropTypes.string,
  onChange: PropTypes.func
}

const UserList = ({ classes }) => {
  const { groupId, parentId } = useParams()
  const navigate = useNavigate()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [state, setState] = useState(initialState)
  const [loading, setLoading] = useState(false)
  const [loadedId, setLoadedId] = useState(null)
  const [sortState, setSortState] = useState({ id: 'email', asc: true })
  const [subgroups, setSubgroups] = useState([])
  const [showSubgroupCreation, setShowSubgroupCreation] = useState(false)
  const [showSettings, setShowSettings] = useState(false)

  const handleShowSettings = event => {
    event.stopPropagation()
    event.preventDefault()
    setShowSettings(true)
  }

  const [prefs, setPrefs] = useState({})
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window?.location?.pathname ?? '',
      title: 'User List Page'
    })
    return authService.listenToUserPrefs(setPrefs)
  }, [])

  const permissions = authService.usePermissions(groupId)
  const [refreshGroup, setRefreshGroups] = useState(0)

  useEffect(() => {
    let isMounted = true
    const abortController = new window.AbortController()
    const fetchData = async () => {
      setLoading(true)
      if (groupId !== loadedId) {
        setState(initialState)
      }

      try {
        const res = await searchService.searchUsers(groupId, { page, limit: rowsPerPage, sort: sortState.id, asc: sortState.asc }, abortController.signal)
        setLoadedId(groupId)
        setState(res)

        setLoading(false)
      } catch (err) {
        if (isMounted) {
          setState(initialState)
          setLoading(false)
          console.error(err)
        }
      }
    }

    const syncFetchData = () => {
      fetchData()

      return () => {
        abortController.abort()
        isMounted = false
      }
    }

    return syncFetchData()
  }, [groupId, page, rowsPerPage, sortState, refreshGroup])

  const [refreshSubgroups, setRefreshSubgroups] = useState(0)

  useEffect(() => {
    let isMounted = true
    const abortController = new window.AbortController()

    const fetchData = async () => {
      if (groupId !== loadedId) {
        setSubgroups([])
      }

      try {
        const res = await searchService.getSubGroupsMembers(groupId, abortController.signal)

        const cache = {}
        const results = res.subgroupMembers.filter(g => g.id !== groupId)
        setSubgroups(results)

        const getAndSetSubgroupMembers = async (subgroup) => {
          cache[subgroup.id] = { id: subgroup.id, name: subgroup.name, parent: groupId }
          const res = await searchService.getUsers(subgroup.id, {}, abortController.signal)
          const data = res.map(user => ({
            ...user,
            initials: (user.name + ' ').replace(/(\w).*?\W+/g, '$1')
          }))
          setSubgroups(val => {
            const newVal = [...val]
            const index = newVal.findIndex(s => s.id === subgroup.id)
            newVal.splice(index, 1, { ...newVal[index], userData: data })
            return newVal
          })
        }

        const promises = []
        for (const subgroup of results) {
          promises.push(getAndSetSubgroupMembers(subgroup))
        }

        await Promise.all(promises)

        updateGroupCache(cache)
      } catch (err) {
        if (isMounted) {
          setSubgroups(null)
          console.error(err)
        }
      }
    }

    const syncFetchData = () => {
      fetchData()

      return () => {
        abortController.abort()
        isMounted = false
      }
    }
    return syncFetchData()
  }, [groupId, refreshSubgroups])

  const handleChangePage = (p) => {
    setState(state => ({ ...state, users: [] }))
    setPage(p)
  }

  const handleChangeRowsPerPage = event => {
    const rowsPerPage = parseInt(event.target.value)
    setRowsPerPage(rowsPerPage)
  }

  const SortableHeader = ({ id, title, biDirec = true, defaultDirec = true, flip = false }) => {
    const active = sortState.id === id
    return (
      <TableCell
        onClick={() => {
          setSortState({ id, asc: (active && biDirec) ? !sortState.asc : defaultDirec })
          setState(state => ({ ...state, users: [] }))
          setLoading(true)
        }}
      >
        <div className={classes.sortableHeader}>
          {active && (sortState.asc !== flip ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />)}
          <Typography variant='subtitle1'>{title}</Typography>
        </div>
      </TableCell>
    )
  }

  SortableHeader.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    biDirec: PropTypes.bool,
    defaultDirec: PropTypes.bool,
    flip: PropTypes.bool
  }

  const message = state.message
  if (message) {
    return (
      <div>
        <Typography variant='h1' className={classes.message}>{message}</Typography>
      </div>
    )
  } else {
    return (
      <div>
        <PageHeader title='Users' {...{ groupId, parentId }} />

        <div>
          {permissions.check('group', 'viewer') &&
            <Grid container spacing={2} className={classes.subgroups}>
              {subgroups && subgroups.map(subgroup => (
                <SubgroupTile
                  key={subgroup.id + refreshSubgroups}
                  {...{ subgroup, groupId, classes }}
                  onChange={() => {
                    setRefreshSubgroups(v => ++v)
                  }}
                />
              ))}
              {permissions.check('group', 'publisher') && !parentId &&
                <Grid item md={4}>
                  <div
                    role='button'
                    className={classes.subgroupCreate}
                    onClick={event => {
                      event.preventDefault()
                      setShowSubgroupCreation(true)
                    }}
                  >
                    <Typography variant='h4'>Create a subgroup...</Typography>
                  </div>
                  <SubgroupSettings
                    {...{ groupId }}
                    create
                    open={showSubgroupCreation}
                    onClose={() => setShowSubgroupCreation(false)}
                  />
                </Grid>}
            </Grid>}
          <Paper square={false} className={classes.mainPanel}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <SortableHeader id='name' title='Name' />
                  <SortableHeader id='email' title='Email' />
                  <TableCell><Typography variant='subtitle1'>Subgroup</Typography></TableCell>
                  <TableCell><Typography variant='subtitle1'>Progress</Typography></TableCell>
                  <TableCell><Typography variant='subtitle1'>Today</Typography></TableCell>
                  <SortableHeader id='average' title='7-Day Avg' defaultDirec={false} flip />
                  <TableCell>
                    <Typography variant='subtitle1'>
                      <IconButton
                        className={classes.subgroupMore}
                        onClick={handleShowSettings}
                        size='large'
                        sx={{ position: 'relative !important' }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <MaingroupSettings
                groupId={groupId}
                open={showSettings}
                users={state.users}
                onClose={changed => {
                  setShowSettings(false)
                  if (changed === true) { // closing by clicking outside passes an object
                    // change so we are not manually triggering useEffect with arbitrary variables
                    setRefreshGroups(v => ++v)
                    setRefreshSubgroups(v => ++v)
                  }
                }}
              />
              <TableBody className={classes.body}>
                {state.users.sort((a, b) => b.average > a.average).map(user => (
                  <UserListRow
                    className={classes.row}
                    key={user.id || 'key'}
                    groupId={groupId}
                    user={user}
                    navigate={navigate}
                    compact={state.users.length > 25}
                    units={prefs.measurementSystem}
                  />
                ))}
                {loading &&
                  <TableRow>
                    <TableCell colSpan={7} align='center'>
                      <CircularProgress className={classes.spinner} />
                    </TableCell>
                  </TableRow>}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    colSpan={7}
                    count={state.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      native: true
                    }}
                    classes={{
                      toolbar: classes.pageBar,
                      spacer: classes.pageSpacer
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActionsWrapped}
                  />
                </TableRow>
              </TableFooter>
            </Table>
            {state.users.length !== state.count && <div className='red py-2'>A user is missing from your group. Please confirm user has registered a sip on the mobile app.</div>}
          </Paper>
        </div>
      </div>
    )
  }
}

UserList.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(UserList)
