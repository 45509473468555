import React from 'react'
import { Link } from 'react-router-dom'
import { PlusIcon } from '@heroicons/react/24/outline'

const FilterHeader = ({ filters, canAdd, addText = 'Create', addLink, onAdd }) => {
  return (
    <div className={`
    flex flex-col md:flex-row 
    space-y-2.5 md:space-y-0 
    items-start justify-between
    mb-5`}
    >
      {canAdd &&
        <div>
          {addLink
            ? (
              <Link to={addLink}>
                <button className='btn btn-sm btn-primary' onClick={onAdd}>
                  <PlusIcon height={25} width={25} />
                  {addText}
                </button>
              </Link>
              )
            : (
              <button className='btn btn-sm btn-primary' onClick={onAdd}>
                <PlusIcon height={25} width={25} />
                {addText}
              </button>
              )}
        </div>}
      <div>
        {filters.map(filter => (
          <div key={filter.title} className='flex items-center justify-center mb-2'>
            <span className='hidden md:block md:mr-2.5'>{filter?.title ?? ''}</span>
            <div className='join'>
              {filter.options.map(option => {
                const opt = option.id
                  ? { ...option, title: option.title || option.id }
                  : { id: option, title: option }

                if ((filter.prune || opt.prune) && !(filter.counts[opt.id]) && !opt.keep) {
                  return null
                }
                return (
                  <button
                    key={opt.id}
                    className={`btn btn-sm btn-primary join-item ${opt.id !== filter.current && 'btn-outline'}`}
                    onClick={() => filter.onChange(opt.id)}
                  >
                    {(opt.title)}{(filter.counts ? ` (${filter.counts[opt.id] || 0})` : '')}
                  </button>
                )
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FilterHeader
