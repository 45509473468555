import React from 'react'

const PushNotificationAlwaysToggle = ({ userParams, setUserParams }) => {
  return (
    <div className='form-control'>
      <label className='label cursor-pointer'>
        <span className='label-text'>Always send app reminders</span>
        <input
          type='checkbox'
          className='toggle toggle-primary'
          checked={userParams.pushNotificationAlways}
          onChange={() => setUserParams((prev) => ({ ...prev, pushNotificationAlways: !prev.pushNotificationAlways }))}
        />
      </label>
    </div>
  )
}

export default PushNotificationAlwaysToggle
