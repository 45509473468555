import React from 'react'
import DatePicker from 'react-datepicker'
import { format } from 'date-fns'

import 'react-datepicker/dist/react-datepicker.css'
import DatePickerCustomInput from '../../DatePickerCustomInput'

const BirthdayInput = ({ userParams, setUserParams }) => {
  const today = new Date()
  return (
    <div>
      <label className='label'>
        <span className='label-text'>Birthday</span>
      </label>
      <DatePicker
        customInput={<DatePickerCustomInput />}
        wrapperClassName='w-full'
        selected={userParams.birthday}
        value={format(userParams.birthday, 'MMMM d, yyyy')}
        onChange={(date) => setUserParams((prev) => ({ ...prev, birthday: date }))}
        maxDate={today}
        showPopperArrow={false}
        showMonthDropdown
        showYearDropdown
        popperModifiers={[
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport',
              tether: false,
              altAxis: true
            }
          }
        ]}
      />
    </div>
  )
}

export default BirthdayInput
