import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { Avatar, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { searchService } from '../../services/searchService'
import { subgroupService } from '../../services/subgroupService'
import { authService } from '../../services/authService'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

const styles = theme => ({
  dialogContent: {
    maxWidth: '80vw',
    width: '30em',
    minWidth: '320px'
  },
  divider: {
    border: 'none',
    borderTop: '1px solid #ddd',
    margin: '1em 0'
  },
  smallCell: {
    width: '2em',
    height: '2em',
    padding: '0.25em'
  },
  userHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  addIcon: {
    height: '0.8em',
    width: '0.8em',
    color: '#444',
    marginRight: theme.spacing(1)
  },
  searchField: {
    marginTop: theme.spacing(2),
    display: 'flex',
    '& button': {
      marginLeft: theme.spacing(1)
    }
  },
  searchIcon: {
    color: '#888',
    marginRight: theme.spacing(1)
  },
  emptyMsg: {
    fontStyle: 'italic',
    color: '#888'
  },
  saveError: {
    padding: theme.spacing(2),
    paddingBottom: '0',
    textAlign: 'right',
    fontStyle: 'italic'
  }
})

// function spliceFromState (val, state, setState) {
//   console.log(arguments)
//   const index = state.findIndex(v => v === val)
//   if (index > 0) {
//     const newState = [...state]
//     newState.splice(index, 1)
//     setState(newState)
//   }
// }

const UserRow = ({ classes, user, canEdit, inGroup, onAdd, onRemove }) => {
  return (
    <TableRow>
      {canEdit &&
        <TableCell className={classes.smallCell}>
          <IconButton
            aria-label={inGroup ? 'remove' : 'add'}
            onClick={inGroup ? onRemove : onAdd}
            size='large'
          >
            {inGroup ? <RemoveCircleIcon /> : <AddCircleOutlineIcon />}
          </IconButton>
        </TableCell>}
      <TableCell className={classes.smallCell}>
        <Avatar src={user.profile?.url || user.profile} alt={user.name} />
      </TableCell>
      <TableCell>
        {user.name}
      </TableCell>
    </TableRow>
  )
}

UserRow.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.any,
  canEdit: PropTypes.bool,
  inGroup: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func
}

const ClearButton = ({ onClick }) => {
  return (
    <InputAdornment position='end'>
      <IconButton
        aria-label='clear'
        onClick={onClick}
        onMouseDown={e => e.preventDefault()}
        size='large'
      >
        <ClearIcon />
      </IconButton>
    </InputAdornment>
  )
}

ClearButton.propTypes = {
  onClick: PropTypes.func
}

const SubgroupSettings = ({ classes, groupId, open, onClose, subgroup = {}, create = false }) => {
  // const [loadingUsers, setLoadingUsers] = useState(false)

  const permissions = authService.usePermissions(groupId)
  const canEdit = permissions.check('group', 'editor')
  const canPublish = permissions.check('group', 'publisher')

  const [showSearch, setShowSearch] = useState(create)
  const [query, setQuery] = useState('')
  const [searchResults, setSearchResults] = useState(null)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const searchDebounce = useRef(null)
  useEffect(() => {
    if (!query) {
      setSearchResults(null)
      setLoadingSearch(false)
      return
    }
    setLoadingSearch(true)
    searchDebounce.current = setTimeout(() => {
      searchService.searchUsers(groupId, {
        page: 0,
        limit: 20,
        filter: query
      }).then(res => {
        setSearchResults(res.users)
        setLoadingSearch(false)
        console.log(res.users)
      }).catch(err => {
        console.error(err)
        setSearchResults([])
        setLoadingSearch(false)
      })
    }, 300)
    return () => {
      clearTimeout(searchDebounce.current)
    }
  }, [query, groupId])

  const [details, setDetails] = useState({
    name: subgroup.name,
    joinCode: subgroup.joinCode
  })
  const [detailsChanged, setDetailsChanged] = useState(null)
  useEffect(() => {
    setDetailsChanged(val => val !== null)
    // ignore initial update by setting from null to false
  }, [details])

  const [toAdd, setToAdd] = useState([])
  const [toRemove, setToRemove] = useState([])
  const [roster, setRoster] = useState(subgroup.userData || [])
  useEffect(() => {
    setRoster(subgroup.userData || [])
  }, [subgroup.userData])

  const [loadingSave, setLoadingSave] = useState(false)
  const [saveError, setSaveError] = useState(null)

  const handleSaveSuccess = () => {
    onClose(true)
    setLoadingSave(false)
  }

  const handleSaveError = err => {
    console.error(err)
    setSaveError(err)
    setLoadingSave(false)
  }

  const cancelAndClose = () => {
    onClose(false)
    setSubgroupIsGone(false)
    setShowDeleteText(false)
    setShowDeleteIcon(true)
    setToAdd([])
    setToRemove([])
    setRoster(subgroup.userData || [])
    setDetailsChanged(null)
    setDetails({
      name: subgroup.name,
      joinCode: subgroup.joinCode
    })
  }

  const saveAndClose = () => {
    setLoadingSave(true)
    if (create) {
      subgroupService.createSubgroup(groupId, details)
        .then(res => {
          subgroupService.editSubgroupMembers(groupId, res.subGroupId, { add: toAdd, remove: toRemove })
            .then(handleSaveSuccess)
        })
        .catch(handleSaveError)
    } else {
      const calls = []
      if (detailsChanged) {
        calls.push(subgroupService.editSubgroup(groupId, subgroup.id, details))
      }
      if (toAdd.length || toRemove.length) {
        calls.push(subgroupService.editSubgroupMembers(groupId, subgroup.id, { add: toAdd, remove: toRemove }))
      }
      Promise.all(calls)
        .then(handleSaveSuccess)
        .catch(handleSaveError)
    }
  }

  const [showDeleteText, setShowDeleteText] = useState(false)
  const [deleteStatusText, setDeleteStatusText] = useState('Are you sure you want to delete this subgroup? This change cannot be undone. Subgroups cannot be deleted if they contain additional subgroups.')
  const [deletingSubgroup, setDeletingSubgroup] = useState(false)
  const [showDeleteIcon, setShowDeleteIcon] = useState(true)
  const [subgroupIsGone, setSubgroupIsGone] = useState(false)

  const deleteSubgroup = async () => {
    setDeletingSubgroup(true)
    try {
      const deletedSubgroupMessage = await subgroupService.deleteSubgroup(groupId, subgroup.id)
      setDeleteStatusText(deletedSubgroupMessage)
      // set cancel and delete buttons to okay
      setSubgroupIsGone(true)
      // on okay or click outside of box reset everything
    } catch (error) {
      console.log(error)
      setDeleteStatusText(error)
    }
    setDeletingSubgroup(false)
  }

  const fieldAdornment = (
    query.length === 0
      ? null
      : loadingSearch
        ? <div><CircularProgress className={classes.spinner} size={24} /></div>
        : <ClearButton onClick={() => setQuery('')} />
  )

  const deleteSubgroupPrompt = () => {
    setShowDeleteText(true)
    setShowDeleteIcon(false)
  }

  const cancelDeleteSubgroupPrompt = () => {
    setShowDeleteText(false)
    setShowDeleteIcon(true)
  }

  return (
    <Dialog onClose={cancelAndClose} open={open}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '20px' }}>
        <DialogTitle>
          {create ? 'Create' : showDeleteText ? 'Delete' : 'Manage'} Subgroup
        </DialogTitle>
        {(!create && showDeleteIcon) && <DeleteForeverIcon style={{ cursor: 'pointer' }} onClick={deleteSubgroupPrompt} />}
      </div>
      {!showDeleteText &&
        <DialogContent dividers className={classes.dialogContent}>
          <Typography variant='h4'>Name</Typography>
          <TextField
            variant='outlined'
            fullWidth
            style={{ display: 'block', margin: '.5em 0 1em' }}
            disabled={!canPublish}
            placeholder='Name'
            value={details.name}
            onChange={e => setDetails(obj => ({ ...obj, name: e.target.value }))}
          />
          <Typography variant='h4'>Join Code</Typography>
          <TextField
            variant='outlined'
            style={{ display: 'block', margin: '.5em 0' }}
            disabled={!canPublish}
            placeholder='Join Code'
            value={details.joinCode}
            onChange={e => setDetails(obj => ({ ...obj, joinCode: e.target.value }))}
          />
          <Typography variant='body1'>Users can join this subgroup in the app using the code above.</Typography>
          <hr className={classes.divider} />
          <div className={classes.userHeader}>
            <Typography variant='h4'>Users</Typography>
            {!showSearch && canEdit &&
              <Button onClick={() => setShowSearch(true)}>
                <AddCircleOutlineIcon className={classes.addIcon} /> Add Users...
              </Button>}
          </div>
          {showSearch && canEdit &&
            <div className={classes.searchField}>
              <TextField
                fullWidth
                variant='outlined'
                placeholder='Search by email...'
                value={query}
                onChange={e => {
                  setQuery(e.target.value)
                }}
                InputProps={{
                  startAdornment: <SearchIcon className={classes.searchIcon} />,
                  endAdornment: fieldAdornment
                }}
              />
              {!create &&
                <Button
                  onClick={() => {
                    setShowSearch(false)
                    setQuery('')
                  }}
                >
                  Done
                </Button>}
            </div>}
          <Table className={classes.table}>
            <TableBody className={classes.body}>
              {(
                searchResults ||
              roster ||
              subgroup.members?.map(u => ({ id: u }))
              )?.map(user => (
                <UserRow
                  key={user.id}
                  {...{ classes, user, canEdit }}
                  inGroup={roster.some(v => v.id === user.id)}
                  onAdd={() => {
                    setToAdd(arr => [...arr, user.id])
                    setToRemove(arr => arr.filter(v => v !== user.id))
                    setRoster(arr => [user, ...arr])
                  }}
                  onRemove={() => {
                    setToAdd(arr => arr.filter(v => v !== user.id))
                    setToRemove(arr => [...arr, user.id])
                    setRoster(arr => arr.filter(v => v.id !== user.id))
                  }}
                />
              ))}
              {(
                searchResults ||
              roster ||
              subgroup.members?.map(u => ({ id: u }))
              )?.length === 0 &&
                <TableRow>
                  <TableCell colSpan={3} align='center' className={classes.emptyMsg}>
                    {searchResults ? 'No matching users found.' : "Nobody's here yet."}
                  </TableCell>
                </TableRow>}
              {/* loadingUsers &&
              <TableRow>
                <TableCell colSpan={3} align='center'>
                  <CircularProgress className={classes.spinner} />
                </TableCell>
            </TableRow> */}
            </TableBody>
          </Table>
          {/* <pre style={{ fontSize: '0.5em' }}>
          {JSON.stringify({ add: toAdd, remove: toRemove, roster }, null, 2)}
        </pre> */}
        </DialogContent>}
      {showDeleteText &&
        <DialogContent dividers className={classes.dialogContent}>
          <Typography variant='h4'>{deleteStatusText}</Typography>
        </DialogContent>}

      {saveError &&
        <div className={classes.saveError}>
          There was a problem saving this subgroup. ({String(saveError)})
        </div>}
      {!showDeleteText &&
        <DialogActions>
          {loadingSave
            ? <CircularProgress size={37} />
            : create || detailsChanged || toAdd.length > 0 || toRemove.length > 0
              ? (
                <>
                  <Button onClick={cancelAndClose} color='secondary'>Cancel</Button>
                  <Button onClick={saveAndClose} color='primary' disabled={!(details.name?.trim())}>Save</Button>
                </>
                )
              : <Button onClick={() => onClose(false)} color='primary'>Done</Button>}
        </DialogActions>}

      {showDeleteText &&
        <DialogActions>
          {!subgroupIsGone &&
            <>
              {!deletingSubgroup && <Button onClick={cancelDeleteSubgroupPrompt} color='primary'>Cancel</Button>}
              <Button onClick={deleteSubgroup} color='primary'>
                {deletingSubgroup && <div><CircularProgress className={classes.spinner} size={24} /></div>}
                {!deletingSubgroup && 'Delete'}
              </Button>
            </>}
          {subgroupIsGone &&
            <Button onClick={cancelAndClose} color='primary'>Close</Button>}
        </DialogActions>}
    </Dialog>
  )
}

SubgroupSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  groupId: PropTypes.any,
  open: PropTypes.any,
  onClose: PropTypes.any,
  subgroup: PropTypes.any,
  create: PropTypes.any
}

export default withStyles(styles)(SubgroupSettings)
