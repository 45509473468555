import { format } from 'date-fns'
import moment from 'moment'

const config = { apiUrl: process.env.REACT_APP_API_URL }
const authHeader = require('./authHeader')

function getUsers (groupId, options, signal) {
  if (groupId && typeof groupId === 'string') {
    const requestOptions = {
      method: 'GET',
      headers: authHeader.authHeader(),
      signal
    }

    return window.fetch(`${config.apiUrl}group/${groupId}/memberslist`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response
      })
  } else {
    return Promise.resolve([])
  }
}

async function findUserByUsername (groupId, query, signal) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader.authHeader(),
    signal
  }

  if (!query) {
    return Promise.reject(new Error())
  }

  const username = encodeURIComponent(query)

  const response = await window.fetch(`${config.apiUrl}group/${groupId}/finduser/${username}`, requestOptions)
  const user = await handleResponse(response)
  return user
}

// Searches for users within current groupId
function searchUsers (groupId, opts, signal) {
  // { page, limit, filter, fullSearch, sort, asc }
  const requestOptions = {
    method: 'GET',
    headers: authHeader.authHeader(),
    signal
  }

  if (!groupId) {
    return Promise.reject(new Error())
  }

  const paramString = Object.keys(opts).filter(key => opts[key] || opts[key] === false).map(key => key + '=' + opts[key]).join('&')

  return window.fetch(`${config.apiUrl}group/${groupId}/members?${paramString}`, requestOptions)
    .then((response) => {
      return handleResponse(response)
    })
}

async function getSips (userId, dayKey) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader.authHeader()
  }

  return window.fetch(`${config.apiUrl}member/${userId}/${dayKey}`, requestOptions)
    .then((response) => {
      return handleResponse(response)
    }).catch(() => {
      return { message: 'failed to fetch' }
    })
}

function getMonthHistory (userId, monthYearKey, signal) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader.authHeader(),
    signal
  }

  return window.fetch(`${config.apiUrl}member/${userId}/month/${monthYearKey}`, requestOptions)
    .then((response) => {
      return handleResponse(response)
    })
}

function getGroups () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader.authHeader()
  }
  return window.fetch(`${config.apiUrl}groups`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response
    })
}

function getGroupCounts (groupId, signal) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader.authHeader(),
    signal
  }

  return window.fetch(`${config.apiUrl}group/${groupId}/counts`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response
    })
}

function getTotalVolume (groupId, signal) {
  // if (groupId && typeof groupId === 'string') {
  const requestOptions = {
    method: 'GET',
    headers: authHeader.authHeader(),
    signal
  }

  return window.fetch(`${config.apiUrl}group/${groupId}/total`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response
    })
}

function getSubGroups (groupId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader.authHeader()
  }

  return window.fetch(`${config.apiUrl}group/${groupId}/subgroups`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response
    })
}

function getSubGroupsMembers (groupId, signal) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader.authHeader(),
    signal
  }

  return window.fetch(`${config.apiUrl}group/${groupId}/subgroups/members`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response
    })
}

const getHardware = async (memberId, signal) => {
  // if (groupId && typeof groupId === 'string') {
  const requestOptions = {
    method: 'GET',
    headers: authHeader.authHeader(),
    signal
  }

  return window.fetch(`${config.apiUrl}member/${memberId}/hardware`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response
    }).catch(
      console.error
    )
}

function getTrophies (groupId, memberId) {
  if (!groupId || !memberId) return
  const requestOptions = {
    method: 'GET',
    headers: authHeader.authHeader()
  }

  return window.fetch(`${config.apiUrl}group/${groupId}/member/${memberId}/awards`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response
    })
}

function getGroupAverages (groupId, signal) {
  if (groupId && typeof groupId === 'string') {
    const requestOptions = {
      method: 'GET',
      headers: authHeader.authHeader(),
      signal
    }

    return window.fetch(`${config.apiUrl}group/${groupId}/averages`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response
      })
  } else {
    return Promise.resolve([])
  }
}

function getGroupNotifications (groupId) {
  if (groupId && typeof groupId === 'string') {
    const requestOptions = {
      method: 'GET',
      headers: authHeader.authHeader()
    }

    return window.fetch(`${config.apiUrl}group/${groupId}/notifications`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response
      })
  } else {
    return Promise.resolve([])
  }
}

function getLeaderboard (groupId, sort, sortBy, signal) {
  if (groupId && typeof groupId === 'string') {
    const requestOptions = {
      method: 'GET',
      headers: authHeader.authHeader(),
      signal
    }

    return window.fetch(`${config.apiUrl}group/${groupId}/leaderboard?ascend=${sort}&sortBy=${sortBy}&dateKey=${moment().format('YYYY-MM-DD')}`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response
      })
  } else {
    return Promise.resolve([])
  }
}

function getUserJoinDates (groupId, signal) {
  if (groupId && typeof groupId === 'string') {
    const requestOptions = {
      method: 'GET',
      headers: authHeader.authHeader(),
      signal
    }

    return window.fetch(`${config.apiUrl}group/${groupId}/joindates`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response
      })
  } else {
    return Promise.resolve([])
  }
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        window.localStorage.clear()
        window.location.replace('/logout')
      }
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}

// Takes group ID and returns all users hydration scores of group
function getHydrationScores (groupId, sortBy, signal, date) {
  if (groupId && typeof groupId === 'string') {
    const requestOptions = {
      method: 'GET',
      headers: authHeader.authHeader(),
      signal
    }

    let dateKey
    if (!date) {
      dateKey = format(new Date(), 'yyyy-MM-dd')
    } else {
      dateKey = format(date, 'yyyy-MM-dd')
    }

    return window.fetch(`${config.apiUrl}group/${groupId}/hydrationscores?sortBy=${sortBy}&dateKey=${dateKey}`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response
      })
  } else {
    return Promise.resolve([])
  }
}

export const searchService = {
  getUsers,
  findUserByUsername,
  searchUsers,
  getGroups,
  getGroupCounts,
  getSips,
  getMonthHistory,
  getTotalVolume,
  getGroupAverages,
  getHardware,
  getTrophies,
  getLeaderboard,
  getUserJoinDates,
  getGroupNotifications,
  getSubGroups,
  getSubGroupsMembers,
  getHydrationScores
}
