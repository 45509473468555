import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { motion, useMotionValue } from 'framer-motion'
import { getHydrationScoreColor } from '../../utils/hydrationScore'

export const HydrationProgress = ({ hydrationScore }) => {
  const scorePercentage = hydrationScore === 'NA'
    ? 0.01
    : Math.floor(hydrationScore * 100)

  const hydrationScoreColor = getHydrationScoreColor(scorePercentage)

  const progressRefBackground = useRef(null)
  const progressRefForeground = useRef(null)
  const backgroundPathRef = useRef(null)
  const foregroundPathRef = useRef(null)

  const progressLength = useMotionValue(0.01)
  const progressX = useMotionValue(0)
  const progressY = useMotionValue(0)

  const transition = { duration: 2, ease: 'easeInOut' }

  const scoreTitle = 'Hydration Score'

  useEffect(() => {
    const foreGroundPathElement = foregroundPathRef.current
    const totalLength = foreGroundPathElement.getTotalLength()

    const progress = progressLength.get()
    const initialCoords = foreGroundPathElement.getPointAtLength(progress * totalLength)
    progressX.set(initialCoords.x)
    progressY.set(initialCoords.y)

    const unsubscribe = progressLength.onChange((latest) => {
      const pathCoord = foreGroundPathElement.getPointAtLength(latest * totalLength)
      progressX.set(pathCoord.x)
      progressY.set(pathCoord.y)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const getHydrationRating = (score) => {
    if (hydrationScore === 'NA') {
      return 'Inactive'
    }
    if (score < 33) {
      return 'Poor'
    }
    if (score >= 33 && score < 66) {
      return 'Good'
    }
    if (score >= 66) {
      return 'Optimal'
    }
  }

  return (
    <div>
      <div className='relative my-5'>
        <motion.svg viewBox='12 13 25 25' height='250' className='w-full'>

          <path
            ref={backgroundPathRef}
            fill='none'
            d='M7.2228 35.3C7.07618 34.3883 7 33.453 7 32.5C7 22.835 14.835 15 24.5 15C34.165 15 42 22.835 42 32.5C42 33.453 41.9238 34.3883 41.7772 35.3'
            style={{
              stroke: '#e7e7e8',
              strokeLinecap: 'round',
              strokeWidth: '2.5'
            }}
          />

          <motion.path
            initial={{ pathLength: 0.01 }}
            animate={{
              pathLength: (hydrationScore === 0 || hydrationScore === 'NA')
                ? 0.01
                : hydrationScore
            }} // 0 renders some weird artifacts on the SVG progress
            transition={transition}
            pathLength={progressLength}
            fill='none'
            strokeLinecap='round'
            stroke={hydrationScoreColor}
            strokeWidth='2.5'
            ref={foregroundPathRef}
            d='M7.2228 35.3C7.07618 34.3883 7 33.453 7 32.5C7 22.835 14.835 15 24.5 15C34.165 15 42 22.835 42 32.5C42 33.453 41.9238 34.3883 41.7772 35.3'
          />

          <motion.circle
            r='2'
            fill={hydrationScoreColor}
            cx={progressX}
            cy={progressY}
            ref={progressRefBackground}
          />
          <motion.circle
            r='1.2'
            fill='white'
            cx={progressX}
            cy={progressY}
            ref={progressRefForeground}
          />
        </motion.svg>

        <div className='absolute top-0 left-0 flex flex-col items-center justify-end h-full w-full'>
          <h3 className='text-2xl font-semibold'>{scoreTitle}</h3>
          <p style={{ fontSize: 56, margin: 0 }}>
            {hydrationScore === 'NA' ? 0 : scorePercentage}
          </p>

          <div className='bg-gray-200 rounded-xl px-10 py-2 mt-5'>
            <p>{getHydrationRating(scorePercentage)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

HydrationProgress.propTypes = {
  hydrationScore: PropTypes.any.isRequired
}
