import React, { useState, useEffect } from 'react'
import GroupCount from './GroupCount'
import TotalVolume from './GroupTotalVolume'
import GroupAverages from './GroupAverages'
import LeaderBoard from './LeaderBoard'
import { searchService } from '../../services/searchService'
import PageHeader from '../PageHeader'
import UserJoinDates from './UserJoinDates'
import { authService } from '../../services/authService'
import { useParams } from 'react-router-dom'
import CreateGroup from './CreateGroup'
import ReactGA from 'react-ga4'

const HomePage = () => {
  const { groupId } = useParams()
  const hidrateAdminMode = groupId === (process.env.GROUP_ALL_USERS ?? '0JZgXuP44a')
  const [totalState, setTotalState] = useState({ volume: 0 })
  const [averageState, setAverageState] = useState({ averages: [] })

  const [prefs, setPrefs] = useState({})

  useEffect(() => {
    console.log(window.location.pathname)
    ReactGA.send({
      hitType: 'pageview',
      page: window?.location?.pathname ?? '',
      title: 'Home Page'
    })
    return authService.listenToUserPrefs(setPrefs)
  }, [])

  useEffect(() => {
    if (!hidrateAdminMode) {
      const abortController = new window.AbortController()

      const fetchData = async () => {
        const promises = []
        promises.push(searchService.getTotalVolume(groupId, abortController.signal))
        promises.push(searchService.getGroupAverages(groupId, abortController.signal))
        const results = await Promise.all(promises)

        setTotalState(results[0])
        setAverageState(results[1])
      }

      const syncFetchData = () => {
        fetchData()
        return () => {
          abortController.abort()
        }
      }
      return syncFetchData()
    }
  }, [groupId])

  const message = averageState.message || totalState.message
  if (message) {
    return (
      <div>
        <PageHeader title='Dashboard' subtitleSlogan />
        <div className='text-center'>
          <h1 className='text-3xl font-bold'>{message}</h1>
        </div>
      </div>
    )
  } else {
    return (
      <div className='h-full'>
        <PageHeader title='Dashboard' subtitleSlogan />
        <div className='text-center'>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
            {!hidrateAdminMode &&
              <>
                <GroupCount groupId={groupId} />
                <GroupAverages groupAverages={averageState.averages} groupId={groupId} />
                <TotalVolume totalWater={totalState.volume} units={prefs.measurementSystem} />

                <div className='col-span-full overflow-x-auto'>
                  <LeaderBoard groupId={groupId} units={prefs.measurementSystem} />
                </div>

                <div className='col-span-full'>
                  <UserJoinDates groupId={groupId} />
                </div>
              </>}
            {hidrateAdminMode &&
              <>
                <div className='col-span-full'>
                  <CreateGroup />
                </div>
              </>}
          </div>
        </div>
      </div>
    )
  }
}

export default HomePage
