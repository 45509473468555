import React from 'react'
import './Tile.css'

export function TileGrid ({ children, ...props }) {
  return (
    <div className='hst-tileGrid' {...props}>
      {children}
    </div>
  )
}

export function TileRow ({ children, ...props }) {
  return (
    <div className='hst-tileRow' {...props}>
      {children}
    </div>
  )
}

export function DualTile ({ flip, img, alt, title, children }) {
  return (
    <TileRow>
      <Tile className={'hst-desktop' + (flip ? ' hst-dualTileFlip' : '')} img={img} alt={alt} />
      <Tile title={title} imgMobile={img.replace('.', '-m.')} alt={alt}>{children}</Tile>
    </TileRow>
  )
}

export default function Tile ({ center, flat, superTitle, title, img, alt, imgMobile, className = '', children, ...props }) {
  if (img) {
    return <img className={'hst-tile ' + (flat ? 'hst-tileFlat ' : '') + className} src={'/assets/' + img} alt={alt} {...props} />
  }

  return (
    <div className={'hst-tile ' + (center ? 'hst-tileCenter ' : '') + (flat ? 'hst-tileFlat ' : '') + className} {...props}>
      {imgMobile && <img className='hst-imgMobile hst-mobile' src={'/assets/' + imgMobile} alt={alt} />}
      <div className='hst-tileContent'>
        {superTitle && <span className='hst-tileSuper'>{superTitle}</span>}
        {title && <h3>{title}</h3>}
        {Array.isArray(children)
          ? (
              children.map(child => {
                if (typeof child === 'string') {
                  return <p key={child.slice(0, 32)}>{child}</p>
                }
                return child
              })
            )
          : (typeof children === 'string') ? <p>{children}</p> : children}
      </div>
    </div>
  )
}
