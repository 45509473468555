import React, { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { authService } from '../services/authService'
import { useNavigate } from 'react-router-dom'

export const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [error, setError] = useState(null)

  const [groupId, setGroupId] = useState('')
  const [groups, setGroups] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal

    try {
      const user = authService.getUser()

      if (user) {
        authService.refreshUserDetails(null, signal)
        authService.refreshUserPrefs(null, signal)
        const groupId = authService.getGroup()
        const groups = authService.getGroups()
        setUser(user)
        setGroupId(groupId)
        setGroups(groups)
      }
    } catch (e) {
      console.log(e)
    }

    return () => {}
  }, [])

  const login = async ({ username, password, signal }) => {
    try {
      const user = await authService.login(username, password, signal)
      const groupId = authService.getGroup()
      const groups = authService.getGroups()
      await authService.fetchPermissions(groupId, signal)
      const startPage = authService.getStartPage(groupId, user.id)

      setGroups(groups)
      setGroupId(groupId)
      setUser(user)

      navigate(startPage)
    } catch (e) {

      if (e?.response?.status === 402) {
        setError('The subscription for this group has expired')
      } else {
        setError(e)
      }
    }
  }

  const logout = () => {
    authService.logout()
  }

  const signup = async ({ password, email, name }) => {
    try {
      const res = await authService.signup({ email, password, name })
      const responseJson = await res.json()
      if (res.ok) {
        await login({ username: email, password })
      } else {
        setError(responseJson.message)
      }
    } catch (e) {
      setError(e)
    }
  }

  return (
    <AuthContext.Provider value={{
      user,
      error,
      groupId,
      groups,
      login,
      logout,
      signup
    }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.any
}
export default AuthProvider
