import React from 'react'

const ActivityLevelSelect = ({ userParams, setUserParams }) => {
  const activityLevelOptions = ['1. Not active', '2. Slightly active', '3. Moderately active', '4. Active', '5. Very active']

  return (
    <div>
      <label className='label'>
        <span className='label-text'>Activity Level</span>
      </label>
      <select
        value={userParams.activityLevel}
        onChange={(e) => setUserParams((prev) => ({ ...prev, activityLevel: e.target.value }))}
        className='select select-bordered w-full'
      >
        {activityLevelOptions.map((opt, idx) => (
          <option key={opt} value={idx}>{opt}</option>))}
      </select>
    </div>
  )
}

export default ActivityLevelSelect
