import React, { useState } from 'react'
import { authService } from '../../services/authService'
import FilterHeader from '../global/FilterHeader'
import { notificationsService } from '../../services/notificationsService'
import toast from 'react-hot-toast'
import HidrateCard from '../HidrateCard'
import { XCircleIcon } from '@heroicons/react/24/outline'

const NotificationsList = ({
  groupId,
  notifications,
  setView,
  msgApprovals,
  setMsgApprovals,
  fetchNotifications
}) => {
  const [filters, setFilters] = useState(['all', 'pending', 'scheduled', 'active', 'sent'])
  const [filter, setFilter] = useState('all')
  const [cancelLoading, setCancelLoading] = useState(false)

  const permissions = authService.getPermissions(groupId)

  const notifs = {
    all: []
  }
  const notifCounts = {}
  notifications.forEach(notif => {
    notifs.all.push(notif)
    const key = notif.status
    notifCounts[key] = (notifCounts[key] || 0) + 1
    if (!notifs[key]) {
      notifs[key] = [notif]
      if (!filters.includes(key)) {
        setFilters([...filters, key])
      }
    } else {
      notifs[key].push(notif)
    }
  })

  const handleDeleteNotification = async (notification) => {
    const abortController = new window.AbortController()
    try {
      setCancelLoading(true)
      await notificationsService.markScheduledNotificationsForDelete(notification.objectId, groupId)
      await fetchNotifications(abortController.signal)
      setCancelLoading(false)
      toast.success('Successfully canceled scheduled notification!')
    } catch (e) {
      console.log(e)
      toast.error(e.message)
    }
  }

  return (
    // <Paper sx={{ padding: '24px' }}>
    <HidrateCard>
      <FilterHeader
        inline
        filters={[{
          title: 'Filter by',
          current: filter,
          onChange: setFilter,
          options: [
            { id: 'all', keep: true },
            { id: 'pending' },
            { id: 'scheduled', keep: true },
            { id: 'active', title: 'ongoing' },
            { id: 'sent', keep: true }
          ],
          prune: true,
          counts: notifCounts
        }]}
        canAdd={permissions.check('notif', 'editor')}
        addText='Create a Notification'
        onAdd={() => setView('new')}
      />
      <div className='overflow-x-auto'>
        <table className='table table-auto'>
          {/* head */}
          <thead>
            <tr>
              <th>Status</th>
              <th>Message</th>
              <th>Deliveries</th>
              <th align='right'>Audience</th>
              {/* <th /> */}
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {notifs[filter] && notifs[filter].map(notif => (
              <tr key={notif.objectId}>
                <td>
                  <div className={`badge ${['active', 'scheduled'].includes(notif.status)
                      ? 'badge-primary'
                      : 'badge-neutral'}`}
                  >
                    {notif.status
                      ? notif.status[0].toUpperCase() + notif.status.slice(1)
                      : 'Unknown'}
                  </div>
                </td>
                <td className='min-w-[12rem] max-w-[20rem] whitespace-normal'>{notif.text || '—'}</td>
                <td className='min-w-[12rem] max-w-[20rem] whitespace-normal'>                  {notif.nextDelivery
                  ? `Next: ${notif.nextDelivery.format('ddd, MMM D')} (${notif.nextDelivery.fromNow()})`
                  : notif.lastDelivery
                    ? `Sent ${notif.pastDeliveries.length} times (latest: ${notif.lastDelivery.fromNow()})`
                    : '—'}
                </td>
                <td align='right'>
                  {notif.audienceCount
                    ? notif.audienceCount + ' users'
                    : 'None'}
                </td>
                <td>
                  {permissions.check('notif', 'publisher') &&
                !(notif.objectId in msgApprovals ? msgApprovals[notif.objectId] : notif.enabled) &&
                  <button
                    className='btn btn-xs btn-primary btn-outline'
                    onClick={() => {
                      notificationsService.publishNotification(groupId, notif.objectId, true).then(() => {
                        setMsgApprovals(obj => ({ ...obj, [notif.objectId]: true }))
                      }).catch(err => {
                        window.alert('Error approving message. ' + err)
                      })
                    }}
                  >
                    Approve
                  </button>}
                </td>
                <td>
                  {notif.status === 'scheduled' && (
                    <button
                      disabled={cancelLoading}
                      aria-label='delete'
                      onClick={() => {
                        handleDeleteNotification(notif)
                      }}
                    >
                      <XCircleIcon height={25} width={25} />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </HidrateCard>
  )
}

export default NotificationsList
