import React, { useEffect, useState } from 'react'
import EditStepContent from './EditStepContent'
import EditStepReview from './EditStepReview'
import EditStepSchedule from './EditStepSchedule'
import EditStepTarget from './EditStepTarget'
import StepFlow from '../templates/StepFlow'
import moment from 'moment'
import { adService } from '../../services/advertisementService'
import { getSubgroups, useGroup } from '../global/groupInfo'
import { useNavigate, useParams } from 'react-router-dom'
import ReactGA from 'react-ga4'

const steps = [{
  component: EditStepContent,
  id: 'content',
  title: 'Choose Ad Location',
  shortTitle: 'Content',
  buttons: true
}, {
  component: EditStepTarget,
  id: 'target',
  title: 'Choose Audience',
  shortTitle: 'Audience',
  buttons: true
}, {
  component: EditStepSchedule,
  id: 'schedule',
  title: 'Set Schedule',
  shortTitle: 'Schedule',
  buttons: true
}, {
  component: EditStepReview,
  id: 'review',
  shortTitle: 'Submit'
}]

const defaultDate = moment().utc(true).startOf('day').add(12, 'hours')

const AdCreate = () => {
  const groupInfo = useGroup()
  const { groupId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window?.location?.pathname ?? '',
      title: 'Ad Create Page'
    })
  }, [])

  const subgroups = getSubgroups(groupInfo.root.id)
  let filteredSteps = steps
  if (subgroups.length === 0) {
    filteredSteps = steps.filter(step => step.id !== 'target')
  }

  const [ad, setAd] = useState({
    location: 'home',
    image: null,
    icon: 'https://hidrate-images.s3.amazonaws.com/ads/new.png',
    imageDescription: '',
    link: '',
    displayWeight: 0,
    startDate: moment(defaultDate).add(7, 'days'),
    endDate: moment(defaultDate).add(14, 'days'),
    useEndDate: false,
    targetGroup: groupInfo.hasParent ? groupInfo.id : 'ROOT'
  })

  const modifyAd = (obj) => {
    setAd(ad => ({ ...ad, ...obj }))
  }

  const submit = async () => {
    await adService.createNewAdvertisement({
      groupId: ad.targetGroup === 'ROOT' ? groupInfo.root.id : ad.targetGroup,
      destinationUrl: ad.link,
      startDate: ad.startDate,
      stopDate: ad.useEndDate ? ad.endDate : undefined,
      accessibilityText: ad.imageDescription,
      adLocation: ad.location,
      displayWeight: parseFloat(ad.displayWeight) || 0,
      imagePayload: ad.image,
      hostedBannerImageUrl: ad.icon
    })
  }

  const cancel = () => {
    navigate(-1)
  }

  return (
    <StepFlow
      title='Create an Advertisement'
      allSteps={filteredSteps}
      data={ad}
      modifyData={modifyAd}
      onSubmit={submit}
      onCancel={cancel}
      {...{ groupId }}
    />
  )
}
export default AdCreate
