import React, { forwardRef } from 'react'

const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
  <button
    className='input input-bordered w-full text-start'
    onClick={onClick}
    ref={ref}
  >
    {value}
  </button>
))

export default DatePickerCustomInput
