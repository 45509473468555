import React from 'react'

const GoalLine = (classes, height, width, highest, isCurrentMonth, barWidth, data, transform, lineOnly) => {
  const graphHeight = height * 0.90
  const graphWidth = width - 60

  const days = data
  const barWidthPixels = (graphWidth * (barWidth / 100))

  let pointLine = ''

  const monthLength = days.length && isCurrentMonth ? new Date().getDate() : days.length

  for (let i = 0; i < monthLength; i++) {
    const day = days[i]
    const goalPercent = transform(day.goal, highest) / 100
    const goalPixels = graphHeight - (graphHeight * goalPercent)
    const offset = 60
    const firstLinePoint = (i + 0.25) * barWidthPixels + offset

    pointLine = pointLine.concat(`\n${firstLinePoint},${goalPixels}`)
  }

  // if (monthLength) {
  //   const lastDay = days[monthLength - 1]
  //   const lastGoalPixels = graphHeight - (graphHeight * transform(lastDay.goal, highest) / 100)
  //   pointLine = pointLine.concat(`\n${barWidthPixels + (barWidthPixels * monthLength)},${lastGoalPixels}`)
  // }

  return <polyline fill='none' className={lineOnly ? classes.goalLineOnly : classes.goalLine} points={pointLine} />
}

export default GoalLine
