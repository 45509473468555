import axios from 'axios'
import { updateGroupCache } from '../components/global/groupInfo'
const config = { apiUrl: process.env.REACT_APP_API_URL }
const authHeader = require('./authHeader')

function getSubgroups (groupId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader.authHeader()
  }

  return window.fetch(`${config.apiUrl}group/${groupId}/subgroups`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response
    })
}

function getSubgroupsMembers (groupId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader.authHeader()
  }

  return window.fetch(`${config.apiUrl}group/${groupId}/subgroups/members`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response
    })
}

function refreshGroupCache (groupId) {
  getSubgroups(groupId).then(res => {
    const groups = {}
    res.users.forEach(group => {
      groups[group.id] = {
        id: group.id,
        name: group.name,
        parent: groupId
      }
    })
    updateGroupCache(groups)
  }).catch(err => console.warn('failed to refresh group cache: ', err))
}

// function editSubgroupMembers (groupId, subgroupId, data) {
//   const requestOptions = {
//     method: 'PUT',
//     headers: authHeader.authHeader(),
//     body: JSON.stringify(data)
//   }

//   return window.fetch(`${config.apiUrl}group/${groupId}/subgroups/${subgroupId}/users`, requestOptions)
//     .then(handleResponse)
//     .then((response) => {
//       return response
//     })
// }

function createSubgroup (groupId, data) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.post(`${config.apiUrl}group/${groupId}/subgroups`, data, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function deleteSubgroup (groupId, subGroupId) {
  if (subGroupId && typeof subGroupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.delete(`${config.apiUrl}group/${groupId}/subgroups/${subGroupId}`, options)
      .then((response) => {
        return response?.data?.message
      }).catch((err) => {
        console.log(err)
        return 'Error deleting subgroup'
      })
  } else {
    throw new Error('No subgroup ID')
  }
}

function editSubgroup (groupId, subgroupId, data) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.put(`${config.apiUrl}group/${groupId}/subgroups/${subgroupId}`, data, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function editSubgroupMembers (groupId, subgroupId, data) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.put(`${config.apiUrl}group/${groupId}/subgroups/${subgroupId}/users`, data, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}

export const subgroupService = {
  getSubgroups,
  getSubgroupsMembers,
  refreshGroupCache,
  createSubgroup,
  deleteSubgroup,
  editSubgroup,
  editSubgroupMembers
}
