import React, { forwardRef } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import PropTypes from 'prop-types'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function GoLiveWarning ({ open, startDate, endDate, goLive, cancel }) {
  const now = new Date()

  const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
  const getMessageString = () => {
    if (now < startDate) {
      return `It will be viewable by users when the start date is reached on ${startDate.toLocaleDateString('en-US', dateOptions)}.`
    } else if (now > endDate) {
      return 'It will not be viewable by users since the stop date has already passed.'
    } else {
      return 'It will be shown to users immediately.'
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={cancel}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>Set this ad to be live?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {`Are you sure you want to set this ad to be live? ${getMessageString()}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color='primary'>
            Cancel
          </Button>
          <Button onClick={goLive} color='primary'>
            Go Live
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

GoLiveWarning.propTypes = {
  open: PropTypes.bool.isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  goLive: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
}

export default (GoLiveWarning)
