import React, { useContext } from 'react'
import { useNavigate, useRoutes } from 'react-router-dom'
import UserList from '../users/UserList'
import IndividualUser from '../individualUser/IndividualUser'
// import Scores from '../scores/Scores'
import NotificationsPage from '../notifications/NotificationsPage'
import AdvertisementsManager from '../advertisements/AdvertisementManager'
import AdCreate from '../advertisements/AdCreate'
import HomePage from '../homePage/HomePage'
import ChallengesList from '../challenges/ChallengesList'
import ChallengeCreate from '../challenges/ChallengeCreate'
import ChallengeEdit from '../challenges/ChallengeEdit'
import ChallengeDetails from '../challenges/ChallengeDetails'
import NavFrame from './NavFrame'
import { AuthContext } from '../../context/AuthContext'

const routes = [
  {
    path: ':groupId/home',
    element: <HomePage />
  },
  {
    path: ':groupId/users',
    element: <UserList />
  },
  {
    path: ':parentId/subgroup/:groupId/users',
    element: <UserList />
  },
  {
    path: ':groupId/notifications',
    element: <NotificationsPage />
  },
  {
    path: ':groupId/advertisements',
    element: <AdvertisementsManager />
  },
  {
    path: ':parentId/subgroup/:groupId/advertisements',
    element: <AdvertisementsManager />
  },
  {
    path: ':groupId/advertisements/create',
    element: <AdCreate />
  },
  {
    path: ':parentId/subgroup/:groupId/advertisements/create',
    element: <AdCreate />
  },
  {
    path: ':groupId/challenges',
    element: <ChallengesList />
  },
  {
    path: ':groupId/challenges/create',
    element: <ChallengeCreate />
  },
  {
    path: ':groupId/challenges/:challengeId/edit',
    element: <ChallengeEdit />
  },
  {
    path: ':groupId/challenges/:challengeId',
    element: <ChallengeDetails />
  },
  {
    path: ':groupId/individual/:userId',
    element: <IndividualUser />
  }
  // {
  //   path: ':groupId/scores',
  //   element: <Scores />
  // }
]

const HealthDashboard = () => {
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()
  const routeResult = useRoutes(routes)

  return (
    <NavFrame
      user={authContext.user}
      navigate={navigate}
      logOut={authContext.logout}
      groupId={authContext.groupId}
      groups={authContext.groups}
    >
      {routeResult}
    </NavFrame>
  )
}

export default HealthDashboard
