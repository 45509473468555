import React from 'react'

const LightNotificationCountSelect = ({ userParams, setUserParams }) => {
  const options = new Array(12 + 1).fill()

  return (
    <div>
      <label className='label'>
        <span className='label-text'>Number of glow reminders</span>
      </label>
      <select
        value={userParams.lightNotificationCount}
        onChange={(e) => setUserParams((prev) => ({ ...prev, lightNotificationCount: parseInt(e.target.value) }))}
        className='select select-bordered w-full'
      >
        {options.map((_, idx) => (
          <option key={idx} value={idx}>{idx}</option>))}
      </select>
    </div>
  )
}

export default LightNotificationCountSelect
