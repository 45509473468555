import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Grid, Typography, FormControl, Select, MenuItem, TextField } from '@mui/material'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import moment from 'moment'

const styles = () => ({
  once: {
    margin: '32px 0',
    display: 'flex',
    justifyContent: 'center'
  },
  formLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  divider: {
    border: 'none',
    borderTop: '1px solid #ddd',
    margin: '2em'
  }
})

const standardizeDate = date => moment(date).utc(true).startOf('day').add(12, 'hours')

const EditStep = ({ classes, data: challenge, modifyData: modifyChallenge }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <>
        {challenge.privacy !== 'PRIVATE' &&
          <>
            <Grid container>
              <Grid item xs={12} md={3} lg={2} className={classes.formLabel}>
                <Typography variant='h4'>Show In App</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl variant='outlined' className={classes.formPart}>
                  <Select
                    value={challenge.visibilityDate ? 'date' : 'immediate'}
                    onChange={event => {
                      modifyChallenge({ visibilityDate: event.target.value === 'date' ? standardizeDate().add(1, 'day') : null })
                    }}
                  >
                    <MenuItem value='immediate'>Immediately</MenuItem>
                    <MenuItem value='date'>Starting from date</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {challenge.visibilityDate &&
                <Grid item xs={12} md={4}>
                  <DatePicker
                    autoOk
                    disableToolbar
                    disablePast
                    variant='inline'
                    inputVariant='outlined'
                    format='MM/dd/yyyy'
                    renderInput={(params) => <TextField {...params} />}
                    InputAdornmentProps={{ position: 'start' }}
                    value={challenge.visibilityDate || standardizeDate().add(1, 'day')}
                    onChange={value => {
                      const mod = { visibilityDate: standardizeDate(value) }
                      if (mod.visibilityDate.isSameOrAfter(challenge.startDate)) {
                        mod.visibilityDate = moment(challenge.startDate)
                      }
                      modifyChallenge(mod)
                    }}
                  />
                </Grid>}
            </Grid>
            <p>
              {challenge.visibilityDate
                ? `The challenge will be visible to ${challenge.targetGroup === 'ROOT' ? 'group' : 'the selected subgroup'} members starting on ${challenge.visibilityDate.format('MMMM D')} (${challenge.visibilityDate.fromNow()}).`
                : `The challenge will be visible to ${challenge.targetGroup === 'ROOT' ? 'group' : 'the selected subgroup'} members as soon as it's approved.`}
            </p>
            <hr className={classes.divider} />
          </>}

        <Grid container>
          <Grid item xs={12} md={3} lg={2} className={classes.formLabel}>
            <Typography variant='h4'>Start Date</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <DatePicker
              autoOk
              disableToolbar
              disablePast
              variant='inline'
              inputVariant='outlined'
              format='MM/dd/yyyy'
              renderInput={(params) => <TextField {...params} />}
              InputAdornmentProps={{ position: 'start' }}
              value={challenge.startDate || standardizeDate().add(1, 'day')}
              onChange={value => {
                const mod = { startDate: standardizeDate(value) }
                if (mod.startDate.isSameOrAfter(challenge.endDate)) {
                  mod.endDate = moment(mod.startDate)
                }
                modifyChallenge(mod)
              }}
            />
          </Grid>
        </Grid>
        <p>The challenge will start on {challenge.startDate.format('MMMM D')} in each person’s local time ({challenge.startDate.fromNow()}).</p>
        {challenge.goal === 'DATE' &&
          <>
            <hr className={classes.divider} />
            <Grid container>
              <Grid item xs={12} md={3} lg={2} className={classes.formLabel}>
                <Typography variant='h4'>End Date</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <DatePicker
                  autoOk
                  disableToolbar
                  disablePast
                  variant='inline'
                  inputVariant='outlined'
                  format='MM/dd/yyyy'
                  renderInput={(params) => <TextField {...params} />}
                  InputAdornmentProps={{ position: 'start' }}
                  value={challenge.endDate || standardizeDate().add(29, 'days')}
                  onChange={value => {
                    const mod = { endDate: standardizeDate(value) }
                    if (mod.endDate.isSameOrBefore(challenge.startDate)) {
                      mod.startDate = moment(mod.endDate)
                    }
                    modifyChallenge(mod)
                  }}
                />
              </Grid>
            </Grid>
            <p>The challenge will last {challenge.endDate.diff(challenge.startDate, 'days') + 1} days, and the team with the most points will be declared the winner after {challenge.endDate.format('MMMM D')} is past.</p>
          </>}
      </>
    </LocalizationProvider>
  )
}

EditStep.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  modifyData: PropTypes.func
}

export default withStyles(styles)(EditStep)
