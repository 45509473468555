import React, { useEffect, useReducer, useRef, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { AuthContext } from '../context/AuthContext'
import { Avatar, IconButton, Menu, MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'
import AutoComplete from './dashboard/AutoComplete'
import { userReducer } from '../reducers/userReducer'
import { searchActions } from '../actions/searchActions'
import SearchIcon from '@mui/icons-material/Search'
import CancelIcon from '@mui/icons-material/Cancel'
import GroupIcon from '@mui/icons-material/Group'
import { authService } from '../services/authService'
import SettingsDialog from './global/SettingsDialog'
import { Link, useNavigate } from 'react-router-dom'
import { getSubgroups, useGroup } from './global/groupInfo'

const MenuDivider = styled('hr')(() => ({
  border: 'none',
  borderBottom: '1px solid #ddd'
}))

const UserDetails = styled('div')(() => ({
  color: 'white',
  marginLeft: '.5em',
  '& span': {
    display: 'block',
    lineHeight: '1.3'
  }
}))

const UserName = styled('span')(() => ({
  fontWeight: 'bold',
  fontSize: '1.1em'
}))

const UserContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
}))

const SubGroupLabel = styled('span')(() => ({
  color: 'white',
  margin: '0 .5em 0 -.5em'
}))

const initialState = {
  search: {
    users: [],
    count: 0,
    searching: false
  },
  memberSubgroups: {}
}

const PageHeaderControls = ({ groups, groupId, groupInfo, onLogOut }) => {
  const [showSearch, setShowSearch] = useState(false)
  const [state, dispatch] = useReducer(userReducer, initialState)

  const navigate = useNavigate()

  const handleChangeFilter = (filter) => {
    console.log('SEARCH ACTION')
    searchActions.search(groupId, 0, 10, filter, false, dispatch)
  }

  const [userDetails, setUserDetails] = useState({})
  useEffect(() => {
    return authService.listenToUserDetails(setUserDetails)
  }, [])

  const currentGroupId = groupId === window.localStorage.getItem('groupId') ? groupId : window.localStorage.getItem('groupId')

  const currentGroup = groups.find(g => g.id === currentGroupId) || { name: 'you' }

  const subgroupMenuAnchor = useRef(null)
  const [showSubgroupMenu, setShowSubgroupMenu] = useState(false)
  const childSubgroups = groupInfo.hasParent ? getSubgroups(groupId) : []
  const rootSubgroups = getSubgroups(groupInfo.root.id)

  const userMenuAnchor = useRef(null)
  const [showUserMenu, setShowUserMenu] = useState(false)

  const [showSettings, setShowSettings] = useState(false)
  const [tempName, setTempName] = useState('')

  function updateName (name) {
    setTempName(name)
  }

  return (
    <>
      <div>
        {showSearch
          ? (
            <AutoComplete
              sx={{
                '& *': {
                  color: 'white'
                }
              }}
              groupId={groupId}
              id='outlined-search'
              searchSuggestions={handleChangeFilter}
              suggestions={state.search.users}
              navigate={navigate}
            />
            )
          : (
            <IconButton
              sx={{ color: 'white' }}
              onClick={() => setShowSearch(true)}
              size='large'
            >
              <SearchIcon />
            </IconButton>
            )}
      </div>

      {(rootSubgroups.length > 0 || childSubgroups.length > 0) &&
        <>
          <div onClick={() => setShowSubgroupMenu(true)}>
            <IconButton
              sx={{ color: 'white' }} ref={subgroupMenuAnchor} size='large'
            >
              <GroupIcon />
            </IconButton>
            {groupInfo.hasParent &&
              <SubGroupLabel>{groupInfo.group.name}</SubGroupLabel>}
          </div>
          <Menu
            anchorEl={subgroupMenuAnchor.current}
            open={showSubgroupMenu}
            onClose={() => setShowSubgroupMenu(false)}
          >
            <MenuItem
              sx={{ fontWeight: 'bold' }}
              selected={groupInfo.root.id === groupId}
              onClick={() => {
                authService.setGroup(groupInfo.root.id)
                navigate(`/dashboard/${groupInfo.root.id}/home`)
                setShowSubgroupMenu(false)
              }}
            >
              {groupInfo.root.name}
            </MenuItem>
            {rootSubgroups.length > 0 &&
            [
              childSubgroups.length > 0 &&
                <MenuItem key='root-label' disabled>Subgroups of {groupInfo.root.name || 'main group'}</MenuItem>,
              rootSubgroups.map(group => (
                <MenuItem
                  key={group.id}
                  selected={group.id === groupInfo.group.id}
                  onClick={() => {
                    authService.setGroup(group.id)
                    navigate(`/dashboard/${group.id}/home`)
                    setShowSubgroupMenu(false)
                  }}
                >
                  {group.name}
                </MenuItem>
              ))
            ]}
            {childSubgroups.length > 0 &&
            [
              <MenuDivider key='local-hr' />,
              <MenuItem key='local-label' disabled>Subgroups of {groupInfo.group.name || 'this subgroup'}</MenuItem>,
              childSubgroups.map(group => (
                <MenuItem
                  key={group.id}
                  // button
                  onClick={() => {
                    authService.setGroup(group.id)
                    navigate(`/dashboard/${group.id}/home`)
                    setShowSubgroupMenu(false)
                  }}
                >
                  {group.name}
                </MenuItem>
              ))
            ]}
          </Menu>
        </>}

      <UserContainer role='button' onClick={() => setShowUserMenu(v => !v)}>
        <Avatar
          sx={{
            color: 'primary.light',
            backgroundColor: 'white',
            height: '2.5em',
            width: '2.5em',
            border: '2px solid white',
            marginLeft: '.5em'
          }}
          src={userDetails.profileImage ? userDetails.profileImage.url : null}
          alt={userDetails.name}
        />
        <UserDetails>
          <UserName>{userDetails.name}</UserName>
          <span ref={userMenuAnchor}>{tempName || groupInfo.root.name || currentGroup.name}</span>
        </UserDetails>
      </UserContainer>
      <Menu
        anchorEl={userMenuAnchor.current}
        open={showUserMenu}
        onClose={() => setShowUserMenu(false)}
      >
        {groups.map(group => (
          <MenuItem
            key={group.id}
            // button
            onClick={() => {
              authService.setGroup(group.id)
              navigate(`/dashboard/${group.id}/home`)
              setShowUserMenu(false)
              updateName(group.name)
            }}
          >
            {group.name}
          </MenuItem>
        ))}
        <MenuDivider />
        <MenuItem
          onClick={() => {
            navigate(`/dashboard/${groupId}/individual/${userDetails.userId}`)
            setShowUserMenu(false)
          }}
        >
          My Profile
        </MenuItem>
        <MenuItem onClick={() => setShowSettings(true)}>Settings</MenuItem>
        <MenuItem onClick={onLogOut}>Logout</MenuItem>
      </Menu>

      <SettingsDialog open={showSettings} onClose={() => setShowSettings(false)} />
    </>
  )
}

PageHeaderControls.propTypes = {
  groups: PropTypes.array,
  groupId: PropTypes.string,
  groupInfo: PropTypes.object,
  onLogOut: PropTypes.func
}

const PageHeader = ({ title, subtitle, subtitleSlogan, hideSubgroupChip }) => {
  const groupInfo = useGroup()
  const authContext = useContext(AuthContext)

  let parentPath = window.location.pathname
  if (groupInfo.hasParent) {
    parentPath = parentPath.replace(groupInfo.group.id, groupInfo.parent.id)
  }

  return (
    <>
      <div className='py-5 flex items-center'>

        <div className='flex-grow'>
          <h1 className='text-3xl font-bold text-white'>
            {title || 'HidrateSpark+'}
          </h1>
          {subtitleSlogan
            ? (
              <h2 className='text-lg text-white'>
                Keeping {groupInfo.root.name || 'you'} hydrated
              </h2>)
            : subtitle
              ? <h2 className='text-lg text-white'>{subtitle}</h2>
              : <></>}

          {groupInfo.hasParent && !hideSubgroupChip &&
            <Link
              to={parentPath} sx={{
                textDecoration: 'none',
                background: 'primary.dark', // theme.palette.primary.dark,
                marginTop: 1,
                marginLeft: -1,
                width: 'fit-content',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                mixBlendMode: 'overlay',
                padding: '.3em',
                paddingRight: '1em',
                borderRadius: '4em',
                '& svg': {
                  marginRight: '.2em'
                }
              }} role='button'
            >
              <CancelIcon /> Showing subgroup: {groupInfo.group.name}
            </Link>}
        </div>
        <PageHeaderControls
          groups={authContext.groups}
          groupId={authContext.groupId}
          groupInfo={groupInfo}
          onLogOut={() => { authContext.logout() }}
        />
      </div>
    </>
  )
}

PageHeader.propTypes = {
  groups: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleSlogan: PropTypes.bool,
  hideSubgroupChip: PropTypes.bool
}

export default PageHeader
