import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { searchService } from '../../services/searchService'
import HidrateCard from '../HidrateCard'
import BarChart from '../graphing/BarChart'
import { subDays, format, subMonths, parse, isSameMonth, isSameWeek, subWeeks } from 'date-fns'

const UserJoinDates = ({ groupId }) => {
  const [groupBy, setGroupBy] = useState('week')
  const [allJoinDates, setAllJoinDates] = useState([])

  useEffect(() => {
    const abortController = new window.AbortController()

    const fetchData = async () => {
      try {
        const response = await searchService.getUserJoinDates(groupId, abortController.signal)
        if (response.result === 'bad') { throw new Error('Error fetching user join dates') }

        const { joinDates } = response
        setAllJoinDates(joinDates)
      } catch (err) {
        console.log(JSON.stringify(err, null, 2))
      }
    }

    fetchData()

    return () => {
      abortController.abort()
    }
  }, [groupId])

  const joinDateData = useMemo(() => {
    const today = new Date()
    const DAY_RANGE = 60
    const WEEK_RANGE = 30
    const MONTH_RANGE = 24

    switch (groupBy) {
      case 'day':
        return Array.from({ length: DAY_RANGE }, (_, idx) => {
          const date = subDays(today, DAY_RANGE - idx)
          const label = format(date, 'MMM, d, yyyy')
          const key = format(date, 'yyyy-MM-dd')
          const userCount = allJoinDates[key] || 0
          return { userCount, label }
        })
      case 'week':
        return Array.from({ length: WEEK_RANGE }, (_, idx) => {
          const date = subWeeks(today, WEEK_RANGE - idx)
          const label = format(date, 'MMM, d, yyyy')
          const allDateKeys = Object.keys(allJoinDates)
          let userCount = 0
          allDateKeys.forEach((dateKey) => {
            const thisDate = parse(dateKey, 'yyyy-MM-dd', today)
            if (isSameWeek(thisDate, date)) { userCount += allJoinDates[dateKey] }
          })
          return { userCount, label }
        })
      case 'month':
        return Array.from({ length: MONTH_RANGE }, (_, idx) => {
          const date = subMonths(today, MONTH_RANGE - idx)
          const label = format(date, 'MMMM, yyyy')
          const allDateKeys = Object.keys(allJoinDates)
          let userCount = 0
          allDateKeys.forEach((dateKey) => {
            const thisDate = parse(dateKey, 'yyyy-MM-dd', today)
            if (isSameMonth(thisDate, date)) { userCount += allJoinDates[dateKey] }
          })
          return { userCount, label }
        })
    }
  }, [groupBy, JSON.stringify(allJoinDates)])

  return (
    <HidrateCard>
      <div className='text-left'>
        <div className='flex items-start justify-between mb-5'>

          <h3 className='text-xl font-semibold'>Users Joining</h3>
          <select
            className='select select-bordered'
            value={groupBy}
            onChange={(e) => { setGroupBy(e.target.value) }}
          >
            <option value='day'>Day</option>
            <option value='week'>Week</option>
            <option value='month'>Month</option>
          </select>
        </div>
      </div>
      <BarChart joinData={joinDateData} />
    </HidrateCard>
  )
}

UserJoinDates.propTypes = {
  groupId: PropTypes.string.isRequired
}

export default UserJoinDates
