import React from 'react'
import PropTypes from 'prop-types'
import NavSide from './NavSide'
import bgImg from '../../resources/background.svg'
import Footer from '../Footer'

const NavFrame = ({ navigate, groupId, user, children }) => {
  return (
    <div className='flex-grow drawer lg:drawer-open'>
      <input id='layout-drawer' type='checkbox' className='drawer-toggle' />

      <div
        className='drawer-content overflow-x-auto flex flex-col p-5'
        style={{ background: `url(${bgImg}) no-repeat, #EEEEF3` }}
      >
        {children}
        <Footer />
      </div>

      <div className='drawer-side'>
        <label htmlFor='layout-drawer' aria-label='close sidebar' className='drawer-overlay' />
        <ul className='menu p-4 w-80 min-h-full bg-white drop-shadow-lg'>
          <NavSide
            navigate={navigate}
            groupId={groupId}
            user={user}
          />
        </ul>
      </div>
    </div>
  )
}

NavFrame.propTypes = {
  user: PropTypes.object,
  groupId: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
  children: PropTypes.any
}

export default NavFrame
