import { searchConstants } from '../constants/searchConstants'
import { searchService } from '../services/searchService'
import { alertActions } from './alertActions'

const search = function (groupId, page, rowsPerPage, filter, full, dispatch) {
  dispatch(request({ groupId }))

  return searchService.searchUsers(groupId, {
    page,
    limit: rowsPerPage,
    filter,
    fullSearch: full
  }).then(
    users => {
      dispatch(success(users))
    },
    error => {
      dispatch(failure(error.toString()))
      dispatch(alertActions.error(error.toString()))
    }
  )

  function request (group) { return { type: searchConstants.DOMAIN_REQUEST, group } }
  function success (users) { return { type: searchConstants.DOMAIN_SUCCESS, users } }
  function failure (error) { return { type: searchConstants.DOMAIN_FAILURE, error } }
}

const getGroups = function (dispatch) {
  dispatch(request({}))

  return searchService.getGroups()
    .then(
      users => {
        dispatch(success(users))
      },
      error => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )

  function request (group) { return { type: searchConstants.GROUP_REQUEST, group } }
  function success (groups) { return { type: searchConstants.GROUP_SUCCESS, groups } }
  function failure (error) { return { type: searchConstants.GROUP_FAILURE, error } }
}

const getGroupCounts = function (groupId, dispatch) {
  dispatch(request(groupId))

  return searchService.getGroupCounts(groupId)
    .then(
      subgroups => {
        dispatch(success(subgroups))
      },
      error => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )

  function request (memberId) { return { type: searchConstants.SUB_GROUP_REQUEST, memberId } }
  function success (groupCounts) { return { type: searchConstants.SUB_GROUP_SUCCESS, groupCounts } }
  function failure (error) { return { type: searchConstants.SUB_GROUP_FAILURE, error } }
}

const getGroupAverages = function (groupId, dispatch) {
  dispatch(request(groupId))

  return searchService.getGroupAverages(groupId)
    .then(
      averages => {
        dispatch(success(averages))
      },
      error => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )

  function request (memberId) { return { type: searchConstants.GROUP_AVERAGE_REQUEST, memberId } }
  function success (averages) { return { type: searchConstants.GROUP_AVERAGE_SUCCESS, averages } }
  function failure (error) { return { type: searchConstants.GROUP_AVERAGE_FAILURE, error } }
}

const getLeaderboard = function (groupId, sort, dispatch) {
  dispatch(request(groupId))
  return searchService.getLeaderboard(groupId, sort)
    .then(
      leaderUsers => {
        dispatch(success(leaderUsers))
      },
      error => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )

  function request (groupId) { return { type: searchConstants.LEADER_REQUEST, groupId } }
  function success (leaderUsers) { return { type: searchConstants.LEADER_SUCCESS, leaderUsers } }
  function failure (error) { return { type: searchConstants.LEADER_FAILURE, error } }
}

const getTotalVolume = function (groupId, dispatch) {
  dispatch(request(groupId))

  return searchService.getTotalVolume(groupId)
    .then(
      total => {
        dispatch(success(total))
      },
      error => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )

  function request (memberId) { return { type: searchConstants.TOTAL_VOLUME_REQUEST, memberId } }
  function success (totals) { return { type: searchConstants.TOTAL_VOLUME_SUCCESS, totals } }
  function failure (error) { return { type: searchConstants.TOTAL_VOLUME_FAILURE, error } }
}

const getSips = function (memberId, day, dispatch) {
  dispatch(request(day))

  return searchService.getSips(memberId, day)
    .then(
      sips => {
        dispatch(success(sips))
      },
      error => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )

  function request (dayKey) { return { type: searchConstants.MEMBER_REQUEST, dayKey } }
  function success (sips) { return { type: searchConstants.MEMBER_SUCCESS, sips } }
  function failure (error) { return { type: searchConstants.MEMBER_FAILURE, error } }
}

const getHardware = function (memberId, dispatch) {
  dispatch(request(memberId))

  return searchService.getHardware(memberId)
    .then(
      hardware => {
        dispatch(success(hardware))
      },
      error => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )

  function request (memberId) { return { type: searchConstants.HARDWARE_REQUEST, memberId } }
  function success (hardware) { return { type: searchConstants.HARDWARE_SUCCESS, hardware } }
  function failure (error) { return { type: searchConstants.HARDWARE_FAILURE, error } }
}

const getMonthHistory = function (memberId, month, year, dispatch) {
  const monthKey = `${month}-${year}`
  dispatch(request(monthKey))

  return searchService.getMonthHistory(memberId, monthKey)
    .then(
      month => {
        dispatch(success(month))
      },
      error => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )

  function request (monthKey) { return { type: searchConstants.MONTH_REQUEST, monthKey } }
  function success (month) { return { type: searchConstants.MONTH_SUCCESS, month } }
  function failure (error) { return { type: searchConstants.MONTH_FAILURE, error } }
}

export const searchActions = {
  search,
  getGroups,
  getGroupCounts,
  getSips,
  getMonthHistory,
  getTotalVolume,
  getGroupAverages,
  getHardware,
  getLeaderboard
}
