import React, { useEffect } from 'react'
import Autosuggest from 'react-autosuggest'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'
import Popper from '@mui/material/Popper'
import withStyles from '@mui/styles/withStyles'
import { PropTypes } from 'prop-types'

function renderInputComponent (inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node)
          inputRef(node)
        },
        classes: {
          input: classes.input
        }
      }}
      {...other}
    />
  )
}

function getSuggestionValue (suggestion) {
  return suggestion.email
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    minWidth: '16em',
    marginRight: theme.spacing(2)
  },
  container: {
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: theme.zIndex.popper,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 20,
    padding: 0,
    listStyleType: 'none',
    zIndex: theme.zIndex.popper
  },
  divider: {
    height: theme.spacing(2)
  }
})

const IntegrationAutosuggest = ({ groupId, searchSuggestions, suggestions, navigate, classes }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [state, setState] = React.useState({
    single: '',
    popper: ''
  })

  function renderSuggestion (suggestion, { isHighlighted }) {
    return (
      <MenuItem
        selected={isHighlighted}
        component='div'
        onClick={() => {
          navigate(`/dashboard/${groupId}/individual/${suggestion.id}`)
        }}
      >
        <div>
          <span key={suggestion.id}>
            {suggestion.email}
          </span>
        </div>
      </MenuItem>
    )
  }
  const [stateSuggestions, setSuggestions] = React.useState([])

  const handleSuggestionsFetchRequested = ({ value }) => {
    searchSuggestions(value)
  }

  useEffect(() => {
    setSuggestions(suggestions)
  }, [suggestions])

  const handleSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const handleChange = name => (event, { newValue }) => {
    setState({
      ...state,
      [name]: newValue
    })
  }

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion
  }

  return (
    <div className={classes.root}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          id: 'react-autosuggest-popper',
          label: 'Search',
          placeholder: 'Email',
          value: state.popper,
          onChange: handleChange('popper'),
          inputRef: node => {
            setAnchorEl(node)
          },
          InputLabelProps: {
            shrink: true
          }
        }}
        theme={{
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderSuggestionsContainer={options => (
          <Popper className={classes.suggestionsList} anchorEl={anchorEl} open={Boolean(options.children)}>
            <Paper
              square
              {...options.containerProps}
              style={{ width: anchorEl ? anchorEl.clientWidth : undefined }}
            >
              {options.children}
            </Paper>
          </Popper>
        )}
      />
    </div>
  )
}

IntegrationAutosuggest.propTypes = {
  searchSuggestions: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired
}

export default withStyles(styles)(IntegrationAutosuggest)
