import React, { useContext, useEffect, useState } from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import logoImg from '../../resources/hidrate-health.svg'
import menuImg from '../../resources/menu.svg'
import './Navbar.css'
import Button from '@mui/material/Button'
import { AuthContext } from '../../context/AuthContext'
import { authService } from '../../services/authService'

export default function Navbar ({ ...props }) {
  const location = useLocation()
  const navigate = useNavigate()
  const { user, groupId } = useContext(AuthContext)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  const [showMenu, setShowMenu] = useState(false)

  return (
    <nav className='hst-navbar' {...props}>
      <div className='hst-navbarContents'>
        <div
          role='button'
          className='hst-navbarBurger hst-mobile'
          onClick={() => setShowMenu(v => !v)}
        >
          <img src={menuImg} alt='Menu' width='30' height='30' />
        </div>

        <Link to='/' className='hst-logo'>
          <img src={logoImg} alt='HidrateSpark+' />
        </Link>

        <div className='hst-desktop'>
          <div className='hst-navParent'>
            <Link to='/' className={'hst-navLink' + (location.pathname !== '/howitworks' && location.pathname !== '/login' ? ' active' : '')}>Teams</Link>
            <div className='hst-navDropdown'>
              <NavLink to='/overview'>Overview</NavLink>
              <NavLink to='/athletics'>Athletics</NavLink>
              <NavLink to='/business'>Business</NavLink>
              <NavLink to='/healthcare'>Healthcare</NavLink>
              <NavLink to='/government'>Government</NavLink>
              <NavLink to='/challenges'>Challenges</NavLink>
            </div>
          </div>
          <NavLink to='/howitworks' className={'hst-navLink' + (location.pathname === '/howitworks' ? ' active' : '')}>How It Works</NavLink>
          <a href='#getStarted' onClick={() => window.scroll(0, 9999999)} className='hst-navLink'>Contact Us</a>
        </div>

        <Button
          sx={{ marginLeft: 2, color: '#fff' }}
          variant='contained'
          onClick={() => {
            if (user && groupId) {
              const startPage = authService.getStartPage(groupId, user.id)
              navigate(startPage)
            } else {
              navigate('/login')
            }
          }}
        >
          Log in
        </Button>

        <Button
          sx={{ marginLeft: 2 }}
          variant=''
          onClick={() => { navigate('/signup') }}
        >
          Sign up
        </Button>
      </div>

      <div className={'hst-navMenuContainer hst-mobile' + (showMenu ? ' shown' : '')}>
        <div className='hst-navMenu' onClick={() => setShowMenu(false)}>
          <Link to='/'>HidrateSpark+ for Teams</Link>
          <NavLink to='/overview' className='hst-navIndent'>Overview</NavLink>
          <NavLink to='/athletics' className='hst-navIndent'>Athletics</NavLink>
          <NavLink to='/business' className='hst-navIndent'>Business</NavLink>
          <NavLink to='/healthcare' className='hst-navIndent'>Healthcare</NavLink>
          <NavLink to='/government' className='hst-navIndent'>Government</NavLink>
          <NavLink to='/challenges' className='hst-navIndent'>Challenges</NavLink>

          <NavLink to='/howitworks'>How It Works</NavLink>

          <div onClick={() => window.scrollTo(0, 9999999)}>Contact Us</div>
        </div>
      </div>
    </nav>
  )
}
