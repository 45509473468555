import { format } from 'date-fns'
import React from 'react'

const Footer = () => {
  return (
    <footer className='mt-5 footer footer-center text-base-content'>
      <aside>
        <p>Copyright © {format(new Date(), 'yyyy')} - All right reserved by Hidrate Spark</p>
      </aside>
    </footer>
  )
}

export default Footer
