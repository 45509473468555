import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, Typography } from '@mui/material'
import { authService } from '../../services/authService'

const styles = theme => ({
  dialogContent: {
    maxWidth: '80vw',
    width: '30em',
    minWidth: '320px'
  },
  select: {
    marginLeft: theme.spacing(1)
  }
})

const SettingsDialog = ({ classes, open, onClose }) => {
  const [prefs, setPrefs] = useState({ blank: true })

  useEffect(() => {
    return authService.listenToUserPrefs(setPrefs)
  }, [])

  // console.log('user settings:', prefs)

  const saveAndClose = async () => {
    onClose()
    await authService.setUserPrefs(prefs)
  }

  return (
    <Dialog onClose={saveAndClose} open={open}>
      <DialogTitle>
        Settings
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Typography variant='h4'>Units</Typography>
        Display liquid amounts in
        <Select
          value={prefs.measurementSystem || 'imperial'}
          onChange={event => setPrefs(obj => ({ ...obj, measurementSystem: event.target.value }))}
          className={classes.select}
        >
          <MenuItem value='imperial'>imperial (fl oz)</MenuItem>
          <MenuItem value='metric'>metric (ml)</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={saveAndClose} color='primary'>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SettingsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func
}

export default withStyles(styles)(SettingsDialog)
