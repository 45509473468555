export const searchConstants = {
  DOMAIN_REQUEST: 'DOMAIN_REQUEST',
  DOMAIN_SUCCESS: 'DOMAIN_SUCCESS',
  DOMAIN_FAILURE: 'DOMAIN_FAILURE',

  GROUP_REQUEST: 'GROUP_REQUEST',
  GROUP_SUCCESS: 'GROUP_SUCCESS',
  GROUP_FAILURE: 'GROUP_FAILURE',

  MEMBER_REQUEST: 'MEMBER_REQUEST',
  MEMBER_SUCCESS: 'MEMBER_SUCCESS',
  MEMBER_FAILURE: 'MEMBER_FAILURE',

  HARDWARE_REQUEST: 'HARDWARE_REQUEST',
  HARDWARE_SUCCESS: 'HARDWARE_SUCCESS',
  HARDWARE_FAILURE: 'HARDWARE_FAILURE',

  MONTH_REQUEST: 'MONTH_REQUEST',
  MONTH_SUCCESS: 'MONTH_SUCCESS',
  MONTH_FAILURE: 'MONTH_FAILURE',

  LEADER_REQUEST: 'LEADER_REQUEST',
  LEADER_SUCCESS: 'LEADER_SUCCESS',
  LEADER_FAILURE: 'LEADER_FAILURE',

  SUB_GROUP_REQUEST: 'SUB_GROUP_REQUEST',
  SUB_GROUP_SUCCESS: 'SUB_GROUP_SUCCESS',
  SUB_GROUP_FAILURE: 'SUB_GROUP_FAILURE',

  TOTAL_VOLUME_REQUEST: 'TOTAL_VOLUME_REQUEST',
  TOTAL_VOLUME_SUCCESS: 'TOTAL_VOLUME_SUCCESS',
  TOTAL_VOLUME_FAILURE: 'TOTAL_VOLUME_FAILURE',

  GROUP_AVERAGE_REQUEST: 'GROUP_AVERAGE_REQUEST',
  GROUP_AVERAGE_SUCCESS: 'GROUP_AVERAGE_SUCCESS',
  GROUP_AVERAGE_FAILURE: 'GROUP_AVERAGE_FAILURE'
}
