import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { Avatar, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { searchService } from '../../services/searchService'
// import { subgroupService } from '../../services/subgroupService'
import { authService } from '../../services/authService'
import { groupService } from '../../services/groupService'

const styles = theme => ({
  dialogContent: {
    maxWidth: '80vw',
    width: '30em',
    minWidth: '320px'
  },
  divider: {
    border: 'none',
    borderTop: '1px solid #ddd',
    margin: '1em 0'
  },
  smallCell: {
    width: '2em',
    height: '2em',
    padding: '0.25em'
  },
  userHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  addIcon: {
    height: '0.8em',
    width: '0.8em',
    color: '#444',
    marginRight: theme.spacing(1)
  },
  searchField: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    '& button': {
      marginLeft: theme.spacing(1)
    }
  },
  searchIcon: {
    color: '#888',
    marginRight: theme.spacing(1)
  },
  emptyMsg: {
    fontStyle: 'italic',
    color: '#888'
  },
  saveError: {
    padding: theme.spacing(2),
    paddingBottom: '0',
    textAlign: 'right',
    fontStyle: 'italic'
  }
})

const UserRow = ({ classes, user, canEdit, onRemove }) => {
  return (
    <TableRow>
      {canEdit &&
        <TableCell className={classes.smallCell}>
          <IconButton
            aria-label='remove'
            onClick={onRemove}
            size='large'
          >
            <RemoveCircleIcon />
          </IconButton>
        </TableCell>}
      <TableCell className={classes.smallCell}>
        <Avatar src={user.profile?.url || user.id.profile} alt={user.id.name} />
      </TableCell>
      <TableCell>
        {user.id.name}
      </TableCell>
    </TableRow>
  )
}

UserRow.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.any,
  canEdit: PropTypes.bool,
  inGroup: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func
}

const AddUserRow = ({ classes, user, canEdit, onAdd }) => {
  return (
    <TableRow>
      {canEdit &&
        <TableCell className={classes.smallCell}>

          <IconButton
            aria-label='add'
            onClick={onAdd}
            size='large'
          >
            <AddCircleOutlineIcon />
          </IconButton>

        </TableCell>}
      <TableCell className={classes.smallCell}>
        <Avatar src='' />
      </TableCell>
      <TableCell>
        {user.name}
      </TableCell>
    </TableRow>
  )
}

AddUserRow.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.any,
  canEdit: PropTypes.bool,
  inGroup: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func
}

const ClearButton = ({ onClick }) => {
  return (
    <InputAdornment position='end'>
      <IconButton
        aria-label='clear'
        onClick={onClick}
        onMouseDown={e => e.preventDefault()}
        size='large'
      >
        <ClearIcon />
      </IconButton>
    </InputAdornment>
  )
}

ClearButton.propTypes = {
  onClick: PropTypes.func
}

const MaingroupSettings = ({ classes, groupId, users, open, onClose, create = false }) => {
  const permissions = authService.usePermissions(groupId)
  const canEdit = permissions.check('group', 'editor')
  const canPublish = permissions.check('group', 'publisher')

  const [showSearch, setShowSearch] = useState(create)
  const [query, setQuery] = useState('')
  const [searchResults, setSearchResults] = useState(null)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [tempUserList, setTempUserList] = useState(users)

  useEffect(() => {
    setTempUserList(users)
  }, [users])

  const searchDebounce = useRef(null)

  const [details, setDetails] = useState({
    id: groupId
  })
  const [detailsChanged, setDetailsChanged] = useState(null)
  useEffect(() => {
    setDetailsChanged(val => val !== null)
    // ignore initial update by setting from null to false
  }, [details])
  const [toAdd, setToAdd] = useState([])
  const [toRemove, setToRemove] = useState([])

  const [loadingSave, setLoadingSave] = useState(false)
  const [saveError, setSaveError] = useState(null)

  const handleSaveSuccess = () => {
    onClose(true)
    setLoadingSave(false)
  }

  const handleSaveError = err => {
    console.error(err)
    setSaveError(err)
    setLoadingSave(false)
  }

  const cancelAndClose = () => {
    onClose(false)
    setQuery('')
    setShowSearch(false)
    setSearchResults(null)
    setToRemove([])
    setToAdd([])
    setTempUserList(users)
    setDetailsChanged(null)
    setDetails({
      id: groupId
    })
  }

  const saveAndClose = async () => {
    setLoadingSave(true)

    if (toRemove.length > 0 || toAdd.length > 0) {
      try {
        await groupService.updateGroupMembers({ groupId, toRemove, toAdd })
        handleSaveSuccess()
      } catch (err) {
        console.log(err)
        handleSaveError()
      }
    }
  }

  const searchForUser = async () => {
    const abortController = new window.AbortController()
    if (!query) {
      setSearchResults(null)
      setLoadingSearch(false)
      return
    }
    setLoadingSearch(true)
    const user = await searchService.findUserByUsername(groupId, query, abortController.signal)
    setSearchResults(user)
    setLoadingSearch(false)

    return () => {
      clearTimeout(searchDebounce.current)
    }
  }

  const fieldAdornment = (
    query.length === 0
      ? null
      : loadingSearch
        ? <div><CircularProgress className={classes.spinner} size={24} /></div>
        : <ClearButton onClick={() => setQuery('')} />
  )
  return (
    <Dialog onClose={cancelAndClose} open={open}>
      <DialogTitle>
        Manage Group
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Typography variant='h4'>Name</Typography>
        <TextField
          variant='outlined'
          fullWidth
          style={{ display: 'block', margin: '.5em 0 1em' }}
          disabled={!canPublish}
          placeholder='Name'
          value={details.name}
          onChange={e => setDetails(obj => ({ ...obj, name: e.target.value }))}
        />
        <Typography variant='h4'>Join Code</Typography>
        <TextField
          variant='outlined'
          style={{ display: 'block', margin: '.5em 0' }}
          disabled={!canPublish}
          placeholder='Join Code'
          value={details.joinCode}
          onChange={e => setDetails(obj => ({ ...obj, joinCode: e.target.value }))}
        />
        <Typography variant='body1'>Users can join this group in the app using the code above.</Typography>
        <hr className={classes.divider} />
        <div className={classes.userHeader}>
          <Typography variant='h4'>Users</Typography>
          {!showSearch && canEdit &&
            <Button className='my-2' onClick={() => setShowSearch(true)}>
              <AddCircleOutlineIcon className={classes.addIcon} /> Add Users...
            </Button>}
        </div>
        {showSearch && canEdit &&
          <div className={classes.searchField}>
            <TextField
              fullWidth
              variant='outlined'
              placeholder='Search by username...'
              value={query}
              onChange={e => {
                setQuery(e.target.value)
              }}
              InputProps={{
                startAdornment: <SearchIcon className={classes.searchIcon} />,
                endAdornment: fieldAdornment
              }}
            />
            <Button onClick={searchForUser}>
              <SearchIcon /> Search
            </Button>

            {!create &&
              <Button
                onClick={() => {
                  setShowSearch(false)
                  setQuery('')
                }}
              >
                Done
              </Button>}
          </div>}
        <Table className={classes.table}>
          <TableBody className={classes.body}>
            {searchResults !== null
              ? <AddUserRow
                  user={searchResults}
                  key={searchResults}
                  {...{ classes, searchResults, canEdit }}
                  onAdd={() => {
                    setToAdd(arr => [...arr, searchResults.id])
                  }}
                />
              : (
                // Loop through all users in group
                  tempUserList.map(u => ({ id: u })))?.map(user => (
                    <UserRow
                      key={user.id.id}
                      {...{ classes, user, canEdit }}
                      onRemove={() => {
                        setToRemove(arr => [...arr, user.id.id])
                        setTempUserList(arr => arr.filter(x => x.id !== user.id.id))
                      }}
                    />
                ))}
            {searchResults &&
              <TableRow>
                <TableCell colSpan={3} align='center' className={classes.emptyMsg}>
                  {searchResults ? 'No matching users found.' : "Nobody's here yet."}
                </TableCell>
              </TableRow>}
          </TableBody>
        </Table>
      </DialogContent>
      {saveError &&
        <div className={classes.saveError}>
          There was a problem saving this group. ({String(saveError)})
        </div>}
      <DialogActions>
        {loadingSave
          ? <CircularProgress size={37} />
          : create || detailsChanged || toAdd.length > 0 || toRemove.length > 0
            ? (
              <>
                <Button onClick={cancelAndClose} color='primary'>Cancel</Button>
                <Button onClick={saveAndClose} color='primary' disabled={toAdd.length === 0 && toRemove.length === 0}>Save</Button>
              </>
              )
            : <Button onClick={() => onClose(false)} color='primary'>Done</Button>}
      </DialogActions>
    </Dialog>
  )
}

MaingroupSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  groupId: PropTypes.any,
  users: PropTypes.any,
  open: PropTypes.any,
  onClose: PropTypes.any,
  subgroup: PropTypes.any,
  create: PropTypes.any
}

export default withStyles(styles)(MaingroupSettings)
