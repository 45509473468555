import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { adService } from '../../services/advertisementService'
import Typography from '@mui/material/Typography'
import PageHeader from '../PageHeader'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import UploadDialog from './UploadDialog'
import GoLiveWarning from './GoLiveWarning'
import { CardMedia, Grid, CircularProgress, Paper } from '@mui/material'
import moment from 'moment'
import { authService } from '../../services/authService'
import FilterHeader from '../global/FilterHeader'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import ReactGA from 'react-ga4'

const MainPanel = styled('div')(() => ({
  padding: 3
}))

const NoResults = styled('div')(() => ({
  padding: 2,
  textAlign: 'center',
  fontSize: '1.1em',
  fontStyle: 'italic',
  color: '#888'
}))

const LoadingContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  padding: 2
}))

const StatusContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  color: '#888'
}))

const AdItem = ({ ad, refresh, adLocation, groupId, permissions }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const startDate = new Date(Date.parse(ad.startDate))
  const endDate = new Date(Date.parse(ad.stopDate))

  let publishLabel
  let publishValue = true
  if (ad.visibility === 'DRAFT') {
    publishLabel = 'Publish'
  } else if (ad.visibility === 'PENDING') {
    if (permissions.check('ad', 'publisher')) {
      publishLabel = 'Approve'
    } else {
      publishLabel = 'Pending'
      publishValue = false
    }
  } else {
    publishLabel = 'Unpublish'
    publishValue = false
  }

  const handlePublish = publish => {
    if (open) {
      setOpen(false)
    } else if (publish && permissions.check('ad', 'publisher')) {
      setOpen(true)
      return
    }
    setLoading(true)
    adService.publishAdvertisement(groupId, ad.objectId, publish).then(() => {
      setLoading(false)
      refresh()
    })
  }

  let dateDisplay = ''
  if (ad.startDate && ad.stopDate) {
    dateDisplay = ad.startMoment.format('h:mm A, MMM D') + ' – ' + ad.stopMoment.format('h:mm A, MMM D, Y')
  } else if (ad.startDate) {
    dateDisplay = 'Starting ' + ad.startMoment.format('h:mm A, MMM D Y')
  } else if (ad.stopDate) {
    dateDisplay = 'Ending ' + ad.stopMoment.format('h:mm A, MMM D Y')
  } else {
    dateDisplay = 'No schedule set'
  }

  let url = ''
  if (ad.destinationUrl) {
    url = ad.destinationUrl.replace('https://', '').split('?')[0].replace(/\/$/, '')
  }

  return (
    <>
      <Card>
        <CardMedia component='img' image={ad.imageString} title={ad.accessibilityText} />
        <CardContent>
          <Typography variant='h5'>{ad.accessibilityText}</Typography>
          <Typography variant='body1'>{url}</Typography>
          <Typography variant='body1'>{dateDisplay}</Typography>
        </CardContent>
        <CardActions sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
        >
          {permissions.check('ad', 'editor') && <UploadDialog edit={ad} adLocation={adLocation} refresh={refresh} groupId={groupId} />}
          <StatusContainer>
            {ad.visibility[0] + ad.visibility.slice(1).toLowerCase()}
          </StatusContainer>
          {permissions.check('ad', 'editor') &&
            <Button onClick={() => handlePublish(publishValue)}>
              {loading ? <CircularProgress color='inherit' size='1.7em' /> : publishLabel}
            </Button>}
        </CardActions>
      </Card>

      <GoLiveWarning open={open} startDate={new Date(startDate)} endDate={new Date(endDate)} goLive={() => handlePublish(true)} cancel={() => setOpen(false)} />
    </>
  )
}

AdItem.propTypes = {
  ad: PropTypes.object.isRequired,
  refresh: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  permissions: PropTypes.object,
  adLocation: PropTypes.any
}

const AdvertisementManager = () => {
  const { groupId, parentId } = useParams()

  const [ads, setAds] = useState({})
  const [adCounts, setAdCounts] = useState({})
  const [message, setMessage] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [filterSlot, setFilterSlot] = useState('home')
  const [filterStatus, setFilterStatus] = useState('all')
  const [loading, setLoading] = useState(2)

  const permissions = authService.getPermissions(groupId)

  const triggerRefresh = () => {
    setRefresh(!refresh)
  }

  useEffect(() => {
    const abortController = new window.AbortController()

    const fetchData = async () => {
      setLoading(2)

      const processAds = res => {
        if (res.message) {
          setMessage(res.message)
          return []
        }
        const data = res.advertisements
        const counts = {}
        data.forEach(ad => {
          if (ad.startDate) ad.startMoment = moment(ad.startDate)
          if (ad.stopDate) ad.stopMoment = moment(ad.stopDate)
          if (ad.visibility === 'DRAFT') {
            ad.filterStatus = 'draft'
          } else if (ad.visibility === 'PENDING') {
            ad.filterStatus = 'pending'
          } else if (ad.startMoment.isAfter()) {
            ad.filterStatus = 'upcoming'
          } else if (!ad.stopMoment || ad.stopMoment.isAfter()) {
            ad.filterStatus = 'active'
          } else {
            ad.filterStatus = 'past'
          }
          counts.all = (counts.all || 0) + 1
          counts[ad.filterStatus] = (counts[ad.filterStatus] || 0) + 1
        })
        return { data, counts }
      }

      adService.getHomeAdvertisements(groupId, abortController.signal).then(res => {
        const processed = processAds(res)
        setAds(s => ({ ...s, home: processed.data }))
        setAdCounts(s => ({ ...s, home: processed.counts }))
        setLoading(v => v - 1)
      })
      adService.getBottlesAdvertisements(groupId, abortController.signal).then(res => {
        const processed = processAds(res)
        setAds(s => ({ ...s, bottles: processed.data }))
        setAdCounts(s => ({ ...s, bottles: processed.counts }))
        setLoading(v => v - 1)
      })
    }

    const syncFetch = () => {
      fetchData()

      return () => {
        abortController.abort()
      }
    }

    return syncFetch()
  }, [groupId, refresh])

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window?.location?.pathname ?? '',
      title: 'Advertisement Manager Page'
    })
  }, [])

  if (message) {
    return (
      <div>
        <PageHeader title='Advertisements' />
        <MainPanel>
          <Typography variant='h1'>{message}</Typography>
        </MainPanel>
      </div>
    )
  }

  const adsSlot = ads[filterSlot] || []
  const adsFiltered = filterStatus === 'all' ? adsSlot : adsSlot.filter(ad => ad.filterStatus === filterStatus)

  return (
    <div>
      <PageHeader title='Advertisements' {...{ groupId, parentId }} />
      <Paper sx={{ padding: 3 }}>
        <FilterHeader
          filters={[{
            title: 'Ad placement',
            current: filterSlot,
            onChange: setFilterSlot,
            options: [
              { id: 'home', title: 'Home' },
              { id: 'bottles', title: 'Bottles Tab' }
            ]
          }, {
            title: 'Filter by',
            current: filterStatus,
            onChange: setFilterStatus,
            options: [
              { id: 'all', keep: true },
              'draft',
              'pending',
              'upcoming',
              { id: 'active', keep: true },
              'past'
            ],
            prune: true,
            counts: adCounts[filterSlot] || {}
          }]}
          canAdd={permissions.check('ad', 'editor')}
          addText='Create an Ad'
          addLink='./create'
        />
        {loading > 0 &&
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>}
        {!loading && adsFiltered.length === 0 &&
          <NoResults>
            No advertisements here yet.
          </NoResults>}
        <Grid container spacing={2}>
          {adsFiltered.map(ad => (
            <Grid item md={4} key={ad.objectId + ad.debug}>
              <AdItem ad={ad} adLocation='home' refresh={triggerRefresh} groupId={groupId} permissions={permissions} />
            </Grid>
          ))}
        </Grid>

        <UploadDialog adLocation='home' refresh={triggerRefresh} groupId={groupId} />
      </Paper>
    </div>
  )
}

export default AdvertisementManager
