import React from 'react'
import DatePicker from 'react-datepicker'
import { format } from 'date-fns'

import 'react-datepicker/dist/react-datepicker.css'
import DatePickerCustomInput from '../../DatePickerCustomInput'

const PregnancyDueDateSelect = ({ userParams, setUserParams }) => {
  const today = new Date()
  const dueDate = userParams?.pregnancyDueDate ?? today

  return (
    <div>
      <label className='label'>
        <span className='label-text'>Pregnancy Due Date</span>
      </label>
      <DatePicker
        customInput={<DatePickerCustomInput />}
        wrapperClassName='w-full'
        selected={dueDate}
        value={format(dueDate, 'MMMM d, yyyy')}
        onChange={(date) => setUserParams((prev) => ({ ...prev, pregnancyDueDate: date }))}
        showPopperArrow={false}
        showMonthDropdown
        showYearDropdown
        popperModifiers={[
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport',
              tether: false,
              altAxis: true
            }
          }
        ]}
      />
    </div>
  )
}

export default PregnancyDueDateSelect
