import React from 'react'
import PropTypes from 'prop-types'

export const HydrationFactor = ({ factor, progress }) => {
  return (
    <div className='w-full px-10'>
      <p>{factor}</p>
      <progress className='progress progress-primary w-full' value={progress} max='100' />
    </div>
  )
}

HydrationFactor.propTypes = {
  factor: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired
}
