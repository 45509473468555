import React, { useState, useEffect } from 'react'
import { format, parse, subDays } from 'date-fns'

import MonthProgress from './MonthProgress'
import UserInfo from './UserInfo'
import PageHeader from '../PageHeader'
import DayStats from './DayStats'
import Trophies from './Trophies'
import { searchService } from '../../services/searchService'
import { authService } from '../../services/authService'
import DebugView from './DebugView'
import HydrationScore from './HydrationScore'
import { useParams } from 'react-router-dom'
import { getHydrationScore } from '../../utils/hydrationScore'
import LoadingFullScreen from '../LoadingFullScreen'

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import UserGoal from './UserGoal'
import PersonalParamsModal from './PersonalParameters/PersonalParamsModal'
import HidrateCard from '../HidrateCard'
import CircularProgressGraph from './CircularProgress'
import AllTrophiesCard from './AllTrophiesCard'
import ReactGA from 'react-ga4'

const IndividualUser = () => {
  const { groupId, userId } = useParams()

  const [day, setDay] = useState(new Date())
  const [dateLoading, setDateLoading] = useState(false)

  const [createdAtDate, setCreatedAtDate] = useState(subDays(new Date(), 1))
  const [hydrationScore, setHydrationScore] = useState(0)
  const [contributors, setContributors] = useState({})
  const [debugData, setDebugData] = useState(null)
  const [newSipsState, setNewSipsState] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [initialGoal, setInitialGoal] = useState(0)

  const [prefs, setPrefs] = useState({})

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window?.location?.pathname ?? '',
      title: 'Individual User Page'
    })
    return authService.listenToUserPrefs(setPrefs)
  }, [])

  useEffect(() => {
    const getData = async () => {
      setDateLoading(true)
      const newSips = await searchService.getSips(userId, format(day, 'yyyy-MM-dd'))
      if (newSips.user && newSips.user.createdAt) {
        setCreatedAtDate(new Date(newSips.user.createdAt))
      }

      if (newSips.message) {
        console.error(newSips.message)
        return
      }
      setInitialGoal(newSips.day ? newSips.day.goal : 0)

      newSips.sips.reverse()

      const score = getHydrationScore(newSips.hydrationScore) === 'NA'
        ? 'NA'
        : getHydrationScore(newSips.hydrationScore) / 100

      setHydrationScore(score)
      setContributors(newSips.contributors)

      if (newSips.debug) {
        setDebugData(newSips.debug)
      }
      setNewSipsState(newSips)
      setIsLoading(false)
      setDateLoading(false)
    }

    getData()
  }, [day, userId])

  const permissions = authService.getPermissions(groupId)
  const loggedInUser = authService.getUser()

  if (isLoading) {
    return (<LoadingFullScreen />)
  }
  const isEditor = (permissions.check('userData', 'editor')) || (userId === loggedInUser.id)
  // Disable Manual Add when No Day (newSipsState.day) !!!!

  return (
    <>

      <PersonalParamsModal
        user={newSipsState.user}
      />

      <PageHeader title='User Details' />
      <div>

        {(!newSipsState.day && newSipsState.lastOpenDate) &&
          <div className='mb-4'>
            <div className='alert alert-error'>
              <ExclamationTriangleIcon height={25} width={25} />
              <span>{`${newSipsState.user.name ?? 'This user'} has not logged in since ${format(parse(newSipsState.lastOpenDate, 'yyyyMMdd', new Date()), 'M/d/yyyy')}`}</span>
            </div>
          </div>}

        <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>

          <UserInfo
            userId={userId}
            isEditor={isEditor}
          />
          <HidrateCard>
            <div className='flex items-center justify-center h-full'>
              <CircularProgressGraph
                totalAmount={newSipsState.day ? newSipsState.day.totalAmount : 0}
                goal={initialGoal ?? 0}
                streak={newSipsState.streak}
                day={day}
                units={prefs.measurementSystem}
              />
            </div>
          </HidrateCard>

          <UserGoal
            units={prefs.measurementSystem}
            isEditor={isEditor}
            setGoal={setInitialGoal}
            goal={initialGoal}
            sips={newSipsState}
            userId={userId}
          />

          <div className='md:col-span-3'>
            <HydrationScore
              dateLoading={dateLoading}
              day={day}
              setDay={setDay}
              userId={userId}
              hydrationScore={hydrationScore}
              contributors={contributors}
            />

          </div>

          <div className='md:col-span-3'>
            <DayStats
              userId={userId}
              day={day}
              setDay={setDay}
              units={prefs.measurementSystem}
              newSipsState={newSipsState}
              createdAtDate={createdAtDate}
            />
          </div>

          {process.env.REACT_APP_SHOW_TROPHIES === 'true' &&
            (
              <div className='md:col-span-3'>
                <AllTrophiesCard
                  groupId={groupId}
                  userId={userId}
                />
              </div>
            )}
        </div>
      </div>
    </>

  )
}

export default IndividualUser
