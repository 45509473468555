import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Grid, RadioGroup, FormControl, Select, MenuItem, Typography, TextField } from '@mui/material'
import RadioOption from '../global/RadioOption'

const styles = () => ({
  midTitle: {
    margin: '2em 0 0'
  },
  formPart: {
    margin: '1em 1em 0 0'
  },
  caption: {
    marginTop: '0.5em'
  }
})

const isCodeValid = str => {
  if (!str) return { error: 'Enter a join code.' }
  if (str.length < 5) return { error: 'Join code must be at least 5 characters.' }
  if (str.length > 20) return { error: 'Join code must be no more than 20 characters.' }
  if (str.match(/[ \-_]./)) return { error: 'No spaces, hyphens or underscores allowed.' }
  if (str.substr(0, 3) === 'LDB') return { error: 'Join codes cannot begin with "LDB".' }
  return { valid: str.toUpperCase() }
}

const DataField = ({ maxLength, codeMode, value, onChange, joinCodePrefix, ...props }) => {
  const [val, setVal] = useState(value)
  const debounceRef = useRef(null)
  console.log(codeMode)
  return (
    <FormControl fullWidth>
      <TextField
        {...props}
        inputProps={{ maxLength }}
        variant='outlined'
        value={joinCodePrefix ? `${joinCodePrefix}_${val}` : val}
        onChange={event => {
          let newVal = event.target.value.replace(joinCodePrefix, '')
          if (codeMode) {
            newVal = newVal.toUpperCase().replace(/[ \-_]/, '')
          }
          setVal(newVal)
          clearTimeout(debounceRef.current)
          debounceRef.current = setTimeout(() => onChange(newVal), 300)
        }}
        onBlur={() => {
          clearTimeout(debounceRef.current)
          onChange(val)
        }}
      />
    </FormControl>
  )
}

DataField.propTypes = {
  classes: PropTypes.object,
  maxLength: PropTypes.number,
  codeMode: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  joinCodePrefix: PropTypes.any
}

const EditStepType = ({ classes, data: challenge, modifyData: modifyChallenge }) => {
  const [joinCodeValid, setJoinCodeValid] = useState(true)

  return (
    <RadioGroup>
      <Grid container direction='column'>
        <RadioOption
          title='Team Battle'
          detail='Keep hitting your hydration goal to accrue points for your team before the challenge ends.'
          value={challenge.goal === 'DATE' && challenge.joinCodePrefix !== 'LDB'}
          onClick={() => modifyChallenge({ goal: 'DATE', metric: 'POINT', joinCodePrefix: '' })}
        />
        <RadioOption
          title='Leaderboard'
          detail='Compete against individuals to see who can stay the most hydrated.'
          value={challenge.goal === 'DATE' && challenge.joinCodePrefix === 'LDB'}
          onClick={() => modifyChallenge({ goal: 'DATE', metric: 'POINT', joinCodePrefix: 'LDB' })}
          whileSelected={
            challenge.privacy === 'GROUP' &&
              <>
                <Grid item md={4} lg={3} className={classes.formLabel} style={{ maxWidth: '100%' }}>
                  <p>Join Code (Leaderboard join codes are automatically prepended with &quot;LDB_&quot;)</p>
                </Grid>
                <Grid item md={8} lg={9}>
                  <DataField
                    codeMode
                    value={challenge.joinCode}
                    onChange={value => {
                      const result = isCodeValid(value)
                      if (result.error) {
                        setJoinCodeValid(result.error)
                      } else {
                        setJoinCodeValid(true)
                        modifyChallenge({ joinCode: result.valid || value })
                      }
                    }}
                    joinCodePrefix={challenge.joinCodePrefix}
                  />
                  {joinCodeValid !== true &&
                    <Typography variant='caption' className={classes.error}>{joinCodeValid}</Typography>}
                </Grid>
              </>
          }
        />

        <RadioOption
          title='Cooperative'
          detail='Work together to save plastic bottles and reach a shared goal.'
          value={challenge.goal === 'THRESHOLD'}
          onClick={() => modifyChallenge({ goal: 'THRESHOLD', metric: 'BOTTLES', joinCodePrefix: '' })}
          whileSelected={
            <div>
              <TextField
                className={classes.formPart}
                variant='outlined'
                value={challenge.thresholdValue}
                onChange={event => {
                  modifyChallenge({ thresholdValue: parseInt(event.target.value) || 0 })
                }}
              />
              <FormControl variant='outlined' className={classes.formPart}>
                <Select
                  value={challenge.metric || 'BOTTLES'}
                  onChange={event => {
                    modifyChallenge({ metric: event.target.value })
                  }}
                >
                  <MenuItem value='BOTTLES'>Bottles Saved</MenuItem>
                </Select>
              </FormControl>
              <Typography variant='body1' className={classes.caption}>All users will collectively contribute to this goal.</Typography>
            </div>
          }
        />
      </Grid>

      {challenge.privacy === 'PRIVATE' &&
        (
          <>
            <Grid item md={4} lg={3} className={classes.formLabel} style={{ maxWidth: '100%' }}>
              {challenge.joinCodePrefix === 'LDB' ? <p>Join Code (Leaderboard join codes are automatically prepended with &quot;LDB_&quot;)</p> : <p>Join Code</p>}
            </Grid>
            <Grid item md={8} lg={9}>
              <DataField
                codeMode
                value={challenge.joinCode}
                onChange={value => {
                  const result = isCodeValid(value)
                  if (result.error) {
                    setJoinCodeValid(result.error)
                  } else {
                    setJoinCodeValid(true)
                    modifyChallenge({ joinCode: result.valid || value })
                  }
                }}
                joinCodePrefix={challenge.joinCodePrefix}
              />
              {joinCodeValid !== true &&
                <Typography variant='caption' className={classes.error}>{joinCodeValid}</Typography>}
            </Grid>
          </>
        )}
    </RadioGroup>
  )
}

EditStepType.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  modifyData: PropTypes.func
}

export default withStyles(styles)(EditStepType)
