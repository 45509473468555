import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import React, { useContext, useState, useEffect } from 'react'
import { Paper, CircularProgress, Box, Link } from '@mui/material'
import Typography from '@mui/material/Typography'
import Navbar from '../landing/Navbar'
import '../landing/LandingPage.css'
import { AuthContext } from '../../context/AuthContext'
import Alert from '@mui/material/Alert'

import imageName from '../../resources/hidrate-health.svg'

const LoginPage = () => {
  const { login, error } = useContext(AuthContext)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loggingIn, setLoggingIn] = useState(false)

  useEffect(() => {
    const abortController = new AbortController()
    return () => {
      abortController.abort()
    }
  }, [error])

  const onSubmit = async (event) => {
    const abortController = new AbortController()
    const signal = abortController.signal
    event.preventDefault()
    setLoggingIn(true)
    await login({ username, password, signal })
    setLoggingIn(false)
  }

  return (
    <div
      className='hst-landing'
      style={{
        backgroundColor: '#f2f2f5',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Navbar />

      {error &&
        <Alert severity='error'>{error}</Alert>}

      <Box sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      >
        <form onSubmit={onSubmit}>
          <Paper sx={{
            padding: 5,
            width: 500,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          >
            <img width='75%' alt='HidrateSpark+' src={imageName} style={{ maxWidth: '250px', marginBottom: '15px' }} />
            <Box sx={{ height: 25 }} />

            <TextField
              fullWidth
              name='username'
              label='Username'
              type='email'
              value={username}
              onChange={(e) => { setUsername(e.target.value) }}
              autoFocus
              required
            />
            <Box sx={{ height: 15 }} />

            <TextField
              fullWidth
              name='password'
              label='Password'
              type='password'
              value={password}
              onChange={(e) => { setPassword(e.target.value) }}
              required
            />

            <Box sx={{ height: 35 }} />

            <Button
              fullWidth
              type='submit'
              variant='contained'
              sx={{ color: 'white' }}
              onClick={onSubmit}
            >
              {loggingIn ? <CircularProgress color='inherit' size='1.7em' /> : 'Log In'}
            </Button>

            <Box sx={{ height: 15 }} />

            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
              <Typography mr={1}>{'Don\'t have an account?'}</Typography>
              <Link href='/signup'>Sign up</Link>
            </Box>
          </Paper>
        </form>
      </Box>
    </div>
  )
}

export default LoginPage
