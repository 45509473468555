import React, { useEffect } from 'react'
import { authService } from '../../services/authService'

export default function Logout () {
  useEffect(() => {
    setTimeout(() => {
      authService.logout()
    }, 3000)
  }, [])

  return (
    <div
      style={{
        color: '#888',
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <p>Logging you out...</p>
    </div>
  )
}
