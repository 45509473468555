import React, { useState, useRef, useEffect } from 'react'
import { ResizeObserver } from '@juggle/resize-observer'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import GraphLines from './GraphLines'
import GoalLine from './GoalLine'
import Bars from './Bars'
import { authService } from '../../services/authService'
import { convertForGraphing, deconvertForGraphing, formatBare, formatVol } from '../global/formatter'

const styles = theme => ({
  graph: {
    width: '100%',
    height: 400,
    zIndex: 1,
    position: 'relative',
    display: 'flex',
    backgroundColor: theme.palette.main.foreground,
    justifyContent: 'flex-start'
  },
  barLinesContainer: {
    width: '97%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end'
  },
  sideBar: {
    display: 'flex',
    flexDirection: 'column',
    width: '2%',
    height: '100%'
  },
  labelContainer: {
    display: 'flex',
    position: 'absolute'
  },
  mainRow: {
    display: 'flex'
  },
  lineBox: {
    position: 'absolute',
    zIndex: 30
  },
  graphBar: {
    '&.clickable rect': {
      cursor: 'pointer'
    },
    '&.clickable:hover rect': {
      strokeWidth: '1'
    },
    '&.clickable:active rect': {
      stroke: theme.palette.graph.consumedStop
    },
    '&:hover $valLabel': {
      fillOpacity: '1',
      transition: 'fill-opacity .1s'
    }
  },
  valLabel: {
    fillOpacity: '0',
    transition: 'fill-opacity .3s'
  },
  goalLine: {
    stroke: theme.palette.graph.goal,
    strokeWidth: 5
  },
  goalLineOnly: {
    stroke: 'url(#linearGradient-1inv)',
    strokeWidth: 10,
    'stroke-linejoin': 'round',
    'stroke-linecap': 'round'
  },
  bar: {
    borderRadius: '100%',
    fill: 'url(#linearGradient-1)',
    stroke: theme.palette.graph.consumedStart,
    strokeWidth: '0',
    transition: 'stroke-width .1s'
  },
  consumeGradientStart: {
    stopColor: theme.palette.graph.consumedStart
  },
  consumeGradientStop: {
    stopColor: theme.palette.graph.consumedStop
  },
  clickable: {}
})

function transformVolumeToGraphPercentage (volume, highest) {
  return (volume / (highest * 1.00)) * 100 // used to be 1.15
}

const Graph = ({ classes, data, isCurrentMonth, showGoal = true, lineOnly, unit, volume, dayClicked, ...props }) => {
  const ref = useRef(null)

  const [update, setUpdate] = useState(0)
  useEffect(() => {
    const update = () => {
      setUpdate(v => v + 1)
      return () => {

      }
    }
    return update()
  }, [])

  const [prefs, setPrefs] = useState({})
  useEffect(() => {
    return authService.listenToUserPrefs(setPrefs)
  }, [])

  useEffect(() => {
    const myObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        setHeight(entry.contentRect.height)
        setWidth(entry.contentRect.width)
      })
    })

    const element = ref.current
    if (element) {
      myObserver.observe(element)
    }

    return () => {
      if (element) {
        myObserver.unobserve(element)
      } else {
        myObserver.disconnect()
      }
    }
  })

  const rendercount = useRef(0)
  rendercount.current++

  const refHeight = ref && ref.current ? ref.current.clientHeight : 0
  const refWidth = ref && ref.current ? ref.current.clientWidth : 0

  const [height, setHeight] = useState(refHeight)
  const [width, setWidth] = useState(refWidth)

  const dayArray = (data && Array.isArray(data)) ? data : []
  let highest = 1.05 * Math.max(9, ...dayArray.reduce(function (acc, day) { return [...acc, day.water, day.goal] }, []))
  if (volume) {
    highest = convertForGraphing(highest, prefs.measurementSystem)
  }
  const power = Math.pow(10, Math.floor(Math.log(highest) * Math.LOG10E))
  highest = Math.ceil(highest / power) * power
  if (volume) {
    highest = deconvertForGraphing(highest, prefs.measurementSystem)
  }

  const barWidth = (100 / dayArray.length)

  const formats = {}
  if (volume) {
    formats.bars = v => formatVol(v, prefs.measurementSystem)
    formats.lines = v => formatBare(v, prefs.measurementSystem)
  }

  return (
    <div className={classes.graphWrapper}>
      <div className={classes.mainRow}>
        <div className={classes.graph} ref={ref} {...props}>

          <svg className={classes.lineBox} viewBox={`0 0 ${width} ${height}`}>
            {GraphLines(height, width, highest, unit, formats.lines)}
            <g className={dayClicked && classes.clickable}>
              <linearGradient x1='50%' y1='0.13988598%' x2='50%' y2='99.9472128%' id='linearGradient-1'>
                <stop className={classes.consumeGradientStart} offset='0%' />
                <stop className={classes.consumeGradientStop} offset='99.9472128%' />
              </linearGradient>
              <linearGradient x1='50%' y1='0.13988598%' x2='50%' y2='99.9472128%' id='linearGradient-1inv'>
                <stop className={classes.consumeGradientStop} offset='0%' />
                <stop className={classes.consumeGradientStart} offset='99.9472128%' />
              </linearGradient>
              {Bars(classes, height, width, highest, barWidth, dayArray, transformVolumeToGraphPercentage, dayClicked, formats.bars)}
            </g>
            {showGoal && GoalLine(classes, height, width, highest, isCurrentMonth, barWidth, dayArray, transformVolumeToGraphPercentage, lineOnly)}
          </svg>
        </div>
      </div>
      <span style={{ display: 'none' }}>{highest} - {update}, {rendercount.current}</span>
    </div>
  )
}

Graph.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  isCurrentMonth: PropTypes.bool,
  showGoal: PropTypes.bool,
  unit: PropTypes.string,
  lineOnly: PropTypes.bool,
  volume: PropTypes.any,
  dayClicked: PropTypes.func
}

export default (withStyles(styles, { withTheme: true })(Graph))
