import axios from 'axios'
const config = { apiUrl: process.env.REACT_APP_API_URL }
const authHeader = require('./authHeader')

function getGroupChallenges (groupId, signal) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader(),
      signal
    }

    return axios.get(`${config.apiUrl}group/${groupId}/challenge`, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function createChallenge ({ groupId, ...data }) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.post(`${config.apiUrl}group/${groupId}/challenge`, data, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function editChallenge (groupId, challengeId, data) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.put(`${config.apiUrl}group/${groupId}/challenge/${challengeId}`, data, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function publishChallenge (groupId, challengeId, publish) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.post(`${config.apiUrl}group/${groupId}/challenge/${challengeId}/publish`, { publish }, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function validateJoinCode ({ groupId, ...data }) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.post(`${config.apiUrl}group/${groupId}/challenge/validateJoinCode`, data, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function getChallengeDetails (groupId, challengeId) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.get(`${config.apiUrl}group/${groupId}/challenge/${challengeId}`, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function createChallengeNotifs (groupId, challengeId, data) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.post(`${config.apiUrl}group/${groupId}/challenge/${challengeId}/notifications`, { notifications: data }, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function getTrophyRecipients (groupId, challengeId, trophyId) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.get(`${config.apiUrl}group/${groupId}/challenge/${challengeId}/trophy/${trophyId}`, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

export const challengesService = {
  getGroupChallenges,
  createChallenge,
  editChallenge,
  publishChallenge,
  validateJoinCode,
  getChallengeDetails,
  createChallengeNotifs,
  getTrophyRecipients
}
