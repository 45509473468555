/* eslint-disable react/prop-types */
import React from 'react'

export default class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { error: false }
  }

  static getDerivedStateFromError (error) {
    return { error }
  }

  componentDidCatch (error, errorInfo) {
    console.error('Error boundary caught:', error, errorInfo)
  }

  render () {
    if (this.state.error) {
      return (
        <div style={{
          width: '100%',
          minHeight: '6em',
          padding: '1em',
          border: '1px dotted #ddd'
        }}
        >
          {this.props.msg || 'An unexpected error occurred.'} ({String(this.state.error)})
        </div>
      )
    }

    return this.props.children
  }
}
