import React, { useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { Stepper, Step, StepLabel, Button } from '@mui/material'
import PageHeader from '../PageHeader'
import ErrorBoundary from '../global/ErrorBoundary'

const styles = theme => ({
  paper: {
    padding: theme.spacing(4)
  },
  progressPaper: {
    padding: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(4)
  },
  buttons: {
    marginTop: theme.spacing(8),
    width: '100%',
    display: 'flex',
    '& button': {
      marginLeft: theme.spacing(2)
    }
  },
  cancelButton: {
    marginRight: 'auto'
  },
  preview: {
    marginTop: theme.spacing(4),
    padding: '8px',
    fontSize: '8px',
    '& pre': {
      margin: '8px auto',
      overflowWrap: 'break-word'
    }
  }
})

const ChallengeCreate = ({ classes, allSteps, data, modifyData, onSubmit, onCancel, title, subtitle, subtitleFunc, ...props }) => {
  const [step, setStep] = useState(0)
  const steps = allSteps.filter(s => !s.when || s.when(data))

  const currentStep = steps[step]
  const EditStep = currentStep.component

  return (
    <>
      <PageHeader title={title} subtitle={subtitleFunc ? subtitleFunc(step) : subtitle} hideSubgroupChip />
      <Grid container spacing={4}>
        <Grid item lg={9}>
          <Paper className={classes.paper}>
            {currentStep.title && <Typography variant='h3' className={classes.title}>{currentStep.title}</Typography>}
            <ErrorBoundary>
              <EditStep
                {...{ data, modifyData, setStep, onSubmit, onCancel, ...props }}
                findStep={id => setStep(steps.findIndex(s => s.id === id))}
              />
            </ErrorBoundary>
            {currentStep.buttons &&
              <div className={classes.buttons}>
                <Button variant='contained' onClick={onCancel} className={classes.cancelButton}>Cancel</Button>
                {steps[step - 1] && <Button variant='contained' onClick={() => setStep(step - 1)}>Back</Button>}
                {steps[step + 1] && <Button variant='contained' onClick={() => setStep(step + 1)} color='primary'>Next</Button>}
              </div>}
          </Paper>
        </Grid>
        <Grid item lg={3}>
          <Paper className={classes.progressPaper}>
            <Stepper activeStep={step} orientation='vertical'>
              {steps.map((s, i) => (
                <Step key={s.shortTitle} onClick={() => setStep(i)}>
                  <StepLabel>{s.shortTitle}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

ChallengeCreate.propTypes = {
  classes: PropTypes.object.isRequired,
  allSteps: PropTypes.any,
  data: PropTypes.any,
  modifyData: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleFunc: PropTypes.func
}

export default withStyles(styles)(ChallengeCreate)
