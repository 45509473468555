import React, { useState } from 'react'
import { kgToLbs, lbsToKg } from '../../../utils/unitHelper'

const WeightInput = ({ units, userParams, setUserParams }) => {
  if (units === 'metric') {
    return (
      <div>
        <label className='label'>
          <span className='label-text'>Weight (kg)</span>
        </label>
        <input
          value={userParams.weight}
          onChange={(e) => setUserParams((prev) => ({
            ...prev,
            weight: parseInt(e.target.value)
          }))}
          type='text'
          placeholder='Type here'
          className='input input-bordered w-full'
        />
      </div>
    )
  } else {
    return (
      <ImperialWeightInput
        userParams={userParams}
        setUserParams={setUserParams}
      />
    )
  }
}

const ImperialWeightInput = ({ userParams, setUserParams }) => {
  const [weight, setWeight] = useState(kgToLbs(userParams.weight))

  return (
    <div>
      <label className='label'>
        <span className='label-text'>Weight (lbs)</span>
      </label>
      <input
        value={weight}
        onChange={(e) => {
          setWeight(e.target.value)
          setUserParams((prev) => ({ ...prev, weight: lbsToKg(parseInt(weight)) }))
        }}
        type='number'
        placeholder='Type here'
        className='input input-bordered w-full'
      />
    </div>
  )
}

export default WeightInput
