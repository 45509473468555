import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Graph from '../graphing/Graph'
import { Box, Typography } from '@mui/material'
import { searchService } from '../../services/searchService'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { styled } from '@mui/material/styles'

const initialState =
{
  daysInMonth: 0,
  dayData: []
}

const DotSpan = styled('span')(() => ({
  height: '15px',
  width: '15px',
  backgroundColor: 'graph.consumedStop',
  borderRadius: '15px'
}))

const PillSpan = styled('span')(() => ({
  height: '5px',
  width: '75px',
  backgroundColor: 'graph.goal',
  borderRadius: '25px'
}))

const MainPanel = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  backgroundColor: 'main.foreground'
}))

const RowGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}))

const MonthProgress = ({ userId, dayClicked }) => {
  const today = new Date()

  const currentMonth = today.getMonth() + 1
  const currentYear = today.getFullYear()

  const [month, setMonth] = useState(currentMonth)
  const [year, setYear] = useState(currentYear)

  const [state, setState] = useState(initialState)

  useEffect(() => {
    const abortController = new window.AbortController()
    const signal = abortController.signal
    const fetchData = async () => {
      const monthKey = `${month}-${year}`
      const response = await searchService.getMonthHistory(userId, monthKey, signal)
      if (response.result === 'bad') {
        setState(initialState)
      } else {
        setState(response)
      }
    }
    fetchData()

    return () => {
      abortController.abort()
    }
  }, [userId, month, year])

  const changeMonth = (event) => {
    setMonth(event.target.value)
  }

  const changeYear = (event) => {
    setYear(event.target.value)
  }

  const isCurrentMonth = currentYear === year && currentMonth === month

  const yearOptions = []
  for (let i = 2015; i <= currentYear; i++) {
    yearOptions.push(i)
  }

  return (
    <Paper>
      <MainPanel>
        <RowGroup>
          <Box sx={{ width: '10%' }} />
          <Typography variant='h3' style={{ whiteSpace: 'nowrap', width: '200px' }}>Month History</Typography>
          <Box sx={{ width: '10%' }} />
          <DotSpan />
          <Box sx={{ width: '3%' }} />
          <Typography variant='h4'>Progress</Typography>
          <Box sx={{ width: '10%' }} />
          <PillSpan />
          <Box sx={{ width: '3%' }} />
          <Typography variant='h4'>Goal</Typography>
        </RowGroup>
        <div>
          <FormControl sx={{ margin: 1 }}>
            <InputLabel>Month</InputLabel>
            <Select
              value={month}
              onChange={changeMonth}
              inputProps={{
                name: 'month',
                id: 'month-simple'
              }}
            >
              <MenuItem value={1}>January</MenuItem>
              <MenuItem value={2}>February</MenuItem>
              <MenuItem value={3}>March</MenuItem>
              <MenuItem value={4}>April</MenuItem>
              <MenuItem value={5}>May</MenuItem>
              <MenuItem value={6}>June</MenuItem>
              <MenuItem value={7}>July</MenuItem>
              <MenuItem value={8}>August</MenuItem>
              <MenuItem value={9}>September</MenuItem>
              <MenuItem value={10}>October</MenuItem>
              <MenuItem value={11}>November</MenuItem>
              <MenuItem value={12}>December</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ margin: 1 }}>
            <InputLabel>Year</InputLabel>
            <Select
              value={year}
              onChange={changeYear}
              inputProps={{
                name: 'year',
                id: 'year-simple'
              }}
            >
              {yearOptions.map(year => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </MainPanel>
      <div>
        <Graph volume data={state.dayData} isCurrentMonth={isCurrentMonth} dayClicked={dayClicked} />
      </div>
    </Paper>
  )
}

MonthProgress.propTypes = {
  userId: PropTypes.string.isRequired,
  dayClicked: PropTypes.func
}

export default MonthProgress
