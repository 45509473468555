import React from 'react'
import './Lightbox.css'

export default function Lightbox ({ open, onClose, children }) {
  if (!open) return null

  return (
    <div className='hst-lightboxBg' onClick={onClose}>
      {open && (
        <div className='hst-lightbox'>
          <div className='hst-lightboxClose' onClick={onClose}>&times;</div>
          {children}
        </div>
      )}
    </div>
  )
}
