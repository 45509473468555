import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import moment from 'moment-timezone'
import { formatVol } from '../global/formatter'

import sipRecordedIcon from '../../resources/sip-recorded.svg'
import sipManualIcon from '../../resources/sip-manual.svg'

const styles = () => ({
  root: {
    height: '100%',
    position: 'relative',
    overflow: 'auto'
  },
  line: {
    top: '0',
    bottom: '0',
    left: '30px', // list padding + item gutter + (icon width / 2)
    border: '4px solid #cfcfcf',
    borderRadius: '4px',
    background: '#cfcfcf',
    width: '0',
    position: 'absolute'
  },
  sipIcon: {
    width: '36px',
    marginRight: '5%'
  },
  item: {
    position: 'relative',
    '& *': {
      zIndex: '2'
    }
  },
  lineCover: {
    background: 'white',
    position: 'absolute',
    left: '0',
    right: '0',
    zIndex: '1',
    '&.top': {
      top: '0',
      height: '50%'
    },
    '&.bottom': {
      bottom: '0',
      height: '75%'
    }
  }

})

const renderSip = (sip, i, len, units, classes) => {
  // const total = Math.round((sip.amount / 29.574) * 10) / 10 || 0
  const time = sip && sip.time ? moment(sip.time.iso).format('hh:mm A') : '00:00'
  return (
    <ListItem key={sip.objectId} className={classes.item}>
      {(i === 0) && <div className={classes.lineCover + ' top'} />}
      {(i === len - 1) && <div className={classes.lineCover + ' bottom'} />}
      <img className={classes.sipIcon} src={!sip.start ? sipManualIcon : sipRecordedIcon} alt='' />
      <ListItemText
        primary={formatVol(sip.amount, units)}
        primaryTypographyProps={{ variant: 'h4' }}
        secondary={`${!sip.start ? 'Manual' : 'Bottle'} • ${time}`}
        secondaryTypographyProps={{ variant: 'body2' }}
      />
    </ListItem>
  )
}

const SipHistory = ({ classes, sips, units }) => {
  return (
    <div className='flex-none'>
      <List disablePadding dense>
        {sips?.length > 0 && (<div className={classes.line} />)}
        {sips.length
          ? sips.map((sip, i) => renderSip(sip, i, sips.length, units, classes))
          : <p>No Sips Recorded</p>}
      </List>
    </div>
  )
}

SipHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  sips: PropTypes.array.isRequired,
  units: PropTypes.any
}

export default (withStyles(styles, { withTheme: true })(SipHistory))
