import React from 'react'
import PropTypes from 'prop-types'
import HidrateCard from '../HidrateCard'

const GroupAverages = ({ groupAverages, groupId }) => {
  const headGroup = groupAverages.find(a => a.groupId === groupId) || { percent: 0 }
  const headGroupPercent = headGroup.totalAverage ? Math.round(headGroup.totalAverage * 100) : 0
  const groupInfos = groupAverages.filter(a => a.groupId !== groupId).map(a => {
    return { name: a ? a.name : 'Loading...', id: a.groupId, percent: Math.round(a.totalAverage * 100) }
  })

  return (
    <HidrateCard>
      <div className='text-left'>
        <h3 className='text-xl font-bold mb-2.5 text-black'>7-day average of all users</h3>
        <p className='text-5xl font-bold text-black mb-5'>{headGroupPercent}%</p>
        <div className='flex flex-col space-y-2'>
          {groupInfos.sort((a, b) => a.percent < b.percent).map(({ percent, name, id }) => (
            <div key={id}>
              <div className='flex items-center justify-between'>
                <p className='text-sm text-semibold'>{name}</p>
                <p className='text-sm text-semibold'>{percent}</p>
              </div>

              <progress className='progress' value={percent} max='100' />
            </div>
          ))}
        </div>
      </div>
    </HidrateCard>
  )
}

GroupAverages.propTypes = {
  groupAverages: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired
}

export default GroupAverages
