import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { FormControl, TextField, Button } from '@mui/material'

const styles = () => ({
  teamRow: {
    display: 'flex',
    marginBottom: '2em'
  },
  removeButton: {
    marginLeft: '1em',
    fontSize: '1.5em',
    padding: '0'
  }
})

let idIncr = 2
const genID = () => '__' + idIncr++

const CHALLENGE_GROUP_MAX = process.env.CHALLENGE_GROUP_MAX ? parseInt(process.env.CHALLENGE_GROUP_MAX) : 40

const TeamField = ({ classes, team, index, setTeamName, removeTeam }) => {
  const [name, setName] = useState(team.name)
  const debounceRef = useRef(null)

  return (
    <div className={classes.teamRow}>
      <FormControl fullWidth>
        <TextField
          placeholder={'Team ' + (1 + index)}
          variant='outlined'
          inputProps={{ maxLength: 30 }}
          value={name}
          onChange={event => {
            const newName = event.target.value
            setName(newName)
            clearTimeout(debounceRef.current)
            debounceRef.current = setTimeout(() => setTeamName(newName), 500)
          }}
          onBlur={() => {
            clearTimeout(debounceRef.current)
            setTeamName(name)
          }}
        />
      </FormControl>
      <Button variant='outlined' className={classes.removeButton} onClick={removeTeam}>×</Button>
    </div>
  )
}

TeamField.propTypes = {
  classes: PropTypes.object.isRequired,
  team: PropTypes.any,
  index: PropTypes.any,
  setTeamName: PropTypes.func,
  removeTeam: PropTypes.any
}

const EditStep = ({ classes, data: challenge, modifyData: modifyChallenge }) => {
  if (!challenge.teams || challenge.teams.length === 0) {
    modifyChallenge({
      teams: [
        { name: '', id: genID() },
        { name: '', id: genID() }
      ]
    })
  }

  return (
    <>
      {challenge.teams.map((team, i) => (
        <TeamField
          key={team.id}
          classes={classes}
          team={team}
          index={i}
          setTeamName={name => {
            team.name = name
            modifyChallenge({ teams: [...challenge.teams] })
          }}
          removeTeam={() => {
            modifyChallenge({ teams: challenge.teams.filter(t => t !== team) })
            if (team.isCurrent) {
              modifyChallenge({
                removedCurrentTeams: [
                  ...(challenge.removedCurrentTeams || []),
                  team.name
                ]
              })
            }
          }}
        />
      ))}
      {challenge.teams.length < CHALLENGE_GROUP_MAX &&
        <Button
          variant='outlined'
          color='primary'
          onClick={() => modifyChallenge({ teams: [...challenge.teams, { name: '', id: genID() }] })}
        >Add Team
        </Button>}
    </>

  )
}

EditStep.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  modifyData: PropTypes.func
}

export default withStyles(styles)(EditStep)
