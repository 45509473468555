import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CheckCircleIcon, CheckIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import toast from 'react-hot-toast'

/* {
  if: function(challenge) => bool  -  show this notice
  fatal: bool  -  prevent submission if this notice is shown (prob also want .warn and .step if true)
  warn: bool  -  display with warning style
  text: one of...
    string  -  body text to display
    function(challenge) => string  -  template string to display
  step: string  -  show fix button to jump to provided step
} */

const ReviewStep = ({ notices, data, objTerm, onSubmit, onCancel, setStep, findStep, children }) => {
  const shownNotices = notices.map((notice, i) => ({ ...notice, key: i })).filter(notice => notice.if(data))

  const [submitState, setSubmitState] = useState('ready')

  const handleSubmit = async () => {
    if (shownNotices.filter(notice => notice.fatal).length > 0) {
      toast.error('You need to fix the issues above before you can save this notification')
      // setSubmitState('blocked')
    } else if (submitState === 'ready') {
      setSubmitState('loading')
      onSubmit().then(() => {
        setSubmitState('done')
        setTimeout(onCancel, 1000)
      }).catch(err => {
        toast.error('Something went wrong!')
        console.error(err)
      })
    }
  }

  return (
    <>
      {children}

      <div className='my-5'>
        {shownNotices.map(notice => (
          <p key={notice.key} className={` alert ${notice.warn ? 'alert-warning' : ''} ${notice.fatal && submitState === 'blocked' ? 'alert-info' : ''}`}>
            <InformationCircleIcon className='h-6 w-6' />
            {typeof notice.text === 'function' ? notice.text(data) : notice.text}
            {notice.step && <button className='btn btn-outline btn-sm' onClick={() => findStep(notice.step)}>Fix...</button>}
          </p>
        ))}
        {shownNotices.length === 0 && (
          <p className='alert alert-success'>
            <CheckCircleIcon className='h-6 w-6' />
            Everything looks good! Submit whenever you’re ready.
          </p>
        )}
      </div>
      <div className='flex items-center justify-between'>
        <button className='btn' onClick={onCancel}>Cancel</button>

        <div className='space-x-2.5'>
          <button className='btn' onClick={() => setStep(step => step - 1)}>Back</button>
          <button
            className='btn btn-primary'
            onClick={handleSubmit}
          >
            {submitState === 'loading'
              ? <span className='loading loading-spinner loading-md' />
              : submitState === 'done'
                ? <CheckIcon className='h-5 w-5' />
                : 'Submit'}
          </button>
        </div>
      </div>
    </>
  )
}

ReviewStep.propTypes = {
  notices: PropTypes.any,
  data: PropTypes.any,
  objTerm: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  setStep: PropTypes.func,
  findStep: PropTypes.func,
  children: PropTypes.any
}

export default ReviewStep
