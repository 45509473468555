
const BreastfeedingToggle = ({ userParams, setUserParams }) => {
  return (
    <div className='form-control'>
      <label className='label cursor-pointer'>
        <span className='label-text'>Nursing</span>
        <input
          type='checkbox'
          className='toggle toggle-primary'
          checked={userParams.breastfeeding}
          onChange={() => setUserParams((prev) => ({ ...prev, breastfeeding: !prev.breastfeeding }))}
        />
      </label>
    </div>
  )
}

export default BreastfeedingToggle
