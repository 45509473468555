import React from 'react'
import PropTypes from 'prop-types'
import { formatAuto } from '../global/formatter'
import HidrateCard from '../HidrateCard'

const TotalVolume = ({ totalWater, units }) => {
  return (
    <HidrateCard>
      <div className='text-left'>
        <h3 className='text-xl font-bold mb-2.5 text-black'>Total Volume Drank</h3>
        <p className='text-5xl font-bold text-black mb-5'>{formatAuto(totalWater, units)}</p>
      </div>
    </HidrateCard>
  )
}

TotalVolume.propTypes = {
  totalWater: PropTypes.number.isRequired,
  units: PropTypes.any
}

export default TotalVolume
