import React, { useState } from 'react'

const TableBodyUsers = ({ notification, modifyNotification, users }) => {
  const handleSelectUser = (user) => {
    const index = notification.audience.indexOf(user.objectId)
    if (index > -1) {
      modifyNotification({ audience: notification.audience.filter(objectId => objectId !== user.objectId) })
    } else {
      modifyNotification({ audience: [...notification.audience, user.objectId] })
    }
  }

  return (
    <tbody>
      {users.map(user => (
        <tr
          key={user.objectId}
          hover
          selected={notification.audience.indexOf(user.objectId) > -1}
          onClick={() => {
            handleSelectUser(user)
          }}
        >
          <td>
            <input
              type='checkbox'
              className='checkbox checkbox-primary'
              checked={notification.audience.indexOf(user.objectId) > -1}
              onChange={() => { handleSelectUser(user) }}
            />
          </td>
          <td>{user.name}</td>
          <td>{user.objectId}</td>
        </tr>
      ))}
    </tbody>
  )
}

const TableBodySubgroups = ({ notification, modifyNotification, subgroups }) => {
  const groupedUsers = new Set()
  const rows = subgroups.map(group => {
    let usersInCount = 0
    let usersOut = false
    for (let i = 0; i < group.members.length; i++) {
      groupedUsers.add(group.members[i])
      if (notification.audience.indexOf(group.members[i]) > -1) {
        usersInCount++
      } else {
        usersOut = true
      }
    }
    return {
      ...group,
      hasIn: usersInCount > 0,
      hasOut: usersOut,
      inCount: usersInCount,
      total: group.members.length
    }
  })
  const ungroupedUsers = notification.audience.filter(user => !groupedUsers.has(user))

  const handleSelectRow = (row) => {
    if (row.hasIn && !row.hasOut) {
      modifyNotification({
        audience: notification.audience.filter(id => row.members.indexOf(id) < 0)
      })
    } else {
      modifyNotification({
        audience: [...notification.audience, ...row.members].filter((id, i, arr) => arr.lastIndexOf(id) === i)
      })
    }
  }

  return (
    <tbody>
      {rows.map(row => (row.total === 0
        ? (
          <tr key={row.id}>
            <td>
              <input
                type='checkbox'
                className='checkbox checkbox-primary'
                disabled
              />
            </td>
            <td>{row.name}</td>
            <td>0 users</td>
          </tr>)
        : (
          <tr
            key={row.id}
            className={`hover ${(row.hasIn && !row.hasOut) && 'bg-base-200'}`}
            selected={row.hasIn && !row.hasOut}
            onClick={() => { handleSelectRow(row) }}
          >
            <td>
              <input
                type='checkbox'
                className='checkbox checkbox-primary'
                checked={row.hasIn}
                onChange={() => { handleSelectRow(row) }}
              />
            </td>
            <td>{row.name}</td>
            <td>
              {!row.hasOut
                ? `All ${row.total} users`
                : `${row.inCount} of ${row.total} users`}
            </td>
          </tr>))
      )}
      {ungroupedUsers.length > 0 &&
        <tr
          className='hover'
          onClick={() => {
            modifyNotification({ audience: notification.audience.filter(id => ungroupedUsers.indexOf(id) < 0) })
          }}
        >
          <td>
            <input
              type='checkbox'
              className='checkbox checkbox-primary'
              checked={notification.audience.length > 0}
              onChange={() => {
                modifyNotification({ audience: notification.audience.filter(id => ungroupedUsers.indexOf(id) < 0) })
              }}
            />
          </td>
          <td><i>Users without subgroups</i></td>
          <td>{ungroupedUsers.length} users</td>
        </tr>}
    </tbody>
  )
}

const EditStep = ({ classes, notification, modifyNotification, users, subgroups }) => {
  const [showGroups, setShowGroups] = useState(subgroups.filter(subgroup => subgroup.members.length > 0).length > 0)

  const handleSelectAll = () => {
    console.log(notification.audience.length, users.length)
    if (notification.audience.length === users.length) {
      modifyNotification({ audience: [] })
    } else {
      modifyNotification({ audience: users.map(user => user.objectId) })
    }
  }

  return (
    <>
      <div className='flex items-center justify-between mb-5'>
        <h2 className='text-xl font-semibold'>Choose an Audience</h2>
        <div className='flex items-center space-x-2.5'>
          <p>View by: </p>
          <div className='join'>
            <button
              onClick={() => setShowGroups(true)}
              className={`btn btn-sm btn-primary join-item ${!showGroups && 'btn-outline'}`}
            >
              Subgroups
            </button>
            <button
              onClick={() => setShowGroups(false)}
              className={`btn btn-sm btn-primary join-item ${showGroups && 'btn-outline'}`}
            >
              Users
            </button>
          </div>
        </div>
      </div>
      <div>
        <table className='table'>
          <thead>
            <tr>
              <th className='w-[5rem]'>
                <input
                  type='checkbox'
                  className='checkbox checkbox-primary'
                  checked={notification.audience.length > 0}
                  onChange={handleSelectAll}
                />
              </th>
              {(showGroups
                ? ['Group Name', 'Users']
                : ['Name', 'ID']
              ).map((str, i) => <th key={i}>{str}</th>)}
            </tr>
          </thead>
          {showGroups
            ? <TableBodySubgroups {...{ users, subgroups, classes, notification, modifyNotification }} />
            : <TableBodyUsers {...{ users, classes, notification, modifyNotification }} />}
        </table>
      </div>
    </>
  )
}

export default EditStep
