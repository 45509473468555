import React, { useState } from 'react'
import PropTypes from 'prop-types'
import HidrateCard from '../HidrateCard'
import { format } from 'date-fns'
import { updateUserParams } from '../../services/userDashboardService'
import { CheckIcon } from '@heroicons/react/24/outline'
import { mLToOz, ozToMl } from '../../utils/unitHelper'

const UserGoal = ({ units, sips, goal, userId, setGoal, isEditor }) => {
  const roundedGoal = Math.ceil(goal)
  const userGoal = units === 'metric'
    ? parseInt(roundedGoal)
    : mLToOz(roundedGoal)

  const recommendedGoal = sips.day ? sips.day.recommendedGoal : 0

  const dayDate = sips?.day?.date
    ? sips.day.date
    : format(new Date(), 'yyyy-MM-dd')

  const [saving, setSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const [goalMl, setGoalMl] = useState(userGoal)
  const [goalOz, setGoalOz] = useState(mLToOz(userGoal))
  const [goalType, setGoalType] = useState(goal === recommendedGoal
    ? 'RECOMMENDED'
    : 'MANUAL')

  const handleSaveManualGoal = async () => {
    try {
      setSaving(true)
      const userGoal = units === 'metric'
        ? parseInt(goalMl)
        : ozToMl(goalOz)

      const userParams = { goal: userGoal }
      const res = await updateUserParams({ userId, userParams, dateKey: dayDate })
      const newGoal = res.day.goal
      setGoal(newGoal)
      setGoalMl(newGoal)
      setGoalOz(mLToOz(newGoal))
    } catch (e) {
      console.error(e)
    } finally {
      setSaving(false)
    }
  }

  const handleSaveRecommendedGoal = async () => {
    try {
      setSaving(true)
      const userParams = { goal: 0 }
      await updateUserParams({ userId, userParams, dateKey: dayDate })
      setGoal(recommendedGoal)
      setGoalMl(recommendedGoal)
      setGoalOz(mLToOz(recommendedGoal))
    } catch (e) {
      console.error(e)
    } finally {
      setSaving(false)
    }
  }

  const goalUnits = units === 'metric'
    ? 'ml'
    : 'oz'

  return (
    <HidrateCard>
      <div className='flex flex-col items-center justify-center h-full'>
        <h2 className='text-xl mb-2'>Daily Intake Goal</h2>
        <div className='flex items-center justify-center space-x-2.5'>
          {(goalType === 'MANUAL' && isEditor && isEditing)
            ? (
              <>
                <div className='flex items-center space-x-2.5'>
                  <input
                    value={units === 'metric' ? goalMl : goalOz}
                    onChange={units === 'metric'
                      ? (e) => setGoalMl(e.target.value)
                      : (e) => setGoalOz(e.target.value)}
                    className='input input-bordered rounded-full'
                    placeholder='Goal'
                  />
                  <button
                    disabled={saving}
                    className='btn btn-primary btn-circle'
                    onClick={async () => {
                      setIsEditing(false)
                      await handleSaveManualGoal()
                    }}
                  >
                    <CheckIcon height={25} width={25} />
                  </button>
                </div>
              </>)
            : (isEditor && goalType === 'MANUAL')
                ? (
                  <button onClick={() => setIsEditing(true)}>
                    <CurrentGoal
                      userGoal={userGoal}
                      goalUnits={goalUnits}
                      isSaving={saving}
                    />
                  </button>)
                : (<CurrentGoal
                    userGoal={userGoal}
                    goalUnits={goalUnits}
                    isSaving={saving}
                   />)}
        </div>
        {isEditor && (
          <div className='join mt-5'>
            <input
              className='join-item btn'
              type='radio'
              checked={goalType === 'RECOMMENDED'}
              onChange={async () => {
                setGoal(recommendedGoal)
                setGoalType('RECOMMENDED')
                await handleSaveRecommendedGoal()
              }}
              aria-label='Recommended'
            />
            <input
              className='join-item btn'
              type='radio'
              checked={goalType === 'MANUAL'}
              onChange={() => {
                setGoalType('MANUAL')
                setIsEditing(true)
              }}
              aria-label='Manual'
            />
          </div>)}
      </div>
    </HidrateCard>
  )
}

const CurrentGoal = ({ userGoal, goalUnits, isSaving }) => {
  return !isSaving
    ? (<h3 className='text-5xl font-semibold'>{userGoal} {goalUnits}</h3>)
    : (<span className='loading loading-spinner loading-md min-h-[48px]' />)
}

UserGoal.propTypes = {
  units: PropTypes.string.isRequired,
  isEditor: PropTypes.bool.isRequired,
  setGoal: PropTypes.func.isRequired,
  sips: PropTypes.object.isRequired,
  goal: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired
}

export default UserGoal
