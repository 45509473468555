import React from 'react'
import PropType from 'prop-types'

const HidrateCard = ({ children }) => {
  return (
    <div className='bg-white p-5 rounded-3xl flex flex-col shadow-md overflow-x-auto'>
      {children}
    </div>
  )
}
HidrateCard.propTypes = {
  children: PropType.any
}
export default HidrateCard
