import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';
import { Grid, Radio, Typography } from '@mui/material'

const styles = theme => ({
  radioOption: {
    marginBottom: '.5em'
  },
  radioTitle: {
    marginTop: '.25em',
    cursor: 'pointer'
  },
  whileSelected: {
    marginTop: '.5em',
    padding: '0.5em 1em',
    borderLeft: '2px solid ' + theme.palette.primary.main
  }
})

const RadioOption = ({ classes, title, detail, value, whileSelected, onClick }) => (
  <Grid item md onClick={onClick} className={classes.radioOption}>
    <Grid container wrap='nowrap'>
      <Grid item>
        <Radio checked={value} />
      </Grid>
      <Grid item>
        <Typography variant='h6' className={classes.radioTitle}>{title}</Typography>
        {detail}
        {value && whileSelected &&
          <div className={classes.whileSelected} onClick={e => {
            e.stopPropagation()
            e.preventDefault()
          }}>
            {whileSelected}
          </div>}
      </Grid>
    </Grid>
  </Grid>
)

RadioOption.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  detail: PropTypes.string,
  value: PropTypes.any,
  whileSelected: PropTypes.any,
  onClick: PropTypes.func
}

export default withStyles(styles)(RadioOption)
