import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Avatar from '@mui/material/Avatar'
import { formatGoal } from '../global/formatter'
import image from '../../resources/Logomark_TEAL.svg'

const UserListRow = ({ groupId, user, compact, units, navigate }) => {
  const handleClickRow = (id) => {
    navigate(`/dashboard/${groupId}/individual/${id}`)
  }

  const url = user.profile || image

  return (
    <TableRow
      hover
      onClick={() => handleClickRow(user.id)}
      sx={{
        padding: compact ? 1 : 0,
        paddingLeft: compact ? 2 : 0
      }}
    >
      <TableCell component='th' scope='row' sx={{ paddingRight: 0 }}>
        <Avatar alt={user.name} src={url} />
      </TableCell>
      <TableCell title={user.id} sx={{ fontWeight: 'bold' }}>{user.name}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{(!user.subgroups || user.subgroups === 'None') ? '—' : user.subgroups}</TableCell>
      <TableCell>{formatGoal(user.progress.todayTotal, user.progress.todayGoal, units)}</TableCell>
      <TableCell>{user.progress.todayPercent || 0}%</TableCell>
      <TableCell>{user.average || 0}%</TableCell>
    </TableRow>
  )
}

UserListRow.propTypes = {
  user: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  compact: PropTypes.bool,
  units: PropTypes.any
}

export default UserListRow
