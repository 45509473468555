import axios from 'axios'
import { useEffect, useState } from 'react'
import { getCached, updateGroupCache } from '../components/global/groupInfo'
import { searchService } from './searchService'
const config = { apiUrl: process.env.REACT_APP_API_URL }
const authHeader = require('./authHeader')

const signup = async ({ email, password, name }) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password, name })
  }

  const response = await window.fetch(`${config.apiUrl}signup`, requestOptions)
  return response
}

const login = async (username, password, signal) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    signal,
    body: JSON.stringify({ username, password })
  }

  const response = await window.fetch(`${config.apiUrl}authenticate`, requestOptions)
  const user = await handleResponse(response)
  if (user && user.token) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    const nonAdminId = '0000000000'
    const groupId = user.groups.length ? user.groups[0].id : nonAdminId
    setGroup(groupId)
    if (groupId === nonAdminId) {
      window.location.href = `/dashboard/${nonAdminId}/individual/${user.id}`
    }
    window.localStorage.setItem('user', JSON.stringify(user))
    refreshUserDetails(user.id)
    refreshUserPrefs(groupId)
    return user
  } else {
    throw new Error('Something went wrong logging in')
  }
}

function logout () {
  // remove user from local storage to log user out
  window.localStorage.removeItem('user')
  window.localStorage.removeItem('userDetails')
  window.localStorage.removeItem('userPrefs')
  window.location.href = '/login'
}

let userDetails = JSON.parse(window.localStorage.getItem('userDetails') || '{}')
const userDetailsListeners = []

function listenToUserDetails (callback) {
  callback(userDetails)
  userDetailsListeners.push(callback)
  return () => userDetailsListeners.splice(userDetailsListeners.indexOf(callback), 1)
}

const refreshUserDetails = async (userId, signal) => {
  const user = JSON.parse(window.localStorage.getItem('user'))

  if (userId) {
    try {
      const res = await searchService.getHardware(userId, signal)

      userDetails = res.user
      userDetailsListeners.forEach(callback => callback(userDetails))
      window.localStorage.setItem('userDetails', JSON.stringify(userDetails))
    } catch (err) {
      console.warn('error refreshing user details:', err)
    }
  } else if (user && user.id) {
    try {
      const res = await searchService.getHardware(user.id)

      userDetails = res.user
      userDetailsListeners.forEach(callback => callback(userDetails))
      window.localStorage.setItem('userDetails', JSON.stringify(userDetails))
    } catch (err) {
      console.warn('error refreshing user details:', err)
    }
  }
}

function getUser () {
  return JSON.parse(window.localStorage.getItem('user'))
}

function setGroup (groupId) {
  window.localStorage.setItem('groupId', groupId)
}

function getGroups () {
  const user = JSON.parse(window.localStorage.getItem('user'))
  return user.groups
}

function getGroup () {
  return window.localStorage.getItem('groupId')
}

let userPrefs = JSON.parse(window.localStorage.getItem('userPrefs') || '{ "initial": true }')
const userPrefsListeners = []

function listenToUserPrefs (callback) {
  callback(userPrefs)
  userPrefsListeners.push(callback)
  return () => userPrefsListeners.splice(userPrefsListeners.indexOf(callback), 1)
}

function refreshUserPrefs (groupId, signal) {
  const options = {
    headers: authHeader.authHeader(),
    signal
  }

  axios.get(`${config.apiUrl}group/${groupId || getGroup()}/settings`, options).then(res => {
    userPrefs = res.data
    userPrefsListeners.forEach(callback => callback(userPrefs))
    window.localStorage.setItem('userPrefs', JSON.stringify(userPrefs))

    const group = res.data.group
    if (!group) return
    const groupInfo = getCached(groupId)
    if (group.groupName) groupInfo.name = group.groupName
    if (group.parent) groupInfo.parent = group.parent.objectId
    updateGroupCache({ [groupId]: groupInfo })
  })
}

async function setUserPrefs (prefs, groupId) {
  userPrefs = prefs

  userPrefsListeners.forEach(callback => callback(userPrefs))
  window.localStorage.setItem('userPrefs', JSON.stringify(userPrefs))

  await axios.put(`${config.apiUrl}group/${groupId || getGroup()}/settings`, prefs, {
    headers: authHeader.authHeader()
  })
}

let userPermissions = null // caching parsed data for speed and react update detection
const ranks = ['any', 'viewer', 'editor', 'publisher'] // all valid ranks in ascending order of coolness
const permissionsListeners = [] // functions to run when permissions update

function getPermissions (groupId, postFetch = false) {
  let permissions = {}
  try {
    // console.log('PERMISSIONS:', JSON.parse(window.localStorage.getItem('user')).permissions)
    if (!userPermissions) {
      userPermissions = (JSON.parse(window.localStorage.getItem('user')).permissions || {})
      if (!postFetch) refreshPermissions(groupId)
    }
    permissions = userPermissions[groupId]
  } catch (e) {
    console.error(e)
    permissions = null
  }
  if (!permissions) {
    permissions = { blank: true }
    if (!postFetch) refreshPermissions(groupId)
  }
  permissions.check = function (area, rank = 'any') {
    const rankIndex = ranks.indexOf(rank)
    if (rankIndex === -1) return false
    if (this[`${area}_${rank}`]) return true
    const nextRank = ranks[rankIndex + 1]
    if (nextRank) return permissions.check(area, nextRank)
    return false
  }
  return permissions
}

async function fetchPermissions (groupIdIn, signal) {
  const groupId = groupIdIn || getGroup()
  const userString = window.localStorage.getItem('user')
  if (groupId && typeof groupId === 'string' && userString) {
    const options = {
      headers: authHeader.authHeader(),
      signal
    }

    const response = await axios.get(`${config.apiUrl}group/${groupId}/permissions`, options)
    if (response.status === 401) {
      window.location.replace('/logout')
    }
    const user = JSON.parse(userString)
    user.permissions = {
      ...user.permissions,
      ...response.data
    }
    window.localStorage.setItem('user', JSON.stringify(user))
    userPermissions = user.permissions
    permissionsListeners.forEach(call => call(getPermissions(groupId, true)))
    return userPermissions
  }
}

function refreshPermissions (groupIdIn) {
  const groupId = groupIdIn || getGroup()
  const userString = window.localStorage.getItem('user')
  if (groupId && typeof groupId === 'string' && userString) {
    const options = {
      headers: authHeader.authHeader()
    }

    axios.get(`${config.apiUrl}group/${groupId}/permissions`, options)
      .then((response) => {
        // console.log('refreshed permissions')
        if (response.status === 401) {
          window.location.replace('/logout')
        }
        const user = JSON.parse(userString)
        user.permissions = {
          ...user.permissions,
          ...response.data
        }
        window.localStorage.setItem('user', JSON.stringify(user))
        userPermissions = user.permissions
        permissionsListeners.forEach(call => call(getPermissions(groupId, true)))
        return userPermissions
      }).catch((err) => {
        console.log('failed to refresh permissions:', err)
      })
  }
}

function listenToPermissions (groupId, callback) {
  callback(getPermissions(groupId))
  permissionsListeners.push(callback)
  return () => permissionsListeners.splice(permissionsListeners.indexOf(callback), 1)
}

function usePermissions (groupId) {
  const [permissions, setPermissions] = useState(authService.blankPermissions)
  useEffect(() => {
    return listenToPermissions(groupId, setPermissions)
  }, [groupId])
  return permissions
}

function getStartPage (groupId, userIdIn) {
  const permissions = authService.getPermissions(groupId)

  let path = ''
  if (permissions.blank) {
    return null
  } else if (permissions.check('userData', 'any')) {
    path = '/home'
  } else if (permissions.check('ad', 'any')) {
    path = '/advertisements'
  } else if (permissions.check('challenge', 'any')) {
    path = '/challenges'
  } else if (permissions.check('notif', 'any')) {
    path = '/notifications'
  } else {
    const userId = userIdIn || JSON.parse(window.localStorage.getItem('user')).id
    path = `/individual/${userId}`
  }
  return '/dashboard/' + groupId + path
}

const blankPermissions = { check: () => false, blank: true }

function handleResponse (response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout()
      }

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}

export const authService = {
  signup,
  login,
  logout,
  getUser,
  getGroup,
  setGroup,
  getGroups,
  getPermissions,
  fetchPermissions,
  refreshPermissions,
  listenToPermissions,
  usePermissions,
  getStartPage,
  blankPermissions,
  refreshUserDetails,
  listenToUserDetails,
  refreshUserPrefs,
  listenToUserPrefs,
  setUserPrefs
}
