import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';
import { Clear, Edit } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Switch, Typography } from '@mui/material'
import moment from 'moment'
import iconPie0 from '../../resources/pie-0.svg'
import iconPie25 from '../../resources/pie-25.svg'
import iconPie50 from '../../resources/pie-50.svg'
import iconPie75 from '../../resources/pie-75.svg'
import iconPie100 from '../../resources/pie-100.svg'
import iconMotivation from '../../resources/motivation.svg'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  mainBreaker: {
    background: '#E1EBEA',
    margin: '0 10% 2em',
    padding: '1.5em 2em',
    borderRadius: '2em',
    display: 'flex',
    alignItems: 'center'
  },
  mainBreakerContent: {
    flexGrow: '1',
    '& p': {
      margin: '.5em 0 0'
    }
  },
  category: {
    width: '100%',
    display: 'flex',
    padding: theme.spacing(2),
    borderBottom: '1px solid #ccc',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  categoryIcon: {
    width: '2em',
    height: '2em',
    opacity: '0.7',
    marginRight: theme.spacing(1)
  },
  categoryInfo: {
    width: '7em',
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2)
  },
  categoryTitle: {
    fontWeight: 'bold',
    fontSize: '1.1em',
    textTransform: 'uppercase'
  },
  notifList: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1'
  },
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3)
  },
  fieldLabel: {
    marginRight: theme.spacing(3),
    textAlign: 'right',
    width: '7em'
  },
  field: {
    display: 'flex',
    alignItems: 'baseline',
    background: theme.palette.primary.light,
    padding: '.5em 1em',
    borderRadius: '1.2em',
    flexGrow: '1'
  },
  fieldDefault: {
    background: '#E1EBEA'
  },
  fieldNote: {
    textTransform: 'uppercase',
    fontSize: '.8em',
    fontWeight: 'bold',
    color: '#00000088',
    letterSpacing: '.03em',
    marginRight: theme.spacing(1)
  },
  fieldInput: {
    fontFamily: 'inherit',
    fontSize: '1em',
    border: 'none',
    background: 'none',
    padding: '.4em .1em',
    borderRadius: '.2em',
    flexGrow: '1',
    resize: 'none'
  },
  fieldControl: {
    alignSelf: 'flex-start',
    display: 'block',
    fontSize: '1.5em',
    fontWeight: 'bold',
    color: '#000000',
    opacity: '0.3',
    marginLeft: theme.spacing(1),
    marginRight: '-.3em',
    width: '.75em',
    height: '.75em',
    textAlign: 'center',
    lineHeight: '.8em',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.7'
    }
  },
  addButton: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(-2),
    cursor: 'pointer',
    '&:first-child': {
      marginTop: theme.spacing(-1)
    }
  },
  dialogBody: {
    display: 'flex',
    flexDirection: 'column',
    width: '30em',
    maxWidth: '90vw'
  },
  dialogLabel: {
    fontSize: '1em',
    color: 'inherit'
  },
  dialogRadio: {
    marginTop: theme.spacing(2)
  }
})

let idIncr = 0
const genID = () => `__${idIncr++}`

const NotifField = ({ classes, label, isDefault, value, onChange, onEdit, onDelete }) => {
  const [val, setVal] = useState(value)
  const debounceRef = useRef(null)

  return (
    <div className={classes.fieldContainer}>
      {label && <span className={classes.fieldLabel}>{label}</span>}
      <div className={classes.field + (isDefault ? ' ' + classes.fieldDefault : '')}>
        {isDefault && <span className={classes.fieldNote}>Default</span>}
        <input
          className={classes.fieldInput + (value.length === 0 && val.length === 0 ? ' empty' : '')}
          disabled={!onChange}
          placeholder='Notification text'
          value={val}
          onChange={event => {
            const newVal = event.target.value
            setVal(newVal)
            clearTimeout(debounceRef.current)
            debounceRef.current = setTimeout(() => onChange(newVal), 500)
          }}
          onBlur={() => {
            clearTimeout(debounceRef.current)
            onChange(val)
          }}
        />
        {onDelete && <Clear role='button' className={classes.fieldControl} onClick={onDelete} />}
        {onEdit && <Edit role='button' className={classes.fieldControl} onClick={onEdit} />}
      </div>
    </div>
  )
}

NotifField.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  isDefault: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
}

const typeNames = {
  FIRST: 'start',
  MOTIVATING: 'motivational',
  HALFWAY: 'halfway',
  LAST: 'near finish',
  COMPLETE: 'finish',
  VICTORY: 'winning',
  LOSE: 'losing'
}

const EditStepNotifs = ({ classes, data: challenge, modifyData: modifyChallenge }) => {
  const categories = [{
    id: 'start',
    types: ['FIRST'],
    title: 'Start',
    icon: challenge.goal === 'DATE' ? iconPie0 : iconPie25,
    desc: challenge.goal === 'DATE'
      ? 'Sent on ' + challenge.startDate.format('MMM D')
      : 'Sent at 25% of\xa0goal',
    defaultMsg: `The ${challenge.name || '(challenge name)'} challenge is under way!`
  }, {
    id: 'motivation',
    types: ['MOTIVATING'],
    title: 'Motivation',
    icon: iconMotivation,
    desc: 'Sent as the challenge progresses'
  }, {
    id: 'halfway',
    types: ['HALFWAY'],
    title: 'Halfway',
    icon: iconPie50,
    desc: challenge.goal === 'DATE'
      ? 'Sent on ' + moment(challenge.startDate).add(challenge.endDate.diff(challenge.startDate, 'ms') / 2, 'ms').format('MMM D')
      : 'Sent at 50% of\xa0goal',
    defaultMsg: `The ${challenge.name || '(challenge name)'} challenge is halfway done, keep working towards the goal!`
  }, {
    id: 'almost',
    types: ['LAST'],
    title: 'Near Finish',
    icon: iconPie75,
    desc: 'Sent at 75% of\xa0goal',
    defaultMsg: `The ${challenge.name || '(challenge name)'} challenge is almost over, keep it up!`
  }, {
    id: 'finish',
    types: ['COMPLETE', 'VICTORY', 'LOSE'],
    title: 'Finish',
    icon: iconPie100,
    desc: challenge.goal === 'DATE'
      ? 'Sent on ' + challenge.endDate.format('MMM D')
      : 'Sent when goal is reached',
    defaultMsg: `The ${challenge.name || '(challenge name)'} challenge is over. Check how you did!`
  }]

  if (challenge.goal === 'DATE') {
    categories.splice(3, 1)
  }

  const [showAddDialog, setShowAddDialog] = useState(false)
  const [addingData, setAddingData] = useState({
    category: categories[0],
    type: categories[0].types[0],
    text: '',
    team: null
  })
  const addingTeamName = addingData.team ? (challenge.teams.find(t => t.id === addingData.team) || {}).name : null
  const addingAllowed = addingData.type === 'MOTIVATING' || !Object.values(challenge.notifs).some(notif => notif.type === addingData.type && (notif.team || null) === addingData.team)

  const sortWeight = notif => notif.team || ''

  return (
    <>
      <div className={classes.root}>
        <div className={classes.mainBreaker}>
          <div className={classes.mainBreakerContent}>
            <Typography variant='h4'>Use Challenge Notifications</Typography>
            {!challenge.useNotifs &&
              <p>Deliver personalized messages to your participants as the challenge progresses. You choose when and to which teams they’re sent.</p>}
            <Typography variant='caption'>Notifications can’t be added or edited after the challenge is created.</Typography>
          </div>
          <Switch checked={challenge.useNotifs} onChange={e => modifyChallenge({ useNotifs: e.target.checked })} />
        </div>
        {challenge.useNotifs && categories.map(category => (
          <div key={category.id} className={classes.category}>
            <img src={category.icon} alt='' className={classes.categoryIcon} />
            <div className={classes.categoryInfo}>
              <span className={classes.categoryTitle}>{category.title}</span>
              <span>{category.desc}</span>
            </div>
            <div className={classes.notifList}>
              {category.defaultMsg &&
              !Object.values(challenge.notifs)
                .filter(notif => notif && notif.type === category.types[0])
                .some(notif => !notif.team) &&
                  <NotifField
                    classes={classes}
                    isDefault
                    value={category.defaultMsg}
                    onEdit={() => {
                      const newID = genID()
                      modifyChallenge({
                        notifs: {
                          ...challenge.notifs,
                          [newID]: {
                            id: newID,
                            type: category.types[0],
                            text: category.defaultMsg
                          }
                        }
                      })
                    }}
                  />}
              {Object.values(challenge.notifs)
                .filter(notif => notif && category.types.includes(notif.type))
                .sort((a, b) => {
                  const [sa, sb] = [sortWeight(a), sortWeight(b)]
                  if (sa > sb) return 1
                  if (sa < sb) return -1
                  return 0
                })
                .map(notif => (
                  <NotifField
                    key={notif.id}
                    classes={classes}
                    value={notif.text}
                    label={
                      (notif.type === 'VICTORY' && (
                        notif.team ? notif.teamName + ' (on win)' : 'Winning team'
                      )) ||
                      (notif.type === 'LOSE' && (
                        notif.team ? notif.teamName + ' (on loss)' : 'Losing team'
                      )) ||
                      notif.teamName
                    }
                    onChange={v => {
                      const newNotif = { ...notif, text: v }
                      modifyChallenge({
                        notifs: {
                          ...challenge.notifs,
                          [newNotif.id]: newNotif
                        }
                      })
                    }}
                    onDelete={() => {
                      const newNotifs = { ...challenge.notifs }
                      delete newNotifs[notif.id]
                      modifyChallenge({ notifs: newNotifs })
                    }}
                  />
                ))}
              {(challenge.goal === 'DATE' || category.id === 'motivation' || !Object.values(challenge.notifs).some(notif => category.types.includes(notif.type))) &&
                <span
                  role='button'
                  className={classes.addButton}
                  onClick={() => {
                    setShowAddDialog(true)
                    setAddingData({
                      category: category,
                      type: category.types[0],
                      text: '',
                      team: null
                    })
                  }}
                >Add Message
                </span>}
            </div>
          </div>
        ))}
      </div>

      <Dialog open={showAddDialog} onClose={() => setShowAddDialog(false)}>
        <DialogTitle>{addingData.category.title}</DialogTitle>
        <DialogContent className={classes.dialogBody}>
          <DialogContentText className={classes.dialogLabel}>
            {addingData.category.desc}
          </DialogContentText>
          <TextField
            placeholder='Notification text'
            variant='outlined'
            value={addingData.text}
            onChange={e => setAddingData({ ...addingData, text: e.target.value })}
          />
          {challenge.goal === 'DATE' &&
            <FormControl component='fieldset' className={classes.dialogRadio}>
              <FormLabel component='legend' className={classes.dialogLabel}>Send to</FormLabel>
              <RadioGroup
                value={addingData.team || 'all'}
                onChange={e => setAddingData({ ...addingData, team: e.target.value === 'all' ? null : e.target.value })}
              >
                <FormControlLabel control={<Radio />} value='all' label='All participants' />
                {challenge.teams.map((team, i) => (
                  <FormControlLabel key={team.id} control={<Radio />} value={team.id} label={team.name || `(Team ${i + 1})`} />
                ))}
              </RadioGroup>
            </FormControl>}
          {challenge.goal === 'DATE' && addingData.category.id === 'finish' &&
            <FormControl component='fieldset' className={classes.dialogRadio}>
              <FormLabel component='legend' className={classes.dialogLabel}>{addingData.team ? 'Only if' : 'Only to'}</FormLabel>
              <RadioGroup
                value={addingData.type}
                onChange={e => setAddingData({ ...addingData, type: e.target.value })}
              >
                <FormControlLabel control={<Radio />} value='COMPLETE' label={addingData.team ? 'Always send' : 'Send to everyone'} />
                <FormControlLabel control={<Radio />} value='VICTORY' label={addingData.team ? `Only if ${addingTeamName} wins` : 'Only send to members of the winning team'} />
                <FormControlLabel control={<Radio />} value='LOSE' label={addingData.team ? `Only if ${addingTeamName} loses` : 'Only send to members of losing teams'} />
              </RadioGroup>
            </FormControl>}
          {!addingAllowed && showAddDialog &&
            <DialogContentText className={classes.addingError}>
              You already have a {!addingData.team && 'general'} {typeNames[addingData.type]} notification{addingData.team && (' for ' + addingTeamName)}. Adjust the selected recipients, or go back and edit the existing message.
            </DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={() => setShowAddDialog(false)}
          >Cancel
          </Button>
          <Button
            color='primary'
            disabled={!addingAllowed && showAddDialog}
            onClick={() => {
              setShowAddDialog(false)
              const newID = genID()
              modifyChallenge({
                notifs: {
                  ...challenge.notifs,
                  [newID]: {
                    id: newID,
                    type: addingData.type,
                    text: addingData.text,
                    team: addingData.team || undefined,
                    teamName: addingTeamName || undefined
                  }
                }
              })
            }}
          >Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

EditStepNotifs.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  modifyData: PropTypes.func
}

export default withStyles(styles)(EditStepNotifs)
