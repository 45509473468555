import React from 'react'
import PropTypes from 'prop-types'
import mockupHome from '../../resources/mockup-ad-home.svg'
import mockupPopup from '../../resources/mockup-ad-popup.svg'
import mockupBottles from '../../resources/mockup-ad-bottles.svg'
import ReviewStep from '../templates/ReviewStep'
import { styled } from '@mui/material/styles'

const DivScreens = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center'
}))

const DivScreenContainer = styled('div')(() => ({
  position: 'relative',
  margin: 2
}))

const ImgScreenBase = styled('img')(() => ({
  height: '24em'
}))

const ImgScreenElIcon = styled('img')(() => ({
  position: 'absolute',
  top: '0',
  right: '12%',
  width: 'auto',
  height: '5.7%'
}))

const ImgScreenElAdHome = styled('img')(() => ({
  position: 'absolute',
  top: '10%',
  right: '2.2%',
  width: '95.6%',
  maxHeight: '80%',
  objectFit: 'contain',
  borderRadius: '3px'
}))

const ImgScreenElAdBottles = styled('img')(() => ({
  position: 'absolute',
  top: '42.5%',
  right: '2.2%',
  width: '95.6%',
  maxHeight: '49.7%',
  objectFit: 'cover',
  objectPosition: 'center top'
}))

const notices = [{
  if: ad => !ad.image,
  fatal: true,
  warn: true,
  text: 'Upload an image for this ad.',
  step: 'content'
}, {
  if: ad => ad.image && !ad.imageDescription,
  fatal: true,
  warn: true,
  text: "Add a description of the content in this ad's image.",
  step: 'content'
}, {
  if: ad => !ad.link || !ad.link.trim(),
  warn: true,
  text: 'No link URL provided. This ad will link to the HidrateSpark store homepage by default.',
  step: 'content'
}, {
  if: ad => ad.link && !(/:\/\//.test(ad.link)),
  warn: true,
  text: "The link URL provided might be incomplete or invalid. Double check you've copied the whole link, including the https:// at the start.",
  step: 'content'
}, {
  if: ad => ad.link && !(/[?&]utm_/.test(ad.link)),
  text: "The link URL provided doesn't include UTM tracking parameters. They won't be added automatically; use a link with tracking data included, or continue without tracking.",
  step: 'content'
}]

const EditStepReview = ({ data: ad, ...props }) => {
  return (
    <ReviewStep objTerm='ad' data={ad} notices={notices} {...props}>
      <DivScreens>
        {ad.location === 'home'
          ? (
            <>
              <DivScreenContainer>
                <ImgScreenBase src={mockupHome} alt='' />
                {ad.icon && <ImgScreenElIcon src={ad.icon} alt='Ad icon' />}
              </DivScreenContainer>
              <DivScreenContainer>
                <ImgScreenBase src={mockupPopup} alt='' />
                {ad.image && (
                  <ImgScreenElAdHome
                    src={ad.image.imageString}
                    alt={ad.accessibilityText}
                  />
                )}
              </DivScreenContainer>
            </>
            )
          : (
            <DivScreenContainer>
              <ImgScreenBase src={mockupBottles} alt='' />
              {ad.image && (
                <ImgScreenElAdBottles
                  src={ad.image.imageString}
                  alt={ad.accessibilityText}
                />
              )}
            </DivScreenContainer>
            )}
      </DivScreens>
    </ReviewStep>
  )
}

EditStepReview.propTypes = {
  data: PropTypes.object
}

export default EditStepReview
