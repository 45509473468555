import React from 'react'

const GenderSelect = ({ userParams, setUserParams }) => {
  const genderOptions = ['male', 'female', 'other']

  return (
    <div>
      <label className='label'>
        <span className='label-text'>Gender</span>
      </label>
      <select
        value={userParams.gender.toLowerCase()}
        onChange={(e) => setUserParams((prev) => ({ ...prev, gender: e.target.value.toLowerCase() }))}
        className='select select-bordered w-full'
      >
        {genderOptions.map((opt) => (
          <option key={opt} value={opt}>
            {`${opt[0].toUpperCase()}${opt.slice(1)}`}
          </option>))}
      </select>
    </div>
  )
}

export default GenderSelect
