import React from 'react'

const GoalGlowToggle = ({ userParams, setUserParams }) => {
  return (
    <div className='form-control'>
      <label className='label cursor-pointer'>
        <span className='label-text'>Glow when I meet my goal</span>
        <input
          type='checkbox'
          className='toggle toggle-primary'
          checked={userParams.goalGlow}
          onChange={() => setUserParams((prev) => ({ ...prev, goalGlow: !prev.goalGlow }))}
        />
      </label>
    </div>
  )
}

export default GoalGlowToggle
