import { searchConstants } from '../constants/searchConstants'

export const userReducer = (state, action) => {
  switch (action.type) {
    case searchConstants.DOMAIN_REQUEST:
      return {
        ...state,
        search: {
          ...state.search,
          searching: true
        }
      }
    case searchConstants.DOMAIN_SUCCESS:
      return {
        ...state,
        search: {
          ...state.search,
          ...action.users
        }
      }
    case searchConstants.DOMAIN_FAILURE:
      return {
        ...state,
        search: {
          ...state.search,
          searching: false
        }
      }
    default:
      return state
  }
}

export const groupCountReducer = (state, action) => {
  switch (action.type) {
    case searchConstants.SUB_GROUP_REQUEST:
      return {
        ...state,
        message: ''
      }
    case searchConstants.SUB_GROUP_SUCCESS:
      return {
        ...state,
        groupCounts: action.groupCounts.counts,
        message: action.groupCounts.message
      }
    case searchConstants.SUB_GROUP_FAILURE:
      return {
        ...state
      }
    default:
      return state
  }
}

export const totalVolumeReducer = (state, action) => {
  switch (action.type) {
    case searchConstants.TOTAL_VOLUME_REQUEST:
      return {
        ...state,
        message: ''
      }
    case searchConstants.TOTAL_VOLUME_SUCCESS:
      return {
        ...state,
        totals: {
          totalWater: action.totals
        },
        message: action.message
      }
    case searchConstants.TOTAL_VOLUME_FAILURE:
      return {
        ...state
      }
    default:
      return state
  }
}

export const groupAverageReducer = (state, action) => {
  switch (action.type) {
    case searchConstants.GROUP_AVERAGE_REQUEST:
      return {
        ...state,
        message: ''
      }
    case searchConstants.GROUP_AVERAGE_SUCCESS:
      return {
        ...state,
        averages: action.averages.averages,
        message: action.averages.message
      }
    case searchConstants.GROUP_AVERAGE_FAILURE:
      return {
        ...state
      }
    default:
      return state
  }
}

export const groupReducer = (state, action) => {
  switch (action.type) {
    case searchConstants.GROUP_REQUEST:
      return {
        ...state
      }
    case searchConstants.GROUP_SUCCESS:
      return {
        ...state,
        groups: action.groups
      }
    case searchConstants.GROUP_FAILURE:
      return {
        ...state,
        groups: []
      }
    default:
      return state
  }
}

export const leaderReducer = (state, action) => {
  switch (action.type) {
    case searchConstants.LEADER_REQUEST:
      return {
        ...state,
        leaderUsers: [],
        anonymize: false
      }
    case searchConstants.LEADER_SUCCESS:
      return {
        ...state,
        leaderUsers: action.leaderUsers.users,
        anonymize: action.leaderUsers.anonymize
      }
    case searchConstants.LEADER_FAILURE:
      return {
        ...state,
        leaderUsers: [],
        anonymize: false
      }
    default:
      return state
  }
}

export const sipReducer = (state, action) => {
  switch (action.type) {
    case searchConstants.MEMBER_REQUEST:
      return {
        ...state,
        user: {
          name: '',
          email: 'no known email',
          dept: 'Department',
          profileImage: {},
          wakeUp: { iso: new Date().toISOString() },
          goToSleep: { iso: new Date().toISOString() },
          timeZone: ''
        },
        sips: [],
        streak: 0,
        day: {
          goal: 1,
          totalAmount: 0
        }
      }
    case searchConstants.MEMBER_SUCCESS:
      return {
        ...state,
        user: action.sips.user,
        sips: action.sips.sips,
        streak: action.sips.streak,
        day: action.sips.day
      }
    case searchConstants.MEMBER_FAILURE:
      return {
        ...state,
        user: {
          name: '',
          email: 'no known email',
          dept: 'Department',
          profileImage: {},
          wakeUp: { iso: new Date().toISOString() },
          goToSleep: { iso: new Date().toISOString() },
          timeZone: ''
        },
        sips: [],
        streak: 0,
        day: {
          goal: 1,
          totalAmount: 0
        }
      }
    default:
      return state
  }
}

export const monthReducer = (state, action) => {
  switch (action.type) {
    case searchConstants.MONTH_REQUEST:
      return {
        ...state,
        monthKey: action.monthKey
      }
    case searchConstants.MONTH_SUCCESS:
      return {
        ...state,
        daysInMonth: action.month.daysInMonth,
        monthData: action.month.dayData
      }
    case searchConstants.MONTH_FAILURE:
      return {
        ...state,
        daysInMonth: 0,
        monthData: []
      }
    default:
      return state
  }
}

export const hardwareReducer = (state, action) => {
  switch (action.type) {
    case searchConstants.HARDWARE_REQUEST:
      return {
        ...state
      }
    case searchConstants.HARDWARE_SUCCESS:
      return {
        ...state,
        hardware: action.hardware
      }
    case searchConstants.HARDWARE_FAILURE:
      return {
        ...state,
        hardware: {}
      }
    default:
      return state
  }
}
