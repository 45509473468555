export const getHydrationScore = (rawScore) => {
  return rawScore >= 0
    ? Math.floor(rawScore * 100)
    : 'NA'
}

export const getHydrationScoreColor = (hydrationScore) => {
  const GREY = '#EFEFEF'
  const RED = '#FF375F'
  const ORANGE = '#FF7900'
  const YELLOW = '#FFDD00'
  const GREEN = '#27F176'

  if (hydrationScore === 'NA') {
    return GREY
  }
  if (hydrationScore >= 0 && hydrationScore < 26) {
    return RED
  }
  if (hydrationScore >= 26 && hydrationScore < 51) {
    return ORANGE
  }
  if (hydrationScore >= 51 && hydrationScore < 76) {
    return YELLOW
  }
  if (hydrationScore >= 76 && hydrationScore < 101) {
    return GREEN
  }
}
