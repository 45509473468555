import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { FormControl, TextField, Grid, Typography, RadioGroup, Select, MenuItem } from '@mui/material'
import RadioOption from '../global/RadioOption'
import FileUpload from '../global/FileUpload'
import { getAllSubgroups, useGroup } from '../global/groupInfo'

const styles = theme => ({
  formLabel: {
    '& p': { marginTop: '1em' }
  },
  challengeIcon: {
    height: '72px',
    width: '72px',
    objectFit: 'cover',
    borderRadius: '72px',
    marginRight: '1em'
  },
  privacyHeading: {
    marginLeft: '.5em',
    marginTop: '1em'
  },
  subgroupSelect: {
    marginLeft: theme.spacing(1)
  },
  subgroupHelp: {
    maxWidth: '30em'
  }
})

const isCodeValid = str => {
  if (!str) return { error: 'Enter a join code.' }
  if (str.length < 5) return { error: 'Join code must be at least 5 characters.' }
  if (str.length > 20) return { error: 'Join code must be no more than 20 characters.' }
  if (str.match(/[ \-_]./)) return { error: 'No spaces, hyphens or underscores allowed.' }
  return { valid: str.toUpperCase() }
}

const DataField = ({ maxLength, codeMode, value, onChange, ...props }) => {
  const [val, setVal] = useState(value)
  const debounceRef = useRef(null)

  return (
    <FormControl fullWidth>
      <TextField
        {...props}
        inputProps={{ maxLength }}
        variant='outlined'
        value={val}
        onChange={event => {
          let newVal = event.target.value
          if (codeMode) {
            newVal = newVal.toUpperCase().replace(/[ \-_]/, '')
          }
          setVal(newVal)
          clearTimeout(debounceRef.current)
          debounceRef.current = setTimeout(() => onChange(newVal), 300)
        }}
        onBlur={() => {
          clearTimeout(debounceRef.current)
          onChange(val)
        }}
      />
    </FormControl>
  )
}

DataField.propTypes = {
  classes: PropTypes.object,
  maxLength: PropTypes.number,
  codeMode: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func
}

const EditStepDetails = ({ classes, data: challenge, modifyData: modifyChallenge }) => {
  const [joinCodeValid, setJoinCodeValid] = useState(true)

  const groupInfo = useGroup()
  const subgroups = getAllSubgroups(groupInfo.root.id)

  return (
    <Grid container spacing={2}>
      <Grid item md={4} lg={3} className={classes.formLabel}>
        <p>Challenge title</p>
      </Grid>
      <Grid item md={8} lg={9}>
        <DataField
          placeholder='My Challenge'
          maxLength={50}
          value={challenge.name}
          onChange={value => {
            modifyChallenge({ name: value })
          }}
        />
      </Grid>

      <Grid item md={4} lg={3} className={classes.formLabel}>
        <p>Challenge description</p>
      </Grid>
      <Grid item md={8} lg={9}>
        <DataField
          placeholder='Describe your challenge here.'
          maxLength={500}
          multiline
          value={challenge.description}
          onChange={value => {
            modifyChallenge({ description: value })
          }}
        />
      </Grid>

      <Grid item md={4} lg={3} className={classes.formLabel}>
        <p>Challenge icon</p>
      </Grid>
      <Grid item md={8} lg={9} container alignItems='center'>
        {challenge.icon
          ? <img src={challenge.icon.imageString} alt='' className={classes.challengeIcon} />
          : challenge.currentIcon
            ? <img src={challenge.currentIcon} alt='' className={classes.challengeIcon} />
            : <img src='https://hidrate-images.s3.amazonaws.com/challengeIcons/droplet_1.jpg' alt='' className={classes.challengeIcon} />}
        <FileUpload
          onDone={data => {
            modifyChallenge({
              icon: {
                name: data.name,
                encoding: data.type,
                imageString: data.base64
              }
            })
          }}
        />
      </Grid>

      <Typography variant='h3' className={classes.privacyHeading}>Privacy</Typography>

      <RadioGroup>
        <Grid container direction='column'>
          <RadioOption
            title='Group'
            detail={`Only members of the ${groupInfo.root.name || 'current'} group can join. Group members will see this challenge on the Challenges tab in the Hidrate app.`}
            value={challenge.privacy === 'GROUP' && challenge.targetGroup === 'ROOT'}
            onClick={() => modifyChallenge({ privacy: 'GROUP', targetGroup: 'ROOT' })}
          />
          {subgroups.length > 0 &&
            <RadioOption
              title='Subgroup'
              detail={`Only members of a selected subgroup of ${groupInfo.root.name || 'current'} can join. Those subgroup members will see this challenge on the Challenges tab in the Hidrate app.`}
              value={challenge.privacy === 'GROUP' && challenge.targetGroup !== 'ROOT'}
              onClick={() => {
                modifyChallenge({
                  privacy: 'GROUP',
                  targetGroup: challenge.targetGroup !== 'ROOT'
                    ? challenge.targetGroup
                    : groupInfo.hasParent
                      ? groupInfo.id
                      : subgroups[0]?.id
                })
              }}
              whileSelected={(
                <>
                  {console.log(challenge.targetGroup)}
                  Create this challenge for:
                  <Select
                    variant='outlined'
                    className={classes.subgroupSelect}
                    value={challenge.targetGroup ?? subgroups[0].id}
                    onChange={event => {
                      modifyChallenge({ targetGroup: event.target.value || 'ROOT' })
                    }}
                  >
                    {subgroups.map(subgroup => (
                      <MenuItem key={subgroup.id} value={subgroup.id}>{subgroup.name}</MenuItem>
                    ))}
                  </Select>
                  <p className={classes.subgroupHelp}>
                    <Typography variant='caption'>
                      {'You can\'t choose a different subgroup after the challenge is created, but you can add and remove people from the chosen subgroup at any time.'}
                    </Typography>
                  </p>
                </>
              )}
            />}
          <RadioOption
            title='Private'
            detail={`Members of the ${groupInfo.root.name || 'main'} group must have the invite code to join. This challenge will not appear in the Challenges tab to users who haven’t joined using the code.`}
            value={challenge.privacy === 'PRIVATE'}
            onClick={() => modifyChallenge({ privacy: 'PRIVATE', targetGroup: 'ROOT' })}
          />
        </Grid>
      </RadioGroup>

      {challenge.privacy === 'PRIVATE' && false &&
        <>
          <Grid item md={4} lg={3} className={classes.formLabel}>
            <p>Join Code</p>
          </Grid>
          <Grid item md={8} lg={9}>
            <DataField
              codeMode
              value={challenge.joinCode}
              onChange={value => {
                const result = isCodeValid(value)
                if (result.error) {
                  setJoinCodeValid(result.error)
                } else {
                  setJoinCodeValid(true)
                  modifyChallenge({ joinCode: result.valid || value })
                }
              }}
            />
            {joinCodeValid !== true &&
              <Typography variant='caption' className={classes.error}>{joinCodeValid}</Typography>}
          </Grid>
        </>}
    </Grid>
  )
}

EditStepDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  modifyData: PropTypes.func
}

export default withStyles(styles)(EditStepDetails)
