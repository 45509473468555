import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { searchService } from '../../services/searchService'
import HidrateCard from '../HidrateCard'

const GroupCount = ({ groupId }) => {
  const [groupCountState, setGroupCountState] = useState({ counts: [] })

  useEffect(() => {
    const abortController = new window.AbortController()

    const fetchData = async () => {
      const result = await searchService.getGroupCounts(groupId, abortController.signal)
      setGroupCountState(result)
    }

    fetchData()

    return () => {
      abortController.abort()
    }
  }, [groupId])

  const renderGroup = (count) => {
    return (
      <div key={count.id} className='flex items-center justify-between'>
        <p className='text-sm text-semibold'>{count.name}</p>
        <p className='text-sm text-semibold'>{count.count}</p>
      </div>
    )
  }

  const mainGroup = groupCountState.counts.find(count => count.id === groupId)
  const subGroups = groupCountState.counts.filter(count => count.id !== groupId)
  return (
    <HidrateCard>
      <div className='text-left'>
        <h3 className='text-xl font-bold mb-2.5 text-black'>Total Users</h3>
        <p className='text-5xl font-bold text-black mb-5'>{(mainGroup || { count: 0 }).count}</p>
        <h4 className='text-lg font-bold mb-1 text-black'>Your Groups</h4>
        {subGroups.length
          ? subGroups.map(count => (
            renderGroup(count)
          ))
          : <p>You have no sub groups</p>}
      </div>
    </HidrateCard>
  )
}

GroupCount.propTypes = {
  groupId: PropTypes.string.isRequired
}

export default GroupCount
