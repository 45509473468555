const POUNDS_PER_KG = 2.20462

export const cmToFtIn = (cm) => {
  let inches = cm / 2.54
  const feet = Math.round(inches / 12)
  inches = Math.round(inches % 12)
  return { feet, inches }
}

export const ftInToCm = (feet, inches) => {
  const centimetersPerInch = 2.54
  const inchesPerFoot = 12
  return (parseInt(feet) * inchesPerFoot + parseInt(inches)) * centimetersPerInch
}

export const lbsToKg = (pounds) => {
  return Math.round(pounds / POUNDS_PER_KG)
}

export const kgToLbs = (kg) => {
  return Math.round(kg * POUNDS_PER_KG)
}

export const mLToOz = (mL) => {
  const conversionFactor = 0.0338140227
  return Math.round(mL * conversionFactor)
}

export const ozToMl = (ounces) => {
  const ouncesPerMilliliter = 0.033814
  return ounces / ouncesPerMilliliter
}
