import React from 'react'

const SipGlowToggle = ({ userParams, setUserParams }) => {
  return (
    <div className='form-control'>
      <label className='label cursor-pointer'>
        <span className='label-text'>Glow when I take a sip</span>
        <input
          type='checkbox'
          className='toggle toggle-primary'
          checked={userParams.sipGlow}
          onChange={() => setUserParams((prev) => ({ ...prev, sipGlow: !prev.sipGlow }))}
        />
      </label>
    </div>
  )
}

export default SipGlowToggle
