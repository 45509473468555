import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import Navbar from './Navbar'
import content from './content'
import './LandingPage.css'

const contentMap = {
  overview: 'general',
  government: 'gov'
}

export default function LandingPage () {
  const params = useParams()
  const variant = contentMap[params.landing] || params.landing || 'general'

  if (!content[variant]) {
    return <Navigate replace to='/' />
  }

  return (
    <div className='hst-landing'>
      <Navbar />
      {content[variant] || content.general}
    </div>
  )
}
