import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import PropTypes from 'prop-types'
import { Typography, Popover } from '@mui/material'
import { searchService } from '../../services/searchService'
import moment from 'moment'
import image from '../../resources/Logomark_TEAL.svg'
import { styled } from '@mui/material/styles'

const MainPanel = styled('div')(() => ({
  marginTop: 1,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap'
}))

const CompleteTrophy = styled('div')(() => ({
  width: '33%',
  padding: 1,
  position: 'relative',
  '& svg': {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'rotate(-90deg)',
    transformOrigin: '50% 50%',
    display: 'none'
  },
  '& img': {
    width: '100%'
  },
  '& span': {
    display: 'block'
  }
}))

const IncompleteTrophy = styled('div')(() => ({
  width: '33%',
  padding: 1,
  position: 'relative',
  '& span': {
    display: 'block'
  },
  '& svg': {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'rotate(-90deg)',
    transformOrigin: '50% 50%',
    display: 'block'
  },
  '& img': {
    width: '100%',
    padding: '5%',
    filter: 'contrast(0) brightness(1.5)'
  }
}))

const PopoverContent = styled('div')(() => ({
  textAlign: 'center',
  padding: '8px 16px',
  '&>*': {
    margin: 0
  }
}))

const Trophy = ({ children, onClick, isComplete }) => {
  return (
    <>
      {isComplete
        ? (<CompleteTrophy onClick={onClick}>{children}</CompleteTrophy>)
        : (<IncompleteTrophy onClick={onClick}>{children}</IncompleteTrophy>)}
    </>
  )
}

Trophy.propTypes = {
  isComplete: PropTypes.bool,
  children: PropTypes.any,
  onClick: PropTypes.func
}

const MonthProgress = ({ groupId, userId }) => {
  const [data, setData] = useState([])
  const [error, setError] = useState('')
  const [popoverAnchor, setPopoverAnchor] = useState(null)
  const [openPopover, setOpenPopover] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const response = await searchService.getTrophies(groupId, userId)
      if (response.message) {
        setData([])
        setError(response.message)
      } else {
        setData(response.awards.sort((a, b) => a.weight - b.weight))
      }
    }
    fetchData()
  }, [groupId, userId])

  if (error) {
    return (
      <Paper sx={{ padding: 2 }}>
        <Typography variant='h3'>Trophies</Typography>
        <Typography variant='body1' sx={{ marginTop: 2 }}>Failed to load trophies for this user.</Typography>
        <Typography variant='subtitle1'>{error}</Typography>
      </Paper>
    )
  }

  if (data.length === 0) {
    return (
      <Paper sx={{ padding: 2 }}>
        <Typography variant='h3'>Trophies</Typography>
        <Typography variant='body1' sx={{ marginTop: 2 }}>No trophies achieved yet.</Typography>
      </Paper>
    )
  }

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant='h3'>Trophies</Typography>
      <MainPanel>
        {data.map(trophy => (
          <React.Fragment key={trophy.trophyId}>
            <Trophy
              key={trophy.trophyId}
              isComplete={trophy.progress === 1}
              onClick={event => {
                setPopoverAnchor(event.currentTarget)
                setOpenPopover(trophy.trophyId)
              }}
            >
              <svg viewBox='0 0 60 60'>
                <circle
                  strokeWidth='2'
                  stroke='#1fadff'
                  strokeDasharray='158 158' // circumference with r=25
                  strokeDashoffset={157 - trophy.progress * 157}
                  fill='transparent'
                  r='25'
                  cx='30'
                  cy='30'
                />
              </svg>
              <img src={trophy.image ?? image} alt={trophy.icon} />
              <Typography variant='caption'>{trophy.name}</Typography>
            </Trophy>
            <Popover
              open={openPopover === trophy.trophyId}
              anchorEl={popoverAnchor}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={() => {
                setPopoverAnchor(null)
                setOpenPopover('')
              }}
            >
              <PopoverContent>
                <Typography variant='h6'>{trophy.name}</Typography>
                <p>{trophy.criteriaDescription}</p>
                <Typography variant='subtitle1'>{!trophy.awarded ? `${Math.floor(trophy.progress * 100)}% complete` : `Awarded on ${moment(trophy.awarded).format('MMM D, Y')}`}</Typography>
              </PopoverContent>
            </Popover>
          </React.Fragment>
        ))}
      </MainPanel>
    </Paper>
  )
}

MonthProgress.propTypes = {
  userId: PropTypes.string.isRequired,
  dayClicked: PropTypes.func,
  groupId: PropTypes.string
}

export default MonthProgress
