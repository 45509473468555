import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { authService } from '../../services/authService'
import { groupService } from '../../services/groupService'
import home from '../../resources/sidebar/Home-Icon.svg'
import homeActive from '../../resources/sidebar/Home-Icon-Active.svg'
import notifications from '../../resources/sidebar/Notification-Icon.svg'
import notificationsActive from '../../resources/sidebar/Notification-Icon-Active.svg'

import { useLocation } from 'react-router-dom'

import users from '../../resources/sidebar/Users-Icon.svg'
import usersActive from '../../resources/sidebar/Users-Icon-Active.svg'
import challenges from '../../resources/sidebar/Challenges-Icon.svg'
import challengesActive from '../../resources/sidebar/Challenges-Icon-Active.svg'

// File name to avoid being blocked by browser ad blockers
import advertisements from '../../resources/sidebar/This-Icon.svg'
import advertisementsActive from '../../resources/sidebar/This-Icon-Active.svg'
import HidrateLogo from './HidrateLogo'

const renderList = ({ list, navigate, path }) => {
  const basePathParts = path.match(/.*(dashboard|subgroup)\/\w*\//)// [0]
  let basePath = path
  if (basePathParts && basePathParts.length) {
    basePath = basePathParts[0]
  }

  const handleClick = (isHere, object) => {
    if (!isHere) {
      navigate(basePath + object.view)
    }
  }

  return (
    list.map(object => {
      const isHere = basePath + object.view === path
      return (
        <li key={object.text}>
          <a
            className='flex space-x-2'
            onClick={() => { handleClick(isHere, object) }}
          >
            {isHere
              ? (
                <>
                  <img src={object.activeIcon} alt={object.text} />
                  <p className='text-primary font-bold'>{object.text} </p>
                </>
                )
              : (
                <>
                  <img src={object.icon} alt={object.text} />
                  <p>  {object.text}  </p>
                </>
                )}
          </a>
        </li>
      )
    })
  )
}

const NavSide = ({ navigate, groupId }) => {
  const location = useLocation()
  const path = location.pathname

  const [permissions, setPermissions] = useState(authService.blankPermissions)
  const [groupLogoUrl, setGroupLogoUrl] = useState('')

  const getGroupDetails = async () => {
    try {
      const logoUrl = await groupService.getGroupLogoUrl()
      setGroupLogoUrl(logoUrl ?? '')
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getGroupDetails()
    return authService.listenToPermissions(groupId, setPermissions)
  }, [groupId])

  const list = []

  if (permissions && permissions.check('userData', 'viewer')) {
    list.push({ text: 'Home', view: 'home', icon: home, activeIcon: homeActive })
    list.push({ text: 'Users', view: 'users', icon: users, activeIcon: usersActive })
    // list.push({ text: 'Scores', view: 'scores', icon: users, activeIcon: usersActive })
  }
  if (permissions && permissions.check('notif', 'viewer')) {
    list.push({ text: 'Notifications', view: 'notifications', icon: notifications, activeIcon: notificationsActive })
  }
  if (permissions && permissions.check('ad', 'viewer')) {
    list.push({ text: 'Ads', view: 'advertisements', icon: advertisements, activeIcon: advertisementsActive })
  }
  if (permissions && permissions.check('challenge', 'viewer')) {
    list.push({ text: 'Challenges', view: 'challenges', icon: challenges, activeIcon: challengesActive })
  }
  return (
    <>
      <div className='flex items-center justify-around mb-2'>
        {!groupLogoUrl
          ? (<HidrateLogo width={125} />)
          : (
            <>
              <HidrateLogo width={125} />
              <div className='w-[0.5px] h-[50px] bg-gray-600 m-[10px]' />
              <img
                src={groupLogoUrl}
                alt='HidrateSpark+ Group Logo'
                className='max-w-[100px]'
              />
            </>
            )}
      </div>
      {renderList({
        list,
        navigate,
        path
      })}
    </>
  )
}

NavSide.propTypes = {
  navigate: PropTypes.func.isRequired,
  user: PropTypes.object,
  groupId: PropTypes.string.isRequired
}

export default NavSide
