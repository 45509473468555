import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, FormControl, Select, MenuItem, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import moment from 'moment'

const Divider = styled('hr')(() => ({
  border: 'none',
  borderTop: '1px solid #ddd',
  margin: '2em'
}))

const EditStep = ({ data: ad, modifyData: modifyAd }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container>
        <Grid
          item xs={12} md={3} lg={2} sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography variant='h4'>Start Date</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            autoOk
            disableToolbar
            disablePast
            inputVariant='outlined'
            format='M/dd/yyyy h:mm a'
            renderInput={(params) => <TextField {...params} />}
            InputAdornmentProps={{ position: 'start' }}
            value={ad.startDate || moment().add(1, 'day')}
            onChange={value => {
              const mod = { startDate: moment(value) }
              if (mod.startDate.isSameOrAfter(ad.endDate)) {
                mod.endDate = moment(mod.startDate)
              }
              modifyAd(mod)
            }}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container>
        <Grid
          item xs={12} md={3} lg={2} sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography variant='h4'>End Date</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl variant='outlined'>
            <Select
              value={ad.useEndDate ? 'date' : 'indefinite'}
              onChange={event => {
                modifyAd({ useEndDate: event.target.value === 'date' })
              }}
            >
              <MenuItem value='indefinite'>Indefinite</MenuItem>
              <MenuItem value='date'>Choose date...</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {ad.useEndDate &&
          <Grid item xs={12} md={4}>
            <DatePicker
              autoOk
              disableToolbar
              disablePast
              inputVariant='outlined'
              format='M/dd/yyyy h:mm a'
              renderInput={(params) => <TextField {...params} />}
              InputAdornmentProps={{ position: 'start' }}
              value={ad.endDate || moment(ad.startDate).add(7, 'days')}
              onChange={value => {
                const mod = { endDate: moment(value) }
                if (mod.endDate.isSameOrBefore(ad.startDate)) {
                  mod.endDate = moment(ad.startDate)
                }
                modifyAd(mod)
              }}
            />
          </Grid>}
      </Grid>
      <p>
        {ad.useEndDate
          ? `The ad will run for ${ad.endDate.diff(ad.startDate, 'days')} days. You can change this at any time.`
          : 'The ad will run until you manually unpublish it, or set an end date later.'}
      </p>
    </LocalizationProvider>
  )
}

EditStep.propTypes = {
  data: PropTypes.object,
  modifyData: PropTypes.func
}

export default EditStep
