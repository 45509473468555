import React from 'react'

const Bars = (classes, height, width, highest, barWidth, data, transform, onClick, format) => {
  const graphHeight = height * 0.90
  const graphWidth = width - 60

  const days = data
  const barWidthPixels = (graphWidth * (barWidth / 100))

  const bars = []

  for (let i = 0; i < days.length; i++) {
    const day = days[i]
    const waterPercent = transform(day.water, highest) / 100
    const waterPixels = graphHeight - (graphHeight * waterPercent)
    // const offset = Math.min(barWidthPixels + (barWidthPixels / 2), graphWidth * 0.15)
    // const offset = graphWidth * 0.05
    const offset = 60
    const centerPoint = (i * barWidthPixels) + offset
    const visualWidth = barWidthPixels / 2

    bars.push(
      <g
        key={`bar-${i}`}
        className={classes.graphBar + (onClick ? ' clickable' : '')}
        onClick={onClick && (() => onClick(day.dayKey))}
      >
        <rect
          className={classes.bar}
          rx={visualWidth < 0 ? 0 : visualWidth / 2}
          ry={visualWidth < 0 ? 0 : visualWidth / 2}
          width={visualWidth}
          height={graphHeight * waterPercent}
          x={centerPoint}
          y={waterPixels}
        />
        <text
          className={classes.valLabel}
          x={centerPoint + visualWidth / 2}
          y={waterPixels - 5}
          textAnchor='middle'
          style={{ fontFamily: 'muli', font: 'bold 12px sans-serif', fill: '#585858' }}
        >
          {format ? format(day.water) : Math.round(day.water)}
        </text>
        <text
          x={centerPoint + visualWidth / 2}
          y={height - 25}
          textAnchor='middle'
          style={{ fontFamily: 'muli', font: 'bold 12px sans-serif', fill: '#CFCFCF' }}
        >
          {day.label || i + 1}
        </text>
      </g>
    )
  }

  return bars
}

export default Bars
