import React from 'react'
import PropTypes from 'prop-types'

const HidrateLogo = ({ height, width }) => {
  const fillColor = '#1fadff'
  return (
    <div>
      <svg id='b' data-name='Layer 2' xmlns='http://www.w3.org/2000/svg' height={height} width={width} viewBox='0 0 1000 324.08'>
        <g id='c' data-name='Layer 1'>
          <g>
            <g>
              <g>
                <path fill={fillColor} d='m146.54,19.27c0,9.26-7.51,16.77-16.77,16.77s-16.77-7.51-16.77-16.77V2.51h16.77c9.26,0,16.77,7.51,16.77,16.77Z' />
                <rect fill={fillColor} x='113' y='46' width='33.54' height='100.61' />
              </g>
              <path fill={fillColor} d='m226.72,2.46v46.41c-5.25-1.85-10.89-2.87-16.77-2.87-27.78,0-50.3,22.52-50.3,50.3s22.52,50.3,50.3,50.3,50.29-22.51,50.3-50.28h0V2.46h-33.54Zm-16.77,110.61c-9.26,0-16.77-7.51-16.77-16.77s7.51-16.77,16.77-16.77,16.77,7.51,16.77,16.77-7.51,16.77-16.77,16.77Z' />
              <polygon fill={fillColor} points='67.07 2.75 67.07 54.44 33.54 54.44 33.54 2.75 0 2.75 0 54.44 0 87.97 0 146.6 33.54 146.6 33.54 87.97 67.07 87.97 67.07 146.6 100.61 146.6 100.61 87.97 100.61 54.44 100.61 2.75 67.07 2.75' />
              <path fill={fillColor} d='m449.27,95.39c-.49-27.36-22.81-49.39-50.28-49.39s-50.3,22.52-50.3,50.3,22.52,50.3,50.3,50.3c5.88,0,11.52-1.02,16.77-2.87v2.87h33.54v-51.21h-.02Zm-50.28,17.68c-9.26,0-16.77-7.51-16.77-16.77s7.51-16.77,16.77-16.77,16.77,7.51,16.77,16.77-7.51,16.77-16.77,16.77Z' />
              <path fill={fillColor} d='m306.91,63.56v-17.57h-33.54v100.61h33.54v-38.08c.27-12.69,5.91-20.41,17.09-23.41,4.23-1.14,8.68-1.6,13.31-1.94v-37.18c-13.55.38-23.3,6.35-30.4,17.57Z' />
              <path fill={fillColor} d='m514.06,113.05c-4.87,0-8.82-3.95-8.83-8.82v-24.69h21.11v-33.54h-21.11v-29.43l-27.46,8.45v.02c-3.35,1.09-5.81,4.11-6.08,7.74-.02.21-.03.43-.03.64v8.83h.03v3.75h-16.37v33.54h16.37v33.52c0,18.52,15.01,33.54,33.54,33.54h21.11v-33.54h-12.29Z' />
              <path fill={fillColor} d='m613.59,53.44c-20.3-12.51-48.53-8.89-64.44,8.1-16.3,17.41-18.14,47.01-3.94,65.25,13.27,17.04,31.73,21.31,52.28,19.38,13.14-1.23,24.61-6.25,33.16-17.28-7.11-4.9-14.05-9.77-21.13-14.42-.84-.55-2.86-.4-3.72.22-6.58,4.74-13.95,5.53-21.72,4.54-7.64-.96-13.44-5.44-14.38-11.3h64.93c5.31-20.91-2.58-43.12-21.04-54.5Zm-44.74,32.38c2.29-8.77,8.43-12.97,18.52-12.97,8.98,0,15.05,4.45,17.39,12.97h-35.91Z' />
            </g>
            <g>
              <path fill={fillColor} d='m322.97,272.8c-.49-27.36-22.81-49.39-50.28-49.39s-50.3,22.52-50.3,50.3,22.52,50.3,50.3,50.3c5.88,0,11.52-1.02,16.77-2.87v2.87h33.54v-51.21h-.02Zm-50.28,17.68c-9.26,0-16.77-7.51-16.77-16.77s7.51-16.77,16.77-16.77,16.77,7.51,16.77,16.77-7.51,16.77-16.77,16.77Z' />
              <path fill={fillColor} d='m190.14,230.85c-20.3-12.51-48.53-8.89-64.44,8.1-16.3,17.41-18.14,47.01-3.94,65.25,13.27,17.04,31.73,21.31,52.28,19.38,13.14-1.23,24.61-6.25,33.16-17.28-7.11-4.9-14.05-9.77-21.13-14.42-.84-.55-2.86-.4-3.72.22-6.58,4.74-13.95,5.53-21.72,4.54-7.64-.96-13.44-5.44-14.38-11.3h64.93c5.31-20.91-2.58-43.12-21.04-54.5Zm-44.74,32.38c2.29-8.77,8.43-12.97,18.52-12.97,8.98,0,15.05,4.45,17.39,12.97h-35.91Z' />
              <polygon fill={fillColor} points='67.07 180.16 67.07 231.84 33.54 231.84 33.54 180.16 0 180.16 0 231.84 0 265.38 0 324.01 33.54 324.01 33.54 265.38 67.07 265.38 67.07 324.01 100.61 324.01 100.61 265.38 100.61 231.84 100.61 180.16 67.07 180.16' />
              <path fill={fillColor} d='m440.03,290.46c-4.87,0-8.82-3.95-8.83-8.82v-24.69h21.11v-33.54h-21.11v-29.43l-27.46,8.45v.02c-3.35,1.09-5.81,4.11-6.08,7.74-.02.21-.03.43-.03.64v8.83h.03v3.75h-16.37v33.54h16.37v33.52c0,18.52,15.01,33.54,33.54,33.54h21.11v-33.54h-12.29Z' />
              <path fill={fillColor} d='m531.44,324.01h33.54v-50.3c0-27.78-22.52-50.3-50.3-50.3-5.88,0-11.52,1.02-16.77,2.87v-46.41h-33.54v93.87h0v50.28s33.54,0,33.54,0v-50.3c0-9.26,7.51-16.77,16.77-16.77s16.77,7.51,16.77,16.77v50.3Z' />
              <g>
                <path fill={fillColor} d='m595.56,308.89c-.58-1.33-1.36-2.49-2.34-3.47-.98-.98-2.14-1.75-3.48-2.32-1.34-.56-2.76-.84-4.28-.84s-2.95.28-4.3.84c-1.35.56-2.53,1.33-3.53,2.32-1,.98-1.79,2.14-2.37,3.47-.58,1.33-.87,2.76-.87,4.27s.29,2.93.87,4.25c.58,1.32,1.37,2.47,2.37,3.45,1,.98,2.18,1.75,3.53,2.32,1.35.56,2.79.84,4.3.84s2.94-.28,4.28-.84c1.34-.56,2.5-1.33,3.48-2.32.98-.98,1.76-2.13,2.34-3.45.58-1.32.87-2.73.87-4.25s-.29-2.93-.87-4.27Zm-.99,8.1c-.53,1.21-1.24,2.26-2.14,3.16-.9.9-1.95,1.62-3.15,2.15-1.2.53-2.48.8-3.83.8s-2.67-.27-3.87-.8c-1.2-.53-2.26-1.25-3.18-2.15-.91-.9-1.64-1.95-2.17-3.16-.53-1.21-.79-2.48-.79-3.83s.26-2.63.79-3.83c.53-1.21,1.25-2.26,2.17-3.16.91-.9,1.97-1.62,3.18-2.15,1.2-.53,2.49-.8,3.87-.8s2.62.27,3.83.8c1.2.53,2.25,1.25,3.15,2.15.9.9,1.61,1.95,2.14,3.16.53,1.21.79,2.48.79,3.83s-.27,2.63-.79,3.83Z' />
                <path fill={fillColor} d='m585.95,313.74c.81-.06,1.55-.36,2.23-.89.68-.53,1.02-1.33,1.02-2.39,0-1.13-.36-1.97-1.07-2.53-.71-.56-1.68-.84-2.91-.84h-4.08v12.11h1.06v-5.31h2.65l3.56,5.31h1.25l-3.72-5.45Zm-1.44-.68h-2.3v-5.07h3.01c.73,0,1.4.16,2.01.48.61.32.91.96.91,1.93,0,1.03-.33,1.73-.98,2.1-.65.37-1.54.55-2.66.55Z' />
              </g>
              <polygon fill={fillColor} points='369.02 231.84 369.02 180.16 335.49 180.16 335.49 231.84 335.49 265.38 335.49 324.01 369.02 324.01 369.02 265.38 369.02 231.84' />
            </g>
            <g>
              <path fill={fillColor} d='m861.98,277.15c-4.93,4.93-11.61,7.69-18.57,7.69s-13.65-2.77-18.57-7.69l-41.79-41.79c-10.26-10.26-10.26-26.89,0-37.15,10.25-10.26,26.9-10.26,37.15,0l23.22,23.22,59.15-59.14-45.21-63.33c-5.29-7.14-15.36-8.65-22.5-3.37-1.28.95-2.42,2.08-3.37,3.37,0,0-67.2,94.02-67.2,94.34-15.12,26.11-14.88,58.36.63,84.24,24.65,43.91,80.24,59.52,124.15,34.87,14.61-8.2,26.67-20.26,34.87-34.87,12.7-21.19,15.01-46.62,7.18-69.48l-69.11,69.11Z' />
              <path fill={fillColor} d='m975.49,24.5C958.43,7.44,935.29-1.35,910.95.17c-21.17,1.32-40.85,11.42-55.85,26.42l-11.56,11.56-11.72-11.72c-32.33-32.33-85.26-35.02-118.49-3.62-16.91,15.98-26.25,37.7-26.25,60.86s8.7,43.36,24.51,59.16l31.76,31.55c4.81,4.78,12.76,4.13,16.73-1.37h0c3.18-4.4,2.68-10.46-1.17-14.29l-31.71-31.5c-11.63-11.63-18.04-27.1-18.04-43.55s6.68-32.57,18.79-44.29c24.45-23.66,64.04-21.58,88.1,2.48l16.67,16.67c2.89,2.89,6.74,4.49,10.84,4.49s7.94-1.59,10.84-4.49l16.77-16.77c10.67-10.67,24.51-18.1,39.54-19.43,18.54-1.64,36.25,4.83,49.21,17.79,12.96,12.96,19.43,30.67,17.79,49.21-1.33,15.03-8.76,28.87-19.43,39.54l-114.31,114.3-34.33-34.33c-4.31-4.31-11.3-4.31-15.61,0-4.31,4.31-4.31,11.3,0,15.61l42.14,42.14c2.07,2.07,4.88,3.23,7.8,3.23s5.73-1.16,7.8-3.23l121.67-121.67c15-15,25.1-34.68,26.42-55.86,1.51-24.34-7.27-47.48-24.34-64.54Z' />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

HidrateLogo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
}

export default HidrateLogo
