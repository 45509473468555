import React, { useEffect, useState } from 'react'
import { addWeeks, format, isAfter, isBefore, subWeeks, subYears } from 'date-fns'
import { updateUserParams } from '../../../services/userDashboardService'
import HeightInput from './HeightInput'
import WeightInput from './WeightInput'
import BirthdayInput from './BirthdayInput'
import ActivityLevelSelect from './ActivityLevelSelect'
import GenderSelect from './GenderSelect'
import WakeSleepSelect from './WakeSleepSelect'
import GoalGlowToggle from './GoalGlowToggle'
import AlwaysGlowToggle from './AlwaysGlowToggle'
import PushNotificationAlwaysToggle from './PushNotificationAlwaysToggle'
import SipGlowToggle from './SipGlowToggle'
import LightTypeSelect from './LightTypeSelect'
import AppNotificationCountSelect from './AppNotificationCountSelect'
import LightNotificationCountSelect from './LightNotificationCountSelect'
import BreastfeedingToggle from './BreastfeedingToggle'
import PregnancyToggle from './PregnancyToggle'
import PregnancyDueDateSelect from './PregnancyDueDateSelect'

const checkIfPregnant = (dueDate) => {
  if (!dueDate) {
    return false
  }
  const twoWeeksAfterDueDate = addWeeks(new Date(dueDate), 2)
  const fortyWeeksBeforeDueDate = subWeeks(new Date(dueDate), 40)

  return (isBefore(new Date(), twoWeeksAfterDueDate) && isAfter(new Date(), fortyWeeksBeforeDueDate))
}

const PersonalParamsModal = ({ units, user, day }) => {
  const userParamsDefault = {
    height: 170, // cm
    weight: 80, // kg
    birthday: subYears(new Date(), 25), // 25 yo
    activityLevel: 1,
    gender: 'other',
    wakeUp: new Date('1970-01-01T08:00:00.000Z'),
    goToSleep: new Date('1970-01-01T20:00:00.000Z'),
    pushNotificationAlways: true,
    alwaysGlow: true,
    goalGlow: true,
    sipGlow: true,
    lightType: 44,
    appNotificationCount: 8,
    lightNotificationCount: 8,
    breastfeeding: false,
    pregnancyDueDate: null
  }

  const dayDate = day?.date ?? format(new Date(), 'yyyy-MM-dd')

  const [userParams, setUserParams] = useState(userParamsDefault)
  const [isSaving, setIsSaving] = useState(false)
  const [isPregnant, setIsPregnant] = useState(false)

  useEffect(() => {
    if (!isPregnant) {
      setUserParams((prev) => ({ ...prev, pregnancyDueDate: null }))
    }
  }, [])

  useEffect(() => {
    const latestParams = { ...userParams }
    if (user !== undefined) {
      Object.keys(user).forEach(key => {
        if (key === 'wakeUp') {
          latestParams.wakeUp = new Date(user.wakeUp.iso)
        } else if (key === 'goToSleep') {
          latestParams.goToSleep = new Date(user.goToSleep.iso)
        } else if (key === 'birthday') {
          latestParams.birthday = new Date(user.birthday.iso)
        } else if (key === 'pregnancyDueDate') {
          setIsPregnant(checkIfPregnant(user.pregnancyDueDate))
          latestParams[key] = user[key]
        } else if (key in userParamsDefault) {
          latestParams[key] = user[key]
        }
      })
      console.log('user', user)
      console.log('latestparams', latestParams)
      setUserParams(latestParams)
    }
  }, [user])

  const handleSave = async () => {
    try {
      console.log('userparams', userParams)

      setIsSaving(true)
      const res = await updateUserParams({
        userId: user.objectId,
        userParams,
        dateKey: dayDate
      })
      console.log('saved!')
      console.log(res)
    } catch (e) {
      console.log(e)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <dialog id='personal_params_modal' className='modal'>
      <div className='modal-box max-w-5xl w-full'>
        <form method='dialog'>
          <button className='btn btn-sm btn-circle btn-ghost absolute right-2 top-2'>✕</button>
        </form>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>

          <div className='flex flex-col space-y-5'>

            <h2 className='text-xl font-semibold'>Personal details</h2>

            <HeightInput
              units={units}
              userParams={userParams}
              setUserParams={setUserParams}
            />

            <WeightInput
              units={units}
              userParams={userParams}
              setUserParams={setUserParams}
            />

            <GenderSelect
              userParams={userParams}
              setUserParams={setUserParams}
            />

            <ActivityLevelSelect
              userParams={userParams}
              setUserParams={setUserParams}
            />

            <BirthdayInput
              userParams={userParams}
              setUserParams={setUserParams}
            />

            <WakeSleepSelect
              userParams={userParams}
              setUserParams={setUserParams}
            />

            {userParams.gender !== 'male' && (
              <BreastfeedingToggle
                userParams={userParams}
                setUserParams={setUserParams}
              />)}

            {userParams.gender !== 'male' && (
              <PregnancyToggle
                isPregnant={isPregnant}
                setIsPregnant={setIsPregnant}
              />)}

            {isPregnant && (
              <PregnancyDueDateSelect
                isPregnant={isPregnant}
                setIsPregnant={setIsPregnant}
              />
            )}

          </div>

          <div className='flex flex-col space-y-5'>

            <h2 className='text-xl font-semibold'>App preferences</h2>

            <AppNotificationCountSelect
              userParams={userParams}
              setUserParams={setUserParams}
            />

            <LightNotificationCountSelect
              userParams={userParams}
              setUserParams={setUserParams}
            />

            <LightTypeSelect
              userParams={userParams}
              setUserParams={setUserParams}
            />

            <PushNotificationAlwaysToggle
              userParams={userParams}
              setUserParams={setUserParams}
            />

            <AlwaysGlowToggle
              userParams={userParams}
              setUserParams={setUserParams}
            />

            <GoalGlowToggle
              userParams={userParams}
              setUserParams={setUserParams}
            />

            <SipGlowToggle
              userParams={userParams}
              setUserParams={setUserParams}
            />

          </div>

        </div>

        <div className='flex items-center justify-end mt-5'>
          <button
            className='btn btn-primary'
            disabled={isSaving}
            onClick={handleSave}
          >
            {isSaving && <span className='loading loading-spinner' />}
            Save
          </button>
        </div>
      </div>
    </dialog>
  )
}

export default PersonalParamsModal
