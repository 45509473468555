import axios from 'axios'
const config = { apiUrl: process.env.REACT_APP_API_URL }
const authHeader = require('./authHeader')

function getGroupNotifications (groupId, signal) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader(),
      signal
    }

    return axios.get(`${config.apiUrl}group/${groupId}/notifications`, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

const markScheduledNotificationsForDelete = async (notificationId, groupId) => {
  if (notificationId && groupId) {
    const options = {
      headers: authHeader.authHeader()
    }

    const res = await axios.post(`${config.apiUrl}group/${groupId}/notifications/delete`, { notificationId }, options)
    return res
  }
  throw new Error('Must provide notification id and group id.')
}

function getUsers (groupId, page, rowsPerPage) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.get(`${config.apiUrl}group/${groupId}/notifications/users?page=${page}&limit=${rowsPerPage}`, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function getSubGroups (groupId) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.get(`${config.apiUrl}group/${groupId}/notifications/subgroups`, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function scheduleOneTimeGroupNotification (groupId, notificationId, arrayOfTargets, sendDateTime) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.post(`${config.apiUrl}group/${groupId}/notifications/onetime`, { notificationId, arrayOfTargets, sendDateTime }, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function scheduleOneTimeSubGroupNotification (groupId, notificationId, subgroupId, sendDateTime) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.post(`${config.apiUrl}group/${groupId}/notifications/subgrouponetime`, { notificationId, subgroupId, sendDateTime }, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function scheduleRecurringSubGroupNotification (groupId, notificationId, subgroupId, startDateTime, endDateTime, repeatDays) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.post(`${config.apiUrl}group/${groupId}/notifications/subgrouprecurring`, { notificationId, subgroupId, startDateTime, endDateTime, repeatDays }, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function scheduleRecurringGroupNotification (groupId, notificationId, arrayOfTargets, startDateTime, endDateTime, repeatDays) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.post(`${config.apiUrl}group/${groupId}/notifications/recurring`, { notificationId, arrayOfTargets, startDateTime, endDateTime, repeatDays }, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function createNewGroupNotification (groupId, notificationText) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.post(`${config.apiUrl}group/${groupId}/notifications/new`, { notificationText }, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function publishNotification (groupId, notificationId, publish) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.post(`${config.apiUrl}group/${groupId}/notifications/publish`, { notificationId, publish }, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

export const notificationsService = {
  getGroupNotifications,
  scheduleOneTimeGroupNotification,
  markScheduledNotificationsForDelete,
  createNewGroupNotification,
  scheduleOneTimeSubGroupNotification,
  scheduleRecurringGroupNotification,
  scheduleRecurringSubGroupNotification,
  publishNotification,
  getUsers,
  getSubGroups
}
