import React from 'react'
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
// A theme with custom primary and secondary color.
// It's optional.

const CONSUME_STOP = '#0FB4D8'
const CONSUME_START = '#97EDFF'
const GOAL = 'rgb(3,252,82)'
const FOREGROUND_TILE = 'white'
const BACKGROUND = 'transparent'

const theme = createTheme({
  palette: {
    primary: {
      main: '#1FADFF'
    },
    secondary: {
      main: '#FFFFFF'
    },
    text: {
      main: '#1C1C22'
    },
    main: {
      foreground: FOREGROUND_TILE,
      background: BACKGROUND
    },
    graph: {
      goal: GOAL,
      consumedGradient: `linear-gradient(${CONSUME_STOP}, ${CONSUME_START})`,
      consumedStart: CONSUME_START,
      consumedStop: CONSUME_STOP
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Muli',
    h1: {
      fontSize: 50,
      fontWeight: '800',
      color: '#1C1C22'
    },
    h2: {
      fontSize: 36,
      fontWeight: '800',
      color: '#1F1F1F'
    },
    h3: {
      fontSize: 20,
      fontWeight: '800',
      color: '#585858'
    },
    h4: {
      fontSize: 16,
      fontWeight: '800',
      color: '#3E3E3E'
    },
    h5: {
      fontSize: 15,
      fontWeight: '400',
      color: '#3E3E3E'
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: '800',
      color: '#CFCFCF'
    }
    // body1: {
    //   fontSize: 12,
    //   fontWeight: '400',
    //   color: '#818081'
    // },
    // body2: {
    //   fontSize: 15,
    //   fontWeight: '400',
    //   color: '#CFCFCF'
    // },
    // caption: {
    //   fontSize: 11,
    //   fontWeight: '400',
    //   color: '#2E2E2E'
    // }
  },
  zIndex: {
    mobileStepper: 1000,
    appBar: 1100,
    drawer: 900,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
    popper: 1700
  }
})

function withRoot (Component) {
  function WithRoot (props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Component {...props} />
        </ThemeProvider>
      </StyledEngineProvider>
    )
  }

  return WithRoot
}

export default withRoot
