import React from 'react'

const AlwaysGlowToggle = ({ userParams, setUserParams }) => {
  return (
    <div className='form-control'>
      <label className='label cursor-pointer'>
        <span className='label-text'>Always send glow reminders</span>
        <input
          type='checkbox'
          className='toggle toggle-primary'
          checked={userParams.alwaysGlow}
          onChange={() => setUserParams((prev) => ({ ...prev, alwaysGlow: !prev.alwaysGlow }))}
        />
      </label>
    </div>
  )
}

export default AlwaysGlowToggle
