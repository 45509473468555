import React, { useState } from 'react'
import { EmbedVideo } from './embeds'
import Lightbox from './Lightbox'
import './VideoBanner.css'

export default function VideoBanner ({ full, ...props }) {
  const [showLightbox, setShowLightbox] = useState(false)

  return (
    <>
      <div className={'hst-videoBanner' + (full ? ' hst-videoBannerFull' : '')} {...props}>
        <img src='/assets/video-thumb.jpg' alt='HidrateSpark+ Tour Video' role='button' onClick={() => setShowLightbox(true)} />
      </div>
      <Lightbox open={showLightbox} onClose={() => setShowLightbox(false)}>
        <EmbedVideo />
      </Lightbox>
    </>
  )
}
