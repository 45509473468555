import React from 'react'

const LoadingFullScreen = () => {
  return (
    <div className='flex flex-col items-center justify-center h-full'>
      <span className='loading loading-spinner loading-md' />
    </div>
  )
}

export default LoadingFullScreen
