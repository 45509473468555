import React from 'react'
import App from './App.js'
import withRoot from './withRoot'

function AppWrapper () {
  return (
    <App />
  )
}

export default withRoot(AppWrapper)
