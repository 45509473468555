import React from 'react'

export function EmbedVideo () {
  return (
    <>
      <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
        <iframe
          title='Vimeo Player'
          src='https://player.vimeo.com/video/670326833?h=e767650b9b&color=40BBF5&title=0&byline=0&portrait=0'
          style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
          allow='autoplay; fullscreen; picture-in-picture'
          allowFullScreen
        />
      </div>
    </>
  )
}

export function EmbedForm () {
  return (
    <iframe
      className='ns-embedded-form'
      src='https://7072555.extforms.netsuite.com/app/site/crm/externalleadpage.nl?compid=7072555&formid=3&h=AAFdikaILG4y4fZiQ4J0Cit7PveTmSrc7jlWZ9cBG9p7ic9xQIE'
      width='100%'
    >
    </iframe>
  )
}
