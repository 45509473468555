import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, RadioGroup, Select, MenuItem } from '@mui/material'
import RadioOption from '../global/RadioOption'
import { getAllSubgroups, useGroup } from '../global/groupInfo'
import { styled } from '@mui/material/styles'

const SubGroupHelp = styled('p')(() => ({
  maxWidth: '30em'
}))

const EditStepTarget = ({ data: ad, modifyData: modifyAd }) => {
  const groupInfo = useGroup()
  const subgroups = getAllSubgroups(groupInfo.root.id)

  return (
    <Grid container spacing={2}>
      <RadioGroup>
        <Grid container direction='column'>
          <RadioOption
            title='Group'
            detail={`All members of the ${groupInfo.root.name || 'current'} group will see this ad.`}
            value={ad.targetGroup === 'ROOT'}
            onClick={() => modifyAd({ targetGroup: 'ROOT' })}
          />
          {subgroups.length > 0 &&
            <RadioOption
              title='Subgroup'
              detail={`Only members of a selected subgroup of ${groupInfo.root.name || 'this group'} will see this ad.`}
              value={ad.targetGroup !== 'ROOT'}
              onClick={() => {
                modifyAd({
                  targetGroup: ad.targetGroup !== 'ROOT'
                    ? ad.targetGroup
                    : groupInfo.hasParent
                      ? groupInfo.id
                      : subgroups[0]?.id
                })
              }}
              whileSelected={(
                <>
                  Create this ad for:
                  <Select
                    variant='outlined'
                    sx={{ marginLeft: 1 }}
                    value={ad.targetGroup}
                    onChange={event => {
                      modifyAd({ targetGroup: event.target.value || 'ROOT' })
                    }}
                  >
                    {subgroups.map(subgroup => (
                      <MenuItem key={subgroup.id} value={subgroup.id}>{subgroup.name}</MenuItem>
                    ))}
                  </Select>
                  <SubGroupHelp>
                    <Typography variant='caption'>
                      You can’t choose a different subgroup after the ad is created, but you can add and remove people from the chosen subgroup at any time.
                    </Typography>
                  </SubGroupHelp>
                </>
              )}
            />}
        </Grid>
      </RadioGroup>
    </Grid>
  )
}

EditStepTarget.propTypes = {
  data: PropTypes.object.isRequired,
  modifyData: PropTypes.func
}

export default EditStepTarget
